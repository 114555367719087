import React from 'react';
import PropTypes from 'prop-types';
import Tables from 'react-table';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { makeColumnSetuper } from '../../common/tableCellRenders';

import CardWhite from '../../common/card_white/CardWhite';
import '../../../styles/Table.css';
import './PanelOrderCountByDistr.css';
import { getNumberSeparated } from '../../../services/helpers';

const setupColumn = makeColumnSetuper('table__column panel-orders-count-by-distr__cell', 'table__head table__column');

const getOrderCountSeparated = ({ haveQty, havePrice, ...distr }) => ({
  ...distr,
  havePrice: getNumberSeparated(havePrice),
  haveQty: getNumberSeparated(haveQty),
});

const columns = [
  { accessor: 'id', minWidth: 8 },
  { accessor: 'name', minWidth: 15 },
  { accessor: 'havePrice', Header: 'Price', minWidth: 20 },
  { accessor: 'haveQty', Header: 'Quantity', minWidth: 20 },
].map(setupColumn);

const mapStateToProps = ({ systemStats }) => ({
  totalProducts: systemStats.totalProducts,
  distributors: systemStats.distributors,
});

function PanelOrderCountByDistr({ totalProducts, distributors }) {
  return (
    <div className="panel-order-count-by-distr">
      <div className="panel-order-count-by-distr__container">
        <CardWhite className="panel-order-count-by-distr__text">
          <span className="panel-order-count-by-distr__text_count">
            {getNumberSeparated(totalProducts)}
          </span>
          Total Products
        </CardWhite>
      </div>
      <div className="panel-order-count-by-distr__container">
        <ReactTable
          noDataText="There are no distributors"
          data={distributors.map(getOrderCountSeparated)}
          className="table"
          columns={columns}
          showPaginationBottom={false}
          loadingText=""
          resizable={false}
          minRows={1}
        />
      </div>
    </div>
  );
}


PanelOrderCountByDistr.propTypes = {
  totalProducts: PropTypes.number,
  distributors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    havePrice: PropTypes.number,
    haveQty: PropTypes.number,
  })),
};

PanelOrderCountByDistr.defaultProps = {
  totalProducts: 0,
  distributors: [],
};

export default connect(mapStateToProps)(PanelOrderCountByDistr);
