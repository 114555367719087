import {
  // CHANGE_ORDER_SEARCH,
  CHOOSE_DISTRIBUTOR, REMOVE_DISTR_CHOICE,
  SAVE_DISTRIBUTOR_CHOICES_SUCCESS,
} from '../actions';
import { getObjWithout } from '../../services/helpers';

const initialDistrBuffer = {};

export default function distrBufferReducer(orderDistrBuffer = initialDistrBuffer, action ){
  switch (action.type) {
    // case CHANGE_ORDER_SEARCH:
    case SAVE_DISTRIBUTOR_CHOICES_SUCCESS:
      return initialDistrBuffer;
    case CHOOSE_DISTRIBUTOR:
      return { ...orderDistrBuffer, [action.hash]: action.choice};
    case REMOVE_DISTR_CHOICE:
      return getObjWithout(orderDistrBuffer, action.hash);
    default: return orderDistrBuffer;
  }
}