import { CHANGE_ORDER_SEARCH, CHANGE_ORDER_WITH_SHIPPING_SEARCH, RESET_ORDER_SEARCH } from '../actions';

const initialOrderSearch = {
  marketplace: null,
  distributor: null,
  status: 0,
  shipBy: null,
  shipByBefore: null,
  shipByAfter: null,
  itemInfo: '',
  itemID: '',
  customer: '',
  orderID: '',
  searchAll: '',
  distrPage: false,
};

export default function orderSearchReducer(orderSearch = initialOrderSearch, { type, params }) {
  switch (type) {
    case RESET_ORDER_SEARCH:
      return initialOrderSearch;
    case CHANGE_ORDER_WITH_SHIPPING_SEARCH:
    case CHANGE_ORDER_SEARCH:
      return {
        ...orderSearch,
        ...params,
      };
    default: return orderSearch;
  }
}