import { put, call, select } from 'redux-saga/effects';

import { sendToArchiveFailure, sendToArchiveSuccess } from '../../actions';
import { requestMaker } from '../../../services/settings';
import { getNewYorkTime, orderStatus } from '../../../services/helpers';

export default function* sendToArchiveSaga() {
  const distr = yield select(state => state.orderSearch.distributor);
  const token = yield select(state => state.user.token);
  try {

    yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'patch',
      url: '/api/v1/order/all/',
      data: {
        filters: {
          distr,
          status: orderStatus.export,
        },
        changes: {
          status: orderStatus.archive,
          processingDate: getNewYorkTime(),
        },
        instructions: ['saveDistributor'],
      },
    });
    yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'post',
      url: '/api/v1/ship-order/all/',
      data: {
        statusWhitelist: [
          orderStatus.archive,
          orderStatus.shipReady,
        ],
      },
    });
    yield put(sendToArchiveSuccess());
  } catch (error) {
    yield put(sendToArchiveFailure(error));
  }
}
