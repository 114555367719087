import {
  POPUP_GET_AVAILABLE_PRODUCTS,
  POPUP_GET_AVAILABLE_PRODUCTS_SUCCESS,
  POPUP_GET_AVAILABLE_PRODUCTS_FAILURE,
  POPUP_SET_MARKETS_TABLE_CELL,
  POPUP_SET_AVAIL_PRODS_TABLE_CELL,
  POPUP_CLEAR,
  POPUP_CHANGE_PRODUCTS_BULK,
} from '../actions';

const initialPopupAvailProds = {
  loading: false,
  sku: null,
  marketplaceID: null,
  marketplaces: [],
  available: [],
};

export default function popupAvailProdsReducer(popupAvailProds = initialPopupAvailProds, action) {
  switch (action.type) {
    case POPUP_CLEAR:
      return { ...popupAvailProds, available: [], marketplaces: [] };
    case POPUP_SET_MARKETS_TABLE_CELL:
    case POPUP_SET_AVAIL_PRODS_TABLE_CELL:
    case POPUP_CHANGE_PRODUCTS_BULK:
      return { ...popupAvailProds, loading: true };
    case POPUP_GET_AVAILABLE_PRODUCTS:
      return {
        ...popupAvailProds,
        available: [],
        sku: action.sku,
        marketplaceID: action.marketplace,
        loading: true,
      };
    case POPUP_GET_AVAILABLE_PRODUCTS_FAILURE:
      return { ...initialPopupAvailProds };
    case POPUP_GET_AVAILABLE_PRODUCTS_SUCCESS:
      return {
        ...popupAvailProds,
        loading: false,
        marketplaces: action.marketplaces,
        available: action.available,
      };
    default: return popupAvailProds;
  }
}