import { put, call, select } from 'redux-saga/effects';
import { UPDATE_FILED_IN_ARRAY, updateFieldFailure } from '../../actions';
import { requestMaker } from '../../../services/settings';

export default function* updateFieldSaga({ body, productNumber, productId, loaderId }) {
  try {
    const token = yield select(state => state.user.token);
    const { marketID, distrID } = yield select(state => ({
      marketID: state.search.marketplace,
      distrID: state.search.distributor,
    }));
    const response = yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'patch',
      url: '/api/v1/products/',
      data: body,
    });
    if (response) {
      const marketQuery = marketID ? `&marketplace=${marketID}` : '';
      const distrQuery = distrID ? `&distributor=${distrID}` : '';
      const getProduct = yield call(requestMaker, {
        headers: { Authorization: `JWT ${token}` },
        method: 'get',
        url: `/api/v1/products/single/?sku=${productId}${marketQuery}${distrQuery}`,
      });
      const products = yield select(state => state.productList.products);
      // update other products entry data if needed
      products.forEach((productEntry) => {
        if (productEntry.upc === getProduct.data.upc) {
          productEntry.available_products.forEach((product, index) => {
            const foundIndex = getProduct.data.available_products
              .findIndex(p => p.distributorid === product.distributorid);
            if (foundIndex !== -1) {
              productEntry.available_products[index] =
                Object.assign({}, getProduct.data.available_products[foundIndex]);
            }
          });
        }
      });
      products[productNumber] = getProduct.data;
      yield put({ type: UPDATE_FILED_IN_ARRAY, products, loaderId });
    } else {
      yield put(updateFieldFailure('No response', loaderId));
    }
  } catch (error) {
    yield put(updateFieldFailure(error, loaderId));
  }
};
