import React  from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import { CardLabeled, InputHidden } from '../../../common';
import { validateIntegerWithRegEx } from '../../../../services/validators';
import PopupQtyLayout from './PopupQtyLayout';

class PopupQty extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      qty: props.qty,
      shipQty: props.shipQty,
    };
    this.isOnCloseExecuted = false;
  }
  componentDidUpdate({ opened }) {
    if (opened && !this.props.opened && !this.isOnCloseExecuted) this.onClose();
    else this.isOnCloseExecuted = false;
  }
  onOpen = () => {
    const { qty, shipQty } = this.props;
    this.setState({ qty, shipQty });
  };
  onClose = () => {
    const { setOrderField, ids, close } = this.props;
    if (this.valuesChanged()) setOrderField(ids, { ...this.state });
    this.isOnCloseExecuted = true;
    close();
  };
  makeSubmit = field => value => this.setState({ [field]: value });
  valuesChanged() {
    const { qty, shipQty } = this.props;
    const { qty: newQty, shipQty: newShipQty } = this.state;
    return !(qty === newQty && shipQty === newShipQty);
  }
  render() {
    const { children, opened } = this.props;
    const { qty, shipQty } = this.state;
    return (
      <Popup
        trigger={children}
        onClose={this.onClose}
        onOpen={this.onOpen}
        open={opened}
        position="top center"
        on="focus"
      >
        <PopupQtyLayout
          qtyComponent={
            <CardLabeled label="order qty">
              <InputHidden
                value={qty}
                submit={this.makeSubmit('qty')}
                validate={validateIntegerWithRegEx}
              />
            </CardLabeled>
          }
          shipQtyComponent={
            <CardLabeled label="ship qty">
              <InputHidden
                value={shipQty}
                submit={this.makeSubmit('shipQty')}
                validate={validateIntegerWithRegEx}
              />
            </CardLabeled>
          }
        />
      </Popup>
    );
  }
}

PopupQty.propTypes = {
  qty: PropTypes.number,
  shipQty: PropTypes.number,
  children: PropTypes.node.isRequired,
  setOrderField: PropTypes.func,
  close: PropTypes.func,
  open: PropTypes.func,
  ids: PropTypes.shape({
    orderID: PropTypes.string,
    orderItemID: PropTypes.string,
    marketplaceID: PropTypes.number,
  }),
  opened: PropTypes.bool,
};
PopupQty.defaultProps = {
  qty: 0,
  shipQty: 0,
  setOrderField: (ids, changes)=>{},
  ids: {},
  close: () => {},
  open: () => {},
  opened: undefined,
};

export default PopupQty;
