import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './HeaderProducts.css';
import TableMarketplaces from './TableChangeFilteredMarkets';
import TableAvailable from './TableChangeFilteredDistr';

const mapStateToProps = state => ({
  count: state.productList.count,
});

function HeaderProducts({ count }) {
  return (
    <div className="header-products">
      <div className="header-products__container header-products__counter">
        {count}
      </div>
      <div className="header-products__container">
        <TableMarketplaces />
      </div>
      <div className="header-products__container">
        <TableAvailable />
      </div>
    </div>
  );
}


HeaderProducts.propTypes = {
  count: PropTypes.number,
};

HeaderProducts.defaultProps = {
  count: 0,
};

export default connect(mapStateToProps)(HeaderProducts);