import {
  CHANGE_SHIP_DATE_SYSTEM_PAGE, GET_DISTR_STATS, GET_DISTR_STATS_FAILURE, GET_DISTR_STATS_SUCCESS,
  GET_MARKETPLACE_STATS,
  GET_MARKETPLACE_STATS_FAILURE,
  GET_MARKETPLACE_STATS_SUCCESS
} from '../actions';
import { getNewYorkDate, getObjWithout } from '../../services/helpers';

const initialSystemStats = {
  loading: {},
  shipDate: '',
  distributors: [],
  marketplaces: [],
  totalShipped: 0,
  totalProducts: 0,
};

const getLoadingWithout = (state, field) => getObjWithout(state.loading, field);
const getLoadingWith = (state, field) => ({ ...state.loading, [field]: true });

const summTotal = (sum, market) => sum + market.total;

export default function systemStatsReducer(systemStats = initialSystemStats, action){
  switch (action.type) {
    case GET_MARKETPLACE_STATS:
    case CHANGE_SHIP_DATE_SYSTEM_PAGE:
      return {
        ...systemStats,
        shipDate: action.shipDate,
        loading: getLoadingWith(systemStats, 'marketplaces'),
      };
    case GET_MARKETPLACE_STATS_FAILURE:
      return {
        ...systemStats,
        loading: getLoadingWithout(systemStats, 'marketplaces'),
        marketplaces: initialSystemStats.marketplaces,
      };
    case GET_MARKETPLACE_STATS_SUCCESS:
      return {
        ...systemStats,
        marketplaces: action.marketStats,
        loading: getLoadingWithout(systemStats, 'marketplaces'),
        totalShipped: action.marketStats.reduce(summTotal, 0),
      };
    case GET_DISTR_STATS:
      return { ...systemStats, loading: getLoadingWith(systemStats, 'distributors') };
    case GET_DISTR_STATS_SUCCESS:
      return {
        ...systemStats,
        loading: getLoadingWithout(systemStats, 'distributors'),
        distributors: action.distrStats,
        totalProducts: action.totalProducts,
      };
    case GET_DISTR_STATS_FAILURE:
      return {
        ...systemStats,
        loading: getLoadingWithout(systemStats, 'distributors'),
      };
    default: return systemStats;
  }
}