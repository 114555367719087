import React from 'react';
import PropTypes from 'prop-types';
import './ButtonPicture.css';

export default function ButtonPicture({ pictureURL, onClick }) {
  const style = {
    backgroundImage: `url(${pictureURL})`,
  };
  return (
    <button
      className="button-picture"
      style={style}
      onClick={onClick}
    />
  );
}


ButtonPicture.propTypes = {
  pictureURL: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonPicture.defaultProps = {
  pictureURL: '',
  onClick: () => {},
};
