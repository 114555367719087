import React from 'react';
import PropTypes from 'prop-types';

import '../../../../../../styles/Panel.css';
import './TableShippingServices.css';
import '../../PopupShipping.css';


export default function RowShippingServicesLayout(props) {
  const {
    carrierColumn, nameColumn, rateColumn,
    checkColumn, incotermColumn, cellClassName,
    packageColumn, isProfitable, profitColumn,
  } = props;
  const unprofitableClassName = isProfitable ? '' : 'table-shipping-services__cell_unprofitable';
  return (
    <React.Fragment>
      <div className={`panel__table__cell popup-shipping__table__cell ${cellClassName}`}>
        {carrierColumn}
      </div>
      <div className={`panel__table__cell popup-shipping__table__cell popup-shipping__table__cell_service ${cellClassName}`}>
        {nameColumn}
      </div>
      <div className={`panel__table__cell popup-shipping__table__cell popup-shipping__table__cell_service ${cellClassName}`}>
        {packageColumn}
      </div>
      <div className={`panel__table__cell popup-shipping__table__cell ${cellClassName}`}>
        {rateColumn}
      </div>
      <div className={`panel__table__cell popup-shipping__table__cell popup-shipping__table__cell_capitalize ${cellClassName}`}>
        {incotermColumn}
      </div>
      <div className={`panel__table__cell popup-shipping__table__cell ${cellClassName} ${unprofitableClassName}`}>
        {profitColumn}
      </div>
      <div className={`panel__table__cell popup-shipping__table__cell_radio panel__table__cell_right-end ${cellClassName}`}>
        {checkColumn}
      </div>
    </React.Fragment>
  );
}


RowShippingServicesLayout.propTypes = {
  carrierColumn: PropTypes.node,
  nameColumn: PropTypes.node,
  rateColumn: PropTypes.node,
  checkColumn: PropTypes.node,
  incotermColumn: PropTypes.node,
  packageColumn: PropTypes.node,
  profitColumn: PropTypes.node,
  isProfitable: PropTypes.bool,
  cellClassName: PropTypes.string,
};

RowShippingServicesLayout.defaultProps = {
  carrierColumn: '',
  nameColumn: '',
  rateColumn: '',
  checkColumn: '',
  incotermColumn: '',
  packageColumn: '',
  cellClassName: '',
  isProfitable: true,
};
