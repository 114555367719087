import { put, call, select } from 'redux-saga/effects';
import { requestMaker } from '../../../services/settings';
import { popupGetAvailableProductsFailure, popupGetAvailableProductsSuccess } from '../../actions';

export default function* getAvailProdsSaga({ marketplace, sku }) {
  const token = yield select(state => state.user.token);
  const old = yield select(state =>({
    id: state.popupAvailProds.sku,
    marketplace: state.popupAvailProds.marketplaceID,
  }));
  const params = { marketplace: marketplace || old.marketplace, id: sku || old.id };
  try {
    const response = yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'get',
      url: '/api/v1/products/',
      params,
    });
    if (response.data.list.length > 0) {
      const product = response.data.list[0];
      yield put(popupGetAvailableProductsSuccess(product.available_products, product.marketplaces));
    }
    else {
      yield put(popupGetAvailableProductsFailure({ message: 'Not found' }));
    }
  } catch (error) {
    yield put(popupGetAvailableProductsFailure(error));
  }
};
