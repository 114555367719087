import React from 'react';
import PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';

import '../../../styles/Panel.css';
import './PanelBuyInfo.css';
import {
  CardSimple, CardLabeled, CardOpinion,
  InputDisguised as InputHiddenWithoutLoader,
} from '../../common';
import ShippedOrderedPair from './shipped_ordered_pair/ShippedOrderedPair';
import PopupQty from './popup_qty/PopupQty';
import { makeOrderHash } from '../../../services/helpers';


const listItemIds = ({ upc, sku, prodID }) => [upc, sku, prodID];

const labelQtyChoice = (orderCount) => {
  if (orderCount>1) return `${orderCount} items ordered`;
  return 'quantity';
};

const asMoney = (value) => `${(value < 0) ? '-' : ''}$${Math.abs(value)}`;

class PanelBuyInfo extends React.PureComponent{
  constructor(props, context) {
    super(props, context);
    this.hash = makeOrderHash(props.ids);
    this.state = { popupOpened: false };
  }
  getFieldHash(field) {
    return `${this.hash}.${field}`;
  }
  makeSubmitter = field => (value) => {
    const { ids, setOrderField } = this.props;
    setOrderField(ids, { [field]: value }, this.getFieldHash(field));
  };
  openPopup = () => {
    this.setState({ popupOpened: !this.state.popupOpened });
  };
  closePopup = () => {
    this.setState({ popupOpened: false });
  };
  render() {
    const { buyInfo, setOrderField, ids } = this.props;
    const partialyShipped = buyInfo.qty !== buyInfo.shipQty;
    return (
      <div className="panel panel_one-column panel-buy-info">
        <div className="panel__container panel-buy-info__container_id">
          <CardSimple data={listItemIds(buyInfo.itemIDs)} green={buyInfo.itemIDs.verified} />
        </div>
        <div className="panel__container panel-buy-info__container_qty">
          <CardLabeled
            label={labelQtyChoice(buyInfo.orderCount)}
            caution={buyInfo.qty > 1}
            strange={buyInfo.orderCount > 1}
            labelClassName="panel-buy-info__card-labeled__label"
            contentClassName={partialyShipped ? 'panel-buy-info__card-labeled__content' : ''}
          >
            <PopupQty
              setOrderField={setOrderField}
              ids={ids}
              qty={buyInfo.qty}
              shipQty={buyInfo.shipQty}
              opened={this.state.popupOpened}
              close={this.closePopup}
              open={this.openPopup}
            >
              <div onClick={this.openPopup} role="button" style={{ cursor: 'pointer' }}>
                {
                  (partialyShipped)
                    ? <ShippedOrderedPair shipped={buyInfo.shipQty} ordered={buyInfo.qty} />
                    : buyInfo.shipQty
                }
              </div>
            </PopupQty>
          </CardLabeled>
        </div>
        <div className="panel__container panel-buy-info__container_price">
          <div className="card card-image">
            {asMoney(buyInfo.totalPrice)}
          </div>
        </div>
        <div className="panel__container panel-buy-info__container_profit">
          <CardOpinion
            data={asMoney(buyInfo.profit)}
            good={buyInfo.profit >= 0}
          />
        </div>
      </div>
    );
  }
}

/*
<InputHidden
  loaderID={this.getFieldHash('qty')}
  value={buyInfo.qty}
  validate={validateInteger}
  submit={this.makeSubmitter('qty')}
  type="number"
/>
*/

PanelBuyInfo.propTypes = {
  marketUOM: PropTypes.number,
  buyInfo: PropTypes.shape({
    itemIDs: PropTypes.shape({
      upc: PropTypes.string,
      sku: PropTypes.string,
      prodID: PropTypes.string,
      verified: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
      ]),
    }),
    qty: PropTypes.number,
    shipQty: PropTypes.number,
    orderCount: PropTypes.number,
    price: PropTypes.number,
    tax: PropTypes.number,
    shipPrice: PropTypes.number,
    shipTax: PropTypes.number,
    profit: PropTypes.number,
    totalPrice: PropTypes.number,
  }),
  distrInfo: PropTypes.shape({
    choice: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    list: PropTypes.arrayOf(PropTypes.shape({
      price: PropTypes.number,
    })),
  }),
  ids: PropTypes.shape({
    orderID: PropTypes.string,
    orderItemID: PropTypes.string,
    marketplaceID: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  setOrderField: PropTypes.func,
};

PanelBuyInfo.defaultProps = {
  marketUOM: 0,
  buyInfo: {
    itemIDs: {
      upc: '',
      sku: '',
      prodID: '',
      verified: false,
    },
    qty: 0,
    price: 0,
    tax: 0,
    shipPrice: 0,
    shipTax: 0,
    orderCount: 0,
    profit: 0,
    totalPrice: 0,
  },
  distrInfo: {
    choice:0,
    list:[],
  },
  ids: {
    orderID: '',
    orderItemID: '',
    marketplaceID: 0,
  },
  setOrderField: (ids, changes) => null,
};

export default PanelBuyInfo;
