import { call, select } from 'redux-saga/effects';
import { getOrdersFailure, getOrdersSuccess } from '../../actions';
import { orderStatus } from '../../../services/helpers';
import getPaginationData from '../pagination/getPaginationData';
import makeGetOrdersSaga from './makeGetOrdersSaga';


const getParam = (params, field) => params ? params[field] : null;
export const freshOrStateParam = (field, fresh, state) => getParam(fresh, field) || state[field];

const makeGetter = (obj, def) => field => freshOrStateParam(field, obj, def);

const getCommonParams = get => ({
  distr: get('distributor'),
  itemInfo: get('itemInfo'),
  itemID: get('itemID'),
  customer: get('customer'),
  orderID: get('orderID'),
});

export const getOrderPageSearchParams = (params, search, state) => {
  const get = makeGetter(params, search);
  return {
    ...getCommonParams(get),
    ...getPaginationData(params, state),
    status: get('status'),
    marketplace: get('marketplace'),
    shipBy: get('shipBy'),
    search: get('searchAll'),
  };
};

export const makeOrderPageParamSelector = params => ({ orderSearch, orders }) =>
  getOrderPageSearchParams(params, orderSearch, orders);

const makeDistrPageParamSelector = params => ({ orderSearch, orders }) => {
  const query = getCommonParams(makeGetter(params, orderSearch));
  if (Object.keys(query).some(field => !!query[field])) {
    return {
      ...query,
      ...getPaginationData(params, orders),
      status: orderStatus.export,
    };
  }
  return null;
};

export default function* getOrdersSaga(action) {
  const distrPage = yield select(state => state.orderSearch.distrPage);
  const makeParamSelector = distrPage
    ? makeDistrPageParamSelector
    : makeOrderPageParamSelector;
  const getOrders = makeGetOrdersSaga(makeParamSelector, {
    url: '/api/v1/order/',
    successAction: getOrdersSuccess,
    failureAction: getOrdersFailure,
  });
  yield call(getOrders, action);
}