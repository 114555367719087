import { call, put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { WITHHOLD_SEARCH_TIME, requestMaker } from '../../../services/settings';
import { getOrdersWithShippingInfoSuccess, getOrdersWithShippingInfoFailure } from '../../actions';
import getPaginationData from '../pagination/getPaginationData';
import { orderStatus } from '../../../services/helpers';

const isSearchFieldUpdating = params => params && [
  'shipBy', 'itemID', 'itemInfo', 'customer', 'orderID', 'searchAll',
].some(paramType => Object.keys(params).includes(paramType));

const paramsWithOpenStatusFilter = [
  'sortBy', 'addData', 'limit', 'offset',
];

const isOpenStatusFilter = ({ status, ...otherParams }) => status === orderStatus.open
  && !Object.keys(otherParams)
    .some(field => otherParams[field] && !paramsWithOpenStatusFilter.includes(field));

const shouldSearchIgnoreOpenStatus = searchQuery =>
  searchQuery
  && searchQuery.status === orderStatus.open
  && !isOpenStatusFilter(searchQuery);

const makeShippingPageParamSelector = params => ({ orderSearch, ordersShipping }) => {
  const updatedParams = { ...params, ...orderSearch };
  const paginationData = getPaginationData(params, ordersShipping);
  const usedParams = {
    distr: updatedParams.distributor,
    itemInfo: updatedParams.itemInfo,
    itemID: updatedParams.itemID,
    customer: updatedParams.customer,
    orderID: updatedParams.orderID,
    status: updatedParams.status,
    marketplace: updatedParams.marketplace,
    shipBy: updatedParams.shipBy,
    search: updatedParams.searchAll,
    sortBy: 'shipBy',
  };
  if (usedParams.itemID) {
    usedParams.statusWhitelist = [
      orderStatus.open,
      orderStatus.export,
      orderStatus.archive,
      orderStatus.shipReady,
      orderStatus.service,
      orderStatus.reorders,
    ];
  }
  Object.keys(usedParams).forEach((key) => {
    if (key !== 'status' && !usedParams[key]) delete usedParams[key];
  });
  if (usedParams.status < 0) {
    usedParams.notStatus = -usedParams.status;
    delete usedParams.status;
  }
  if (shouldSearchIgnoreOpenStatus(usedParams)) {
    delete usedParams.status;
  }
  if (paginationData) {
    usedParams.limit = paginationData.limit;
    usedParams.offset = paginationData.offset;
  }
  return usedParams;
};

export default function* getOrdersShippingSaga(action) {
  if (isSearchFieldUpdating(action.params)) yield call(delay, WITHHOLD_SEARCH_TIME);
  const searchQuery = yield select(makeShippingPageParamSelector(action.params));

  if (searchQuery) {
    const token = yield select(state => state.user.token);
    try {
      const response = yield call(requestMaker, {
        headers: { Authorization: `JWT ${token}` },
        method: 'get',
        url: '/api/v1/order-shipping/',
        params: searchQuery,
      });
      yield put(getOrdersWithShippingInfoSuccess(response.data.list, response.data.count));
    } catch (error) {
      yield put(getOrdersWithShippingInfoFailure(error));
    }
  }
  else yield put(getOrdersWithShippingInfoSuccess([], 0));
}
