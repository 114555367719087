import { put, call, select } from 'redux-saga/effects';

import { refundShipmentSuccess, refundShipmentFailure } from '../../actions';
import { requestMaker } from '../../../services/settings';


const selector = ({ user }) => ({
  token: user.token,
});

export default function* refundShipmentSaga({ shipmentID, loaderId }) {
  const { token } = yield select(selector);
  try {
    yield call(requestMaker, {
      headers: { authorization: `JWT ${token}` },
      method: 'PATCH',
      url: '/api/v1/shipping/refund/',
      data: { shipmentID },
    });
    yield put(refundShipmentSuccess(loaderId));
  } catch (error) {
    yield put(refundShipmentFailure(error, loaderId));
  }
};
