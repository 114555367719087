import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown, Image} from 'semantic-ui-react';

import './Header.css';

const trigger = ({ imageURL }) => (
  <React.Fragment>
    <div style={{ backgroundImage: `url(${imageURL || 'anonymous.png'})` }} className="navbar__avatar" />
  </React.Fragment>
);

function UserDropdown(props) {
  return (<Dropdown
    trigger={trigger(props)}
    options={[{ key: 'sign-out', value: 'sign-out', text: 'Sign Out', icon: 'sign out' }]}
    pointing="top right"
    icon={null}
    onChange={props.logOut}
  />);
}

UserDropdown.propTypes = {
  logOut: PropTypes.func,
};

UserDropdown.defaultProps = {
  username: 'Anonymous',
  logOut: () => {},
};

class UserSection extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        {this.props.user.username ?
          <div className="navbar__user">
            <UserDropdown logOut={this.props.logOut} {...this.props.user} />
          </div> : null}
      </React.Fragment>
    );
  }
}

UserSection.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    imageURL: PropTypes.string,
  }),
  logOut: PropTypes.func,
};

UserSection.defaultProps = {
  user: {
    username: 'Anonymous',
    imageURL: '',
  },
  logOut: () => {},
};

export default UserSection;
