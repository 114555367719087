const deleteOnCondition = (obj, predicate) => Object.keys(obj).forEach((field) => {
  if (predicate(obj[field])) delete obj[field];
});

const isFalse = value => !value;

const isNull = value => value === null;

export default function deleteEmptyFields(obj, isStrict) {
  deleteOnCondition(obj, isStrict ? isNull : isFalse);
};