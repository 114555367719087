import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../../../styles/Grid.css';
import './GridInventory.css';
import PanelID from '../panel_inv_id/PanelInvID';
import PanelInfo from '../panel_inv_info/PanelInvInfo';
import PanelProperties from '../panel_inv_props/PanelInvProps';
import { changeProdInventory, deleteProdInventory } from '../../../redux/actions';

const mapDispatchToProps = (dispatch, props) => {
  const getLoaderID = field => `${props.product.upc}.inventory.${field}`;
  return {
    makeSubmitter: field => value =>
      dispatch(changeProdInventory(props.product.upc, { [field]: value }, getLoaderID(field))),
    handleClickDelete: () =>
      dispatch(deleteProdInventory(props.product.upc, getLoaderID('delete'))),
    getLoaderID,
  };
};

function GridInventory({ product, makeSubmitter, getLoaderID, handleClickDelete }) {
  const {
    upc, distrProdID, imageUrl, name, brand, ...properties
  } = product;
  return (
    <div className="grid-inventory">
      <div className="grid__container grid-inventory__container grid-inventory__container_id">
        <PanelID
          upc={upc}
          distrProdID={distrProdID}
          makeSubmitter={makeSubmitter}
          getLoaderID={getLoaderID}
        />
      </div>
      <div className="grid__container grid-inventory__container grid-inventory__container_info">
        <PanelInfo
          imageUrl={imageUrl}
          name={name}
          brand={brand}
          makeSubmitter={makeSubmitter}
          getLoaderID={getLoaderID}
        />
      </div>
      <div className="grid__container grid-inventory__container grid-inventory__container_props">
        <PanelProperties
          properties={properties}
          makeSubmitter={makeSubmitter}
          getLoaderID={getLoaderID}
          handleClickDelete={handleClickDelete}
        />
      </div>
    </div>
  );
}


GridInventory.propTypes = {
  product: PropTypes.shape({
    upc: PropTypes.string,
    distrProdID: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    brand: PropTypes.string,
    weight: PropTypes.number,
    uom: PropTypes.number,
    size: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    unpublish: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]),
    price: PropTypes.number,
    qty: PropTypes.number,
  }),
  makeSubmitter: PropTypes.func,
  getLoaderID: PropTypes.func,
  handleClickDelete: PropTypes.func,
};

GridInventory.defaultProps = {
  product: null,
  makeSubmitter: field => value => null,
  getLoaderID: field => field,
  handleClickDelete: () => null,
};

export default connect(null, mapDispatchToProps)(GridInventory)