import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/Grid.css';
import './GridOrder.css';
import { orderStatus } from '../../../services/helpers';


const colorStyles = {
  [orderStatus.export]: 'grid-orders__container_export',
  [orderStatus.archive]: 'grid-orders__container_archive',
  [orderStatus.shipReady]: 'grid-orders__container_ship-ready',
  [orderStatus.shipped]: 'grid-orders__container_shipped',
  [orderStatus.service]: 'grid-orders__container_service',
  [orderStatus.onHold]: 'grid-orders__container_on-hold',
  [orderStatus.asap]: 'grid-orders__container_asap',
  [orderStatus.cancelled]: 'grid-orders__container_cancelled',
  [orderStatus.reorders]: 'grid-orders__container_reorders',
};

export default function GridOrderLayout({ status, ...props }) {
  const statusStyle = colorStyles[status];
  return (
    <div className="grid grid-orders">
      <div className="grid__container grid-orders__container grid-orders__container_order-info">
        {props.orderPanel}
      </div>
      <div className="grid__container grid-orders__container grid-orders__container_customer-info">
        {props.customerPanel}
      </div>
      <div className="grid__container grid-orders__container grid-orders__container_item-info">
        {props.itemPanel}
      </div>
      <div className="grid__container grid-orders__container grid-orders__container_buy-info">
        {props.pricePanel}
      </div>
      <div className="grid__container grid-orders__container grid-orders__container_distr-info">
        {props.distrPanel}
      </div>
      <div className={`grid__container grid-orders__container grid-orders__container_bottom grid-orders__container_notes ${statusStyle}`}>
        {props.notesPanel}
      </div>
      <div className={`grid__container grid-orders__container grid-orders__container_bottom grid-orders__container_track-order ${statusStyle}`}>
        {props.trackingPanel}
      </div>
      <div className={`grid__container grid-orders__container grid-orders__container_bottom grid-orders__container_buy-inputs ${statusStyle}`}>
        {props.refundsPanel}
      </div>
      <div className={`grid__container grid-orders__container grid-orders__container_bottom grid-orders__container_status ${statusStyle}`}>
        {props.statusPanel}
      </div>
    </div>
  );
}


GridOrderLayout.propTypes = {
  orderPanel: PropTypes.node,
  customerPanel: PropTypes.node,
  itemPanel: PropTypes.node,
  pricePanel: PropTypes.node,
  distrPanel: PropTypes.node,
  notesPanel: PropTypes.node,
  trackingPanel: PropTypes.node,
  refundsPanel: PropTypes.node,
  statusPanel: PropTypes.node,
  status: PropTypes.number,
};

GridOrderLayout.defaultProps = {
  orderPanel: '',
  customerPanel: '',
  itemPanel: '',
  pricePanel: '',
  distrPanel: '',
  notesPanel: '',
  trackingPanel: '',
  refundsPanel: '',
  statusPanel: '',
  status: 0,
};
