import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import uuid from 'uuid';
import PropTypes from 'prop-types';

import Header from './Header';
import RoutesSection from './RoutesSection';
import UserSection from './UserSection';
import { logOut } from '../../../redux/actions';
import { NavLinkMenu as NavLinkMenuClass, NavLinkExternal } from '../index';
import { ADMIN_SITE_URL, isPageAllowedForDepartment } from '../../../services/settings';

const NavLinkMenu = props => <NavLinkMenuClass {...props} key={props.uniqueKey}/>;

const withIndexValues = options => options
  .map((option, index) => ({ ...option, value: index }));

const hideForbiddenTabs = department => tab => isPageAllowedForDepartment(department, tab.href);

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => dispatch(logOut()),
  };
}

function HeaderContainer({ user, logOut }) {
  let elements = [
    {
      name: 'admin',
      title: 'Admin',
      href: ADMIN_SITE_URL,
      uniqueKey: uuid.v4(),
      renderer: NavLinkExternal,
    },
    {
      name: 'system',
      title: 'System',
      href: '/system',
      uniqueKey: uuid.v4(),
    },
    {
      name: 'products',
      title: 'Products',
      href: '/products',
      uniqueKey: uuid.v4(),
      renderer: NavLinkMenu,
      options: withIndexValues([
        { text: 'Products (FULL)', path: '/full' },
      ]),
    },
    {
      name: 'orders',
      title: 'Orders',
      href: '/orders',
      uniqueKey: uuid.v4(),
    },
    {
      name: 'distributors',
      title: 'Distributors',
      href: '/distributors',
      uniqueKey: uuid.v4(),
    },
    {
      name: 'inventory',
      title: 'Inventory',
      href: '/inventory',
      uniqueKey: uuid.v4(),
    },
    {
      name: 'shipping',
      title: 'Shipping',
      href: '/shipping',
      uniqueKey: uuid.v4(),
    },
  ];
  elements = elements.filter(hideForbiddenTabs(user.department));
  if(!user || !user.username) {
    elements.push({
      name: 'login',
      title: 'Login',
      href: '/login',
      uniqueKey: uuid.v4(),
    });
  }
  return (<Header>
      {user && user.username ? <UserSection user={user} logOut={logOut} /> : null}
      <RoutesSection elements={elements} />
  </Header>);
}

HeaderContainer.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    imageURL: PropTypes.string,
  }),
  logOut: PropTypes.func.isRequired,
};

HeaderContainer.defaultProps = {
  user: null,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(HeaderContainer);
