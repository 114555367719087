import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/Panel.css';
import './PanelSize.css';

export default function PanelSizeLayout(props) {
  return (
    <div className="panel panel-size" onKeyDown={props.onKeyDown} >
      <div className="panel__container panel-size__container_length">
        {props.lenghtComponent}
      </div>
      <div className="panel__container panel-size__container_width">
        {props.widthComponent}
      </div>
      <div className="panel__container panel-size__container_height">
        {props.heightComponent}
      </div>
      <div className="panel__container panel-size__container_weight">
        {props.weightComponent}
      </div>
      <div className="panel__container panel-size__container_button">
        {props.buttonComponent}
      </div>
      <div className="panel__container panel-size__container_is-food">
        {props.foodComponent}
      </div>
    </div>
  );
}


PanelSizeLayout.propTypes = {
  lenghtComponent: PropTypes.node,
  widthComponent: PropTypes.node,
  heightComponent: PropTypes.node,
  weightComponent: PropTypes.node,
  buttonComponent: PropTypes.node,
  foodComponent: PropTypes.node,
  onKeyDown: PropTypes.func,
};

PanelSizeLayout.defaultProps = {
  lenghtComponent: null,
  widthComponent: null,
  heightComponent: null,
  weightComponent: null,
  buttonComponent: null,
  foodComponent: null,
  onKeyDown: null,
};
