import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import PropTypes from 'prop-types';

import { actions } from '../../../redux/reducers/searchReducer';
import {  } from '../../../redux/actions';
import '../../page_products/table_products/TableProducts.css';


const mapDispatchToProps = (dispatch, { inputKey }) => {
  return {
    handleChange: (e, data) =>
      dispatch(actions.changeDropDownValue(inputKey, data.value)),
  };
};

const mapStateToProps = (state, props) => ({
  value: state.search[props.inputKey],
});


export class DropDown extends React.PureComponent {
  render() {
    const { styles } = this.props;
    return (
      <React.Fragment>
        <label className={styles.label}>
          {this.props.label}:
        </label>
        <Dropdown
          placeholder={this.props.placeholder}
          options={this.props.options}
          className={styles.dropdown}
          onChange={this.props.handleChange}
          value={this.props.value}
          icon="chevron down"
          selectOnBlur={false}
        />
      </React.Fragment>
  );
  }
}

const PropTypeStringOrNum = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);
DropDown.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypeStringOrNum,
    text: PropTypes.string,
  })),
  value: PropTypeStringOrNum,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
};

DropDown.defaultProps = {
  styles: {
    dropdown: '',
    label: '',
  },
  label: '',
  value: null,
  options: [],
  placeholder: '',
  handleChange: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(DropDown);
