import { createSelector } from 'reselect';

import { updateSwitch } from '../redux/actions';
import { getOrderAsProductToShip } from './serializers';

export const userSelector = state => state.user;
export const reportTimeSelector = state => state.reports.lastReportTime;

export const persistentStateSelector = createSelector(
  userSelector, reportTimeSelector,
  (user, lastReportTime) => JSON.stringify({ user, lastReportTime }),
);

const makeFactoryBodyUpdateProduct = (dispatch, props) => {
  const { nameField, product } = props;
  const { code, forReq, idName } = product;
  return value => ({
    [forReq]: code,
    [idName]: product[idName],
    [nameField]: value,
  });
};

const makeUpdateSwitchDispatcher = (dispatch, props) => (body, loaderId) => {
  const { productId, productNumber } = props.product;
  dispatch(updateSwitch(body, productNumber, productId, loaderId));
};

export const makeSelectorProductChangeSubmit = () => createSelector(
  [makeFactoryBodyUpdateProduct, makeUpdateSwitchDispatcher],
  (makeBody, updateThisSwitch) => (value, loaderId) => updateThisSwitch(makeBody(value), loaderId),
);

const orderByIDs = ({ orderID, orderItemID, marketplaceID }) => ({ unique }) =>
  unique.orderID === orderID
  && unique.orderItemID === orderItemID
  && unique.marketplaceID === marketplaceID;

export const getOrdersShippingList = state => state.ordersShipping.list;

export const getIDsProp = (state, props) => props.ids;

export const getOrderByID = (list, ids) => list.find(orderByIDs(ids));

export const makeSelectorProductToShip = () => createSelector(
  [getOrdersShippingList, getIDsProp],
  (list, ids) => getOrderAsProductToShip(getOrderByID(list, ids)),
);