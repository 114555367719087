import React from 'react';
import PropTypes from 'prop-types';

import './Header.css';

function Header(props) {
  return (
    <div className="navbar">
      {props.children}
    </div>
  )
}

Header.propTypes = {
  children: PropTypes.any, // eslint-disable-line rule
};

export default Header;
