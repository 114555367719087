import React from 'react';
import PropTypes from 'prop-types';

import './PanelShippingControls.css';
import '../../../styles/Panel.css';

export default function PanelShippingControls(props) {
  return (
    <div className="panel panel_small panel_short panel-shipping-controls" >
      <div className="panel-shipping-controls__container_return">
        {props.returnComponent}
      </div>
      <div className="panel-shipping-controls__container_reprint">
        {props.reprintComponent}
      </div>
    </div>
  );
}


PanelShippingControls.propTypes = {
  returnComponent: PropTypes.node,
  reprintComponent: PropTypes.node,
};

PanelShippingControls.defaultProps = {
  returnComponent: '',
  reprintComponent: '',
};
