import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { flagAsAllowed } from '../../redux/actions';
import { PageError } from '../../components/layout';

const mapDispatchToProps = dispatch => ({
  cleanUp: () => {
    dispatch(flagAsAllowed());
  },
});

class PageForbidden extends React.PureComponent{
  componentWillMount() {
    this.props.cleanUp();
  }
  render() {
    return <PageError message="Access denied." />;
  }
}

PageForbidden.propTypes = {
  cleanUp: PropTypes.func,
};

PageForbidden.defaultProps = {
  cleanUp: () => {},
};

export default connect(null, mapDispatchToProps)(PageForbidden);