import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  suggestedShippingServices,
  GROUND_DELIVERY_PREFERENCE_VALUE_THRESHOLD,
} from '../../../../../../services/settings';
import { chooseShippingService, clearShippingRates } from '../../../../../../redux/actions';
import RowShippingServices from './RowShippingServices';
import RowShippingServicesLayout from './RowShippingServicesLayout';
import './TableShippingServices.css';
import '../../../../../../styles/Panel.css';

const getKey = ({ service, id }) => `TableShippingServices.${service}.${id}`;

const findLowestRate = (min, entry) => {
  if (min == null) return +entry.rate;
  return (+entry.rate < min) ? +entry.rate : min;
};

const getLowestRate = list => list.reduce(findLowestRate);

const mapStateToProps = ({ shipment }) => ({
  choice: shipment.choice,
});

const mapDispatchToProps = dispatch => ({
  handleChoice: (event, { value }) => dispatch(chooseShippingService(value)),
});

class TableShippingServices extends React.PureComponent{
  renderHeader = () => (
    <RowShippingServicesLayout
      cellClassName="table-shipping-services__header"
      carrierColumn="Carrier"
      nameColumn="Service"
      rateColumn="Rate"
      incotermColumn="Type"
      packageColumn="Package"
      profitColumn="Profit"
      checkColumn=""
    />
  );
  renderRow = (service, i, list) => (
    <RowShippingServices
      key={getKey(service)}
      service = {service}
      chosen={this.props.choice === service.id}
      current={
        this.props.productsValue > GROUND_DELIVERY_PREFERENCE_VALUE_THRESHOLD
        && suggestedShippingServices.includes(service.service)
      }
      handleChoice={this.props.handleChoice}
    />
  );
  render() {
    const { services } = this.props;
    return (
      <div className="panel__table table table-shipping-services__table">
        {this.renderHeader()}
        {services.map(this.renderRow)}
      </div>
    );
  }
}


TableShippingServices.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape({
    carrier: PropTypes.string,
    service: PropTypes.string,
    rate: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  })),
  choice: PropTypes.string,
  handleChoice: PropTypes.func,
  ids: PropTypes.shape({
    orderID: PropTypes.string,
    orderItemID: PropTypes.string,
    marketplaceID: PropTypes.number,
  }),
  productsValue: PropTypes.number,
};

TableShippingServices.defaultProps = {
  services: [],
  choice: 'none',
  handleChoice: (event, data) => {},
  ids: {},
  productsValue: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableShippingServices);