import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';


class ButtonWithIcon extends PureComponent {
  render() {
    const {
      icon, title, loading, iconRight, ...otherProps
    } = this.props;
    return (
      <Button
        icon
        loading={loading}
        {...otherProps}
      >
        {iconRight && title}
        <Icon name={icon} />
        {!iconRight && title}
      </Button>
    );
  }
}

ButtonWithIcon.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool,
  iconRight: PropTypes.bool,
};
ButtonWithIcon.defaultProps = {
  icon: '',
  title: '',
  loading: false,
  iconRight: false,
};

export default ButtonWithIcon;