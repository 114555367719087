import qz from 'qz-tray';
import RSVP from 'rsvp';
import { createHash } from 'crypto';
import { call } from 'redux-saga/effects';

import { prepareZPL } from '../../../services/helpers';

qz.api.setPromiseType(resolver => new RSVP.Promise(resolver));
qz.api.setSha256Type(data => createHash('sha256').update(data).digest('hex'));


// async function sha256(data) {
//   const buffer = new TextEncoder().encode(data);
//   const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
//   const hashArray = Array.from(new Uint8Array(hashBuffer));
//   return hashArray.map(byte => ('00' + byte.toString(16)).slice(-2)).join('');
// }

// // Set SHA-256 hashing function for qz
// qz.api.setSha256Type(async data => await sha256(data));

// export function printZplQz(b64EncodedFile) {
//   const config = qz.configs.create('Zebra');
//   return qz.print(config, prepareZPL(b64EncodedFile));
// }
export function printZplQz(b64EncodedFile) {
  console.log('printZplQz');
  const config = qz.configs.create('Zebra');
  // b64EncodedFile = Buffer.from(b64EncodedFile, "base64");
  // b64EncodedFile = b64EncodedFile.toString("utf8");
  // console.log(b64EncodedFile);
  // console.log(config)
  // return qz.print(config, b64EncodedFile);
  // return qz.print(config, [{
  //   type: 'raw',
  //   format: 'base64',
  //   data: b64EncodedFile,
  // }]);
  console.log('printZplQz');
  b64EncodedFile = Buffer.from(b64EncodedFile, "base64");
  b64EncodedFile = b64EncodedFile.toString("utf8");
  console.log(b64EncodedFile);
  console.log(config)
  return qz.print(config, [b64EncodedFile]).catch(function(err){
    console.log(err,"print error")
  });
}

export function printPdfQz(b64EncodedFile) {
  const config = qz.configs.create('Zebra');
  return qz.print(config, [{
    type: 'pdf',
    format: 'base64',
    data: b64EncodedFile,
  }]);
}

export function* connectToQzTray(){
  if (!qz.websocket.isActive()) yield call([qz.websocket, 'connect']);
  yield call([qz.printers, 'find'], 'zebra');
}

export function* connectAndPrintZPL(b64EncodedFile) {
  yield call(connectToQzTray);
  yield call(printZplQz, b64EncodedFile);
}

export function* connectAndPrintPDF(b64EncodedFile) {
  yield call(connectToQzTray);
  yield call(printPdfQz, b64EncodedFile);
}