import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/Card.css';
import './PanelTrackOrder.css';
import '../../../styles/Panel.css';

export default function PanelTrackOrderLayout(props) {
  return (
    <div className="panel panel_short panel-track-order">
      <div className="panel__container panel-track-order__container_tracking-num">
        {props.trackingNumberComponent}
      </div>
      <div className="panel__container panel-track-order__container_shipping-com">
        {props.shippingCarrierSelectorComponent}
      </div>
      <div className="panel__container panel-track-order__container_track">
        {props.buttonComponent}
      </div>
    </div>
  );
}


PanelTrackOrderLayout.propTypes = {
  trackingNumberComponent: PropTypes.node,
  shippingCarrierSelectorComponent: PropTypes.node,
  buttonComponent: PropTypes.node,
};

PanelTrackOrderLayout.defaultProps = {
  trackingNumberComponent: '',
  shippingCarrierSelectorComponent: '',
  buttonComponent: '',
};
