import { call, select, put } from 'redux-saga/effects';
import { getShippingRatesSuccess, getShippingRatesFailure } from '../../actions';
import { isUSA, rateTags } from '../../../services/helpers';
import { requestMaker, makeRateFilter, nonResidential } from '../../../services/settings';

const LONG_TIMEOUT = 60 * 1000; // 1 min

const VALID_RATE_THRESHOLD = 0.5;

const HEAVY_WEIGHT = 1; // lbs

const DUMMY_RESPONSE_DATA = {
  rates: [],
  carrierMessages: [],
};

const highEnoughToBeValidRatesOnly = ({ rate }) => rate >= VALID_RATE_THRESHOLD;

const byRate = (a, b) => a.rate - b.rate;

const setShipmentType = type => rate => ({ ...rate, type });

const calculateShipmentProfit = orderProfit => rate => ({ ...rate, profit: orderProfit - rate.rate });

const addTagsByCondition = condition => (rate) => {
  const tags = rate.tags || [rateTags.returning];
  if (condition(rate)) {
    tags.push(rateTags.shipping);
  }
  return {
    ...rate,
    tags,
  };
};

const getShipment = ({ shipment, shipmentDDP, shipmentFlat, isFood, international, weight, profit }) => ({
  rates: [
    ...shipment.rates.map(setShipmentType('regular')),
    ...shipmentDDP.rates.map(setShipmentType('DDP')),
    ...shipmentFlat.rates.map(setShipmentType('flat')),
  ]
    .filter(highEnoughToBeValidRatesOnly)
    .sort(byRate)
    .map(addTagsByCondition(makeRateFilter(international, weight >= HEAVY_WEIGHT, isFood)))
    .map(calculateShipmentProfit(profit)),
  carrierMessages: [
    ...shipment.carrierMessages,
    ...shipmentDDP.carrierMessages,
    ...shipmentFlat.carrierMessages,
  ],
});

const chosenOnly = product => product.chosen;

const getShippingListEntry = ({ orderItemID, marketplaceID, shipQty }) =>
  ({ orderItemID, marketplaceID, shipQty });

const productIsFood = ({ isFood }) => isFood;

const orderIsInternational = ({ country }) => !isUSA(country);

const sumProfits = (sum, { profit }) => sum + profit;

const selector = ({ productsToShip, shipment, user: { token } }) => {
  const chosenOrders = productsToShip.list.filter(chosenOnly);
  return {
    profit: chosenOrders.reduce(sumProfits, 0),
    shippingList: chosenOrders.map(getShippingListEntry),
    isFood: chosenOrders.some(productIsFood),
    international: chosenOrders.some(orderIsInternational),
    signatureRequired: shipment.signatureRequired,
    token,
  };
};

export default function* getShippingServiceRatesSaga(action) {
  const {
    profit, shippingList, isFood, international, signatureRequired, token,
  } = yield select(selector);
  const {
    ids, sizes, selectedPackages, returning,
  } = action;
  try {
    const requestSettings = {
      headers: { Authorization: `JWT ${token}`},
      method: 'post',
      url: '/api/v1/shipping/rates/',
      data: {
        orderID: ids.orderID,
        size: sizes,
        shippingList,
        international,
        signatureRequired,
        returning,
      },
    };

    const { data: shipment } = yield call(requestMaker, requestSettings);
    const ddpValue = ['ePAQPlusePacketCanadaDDP', 'ePAQSelectDDP', 'ePAQSelectPMICanadaDDP']
    shipment.rates = shipment.rates.filter(e => !ddpValue.includes(e.service))

    let shipmentDDP = DUMMY_RESPONSE_DATA;
    if (international && !isFood) {
      ({ data: shipmentDDP } = yield call(requestMaker, {
        ...requestSettings,
        data: { ...requestSettings.data, DDP: true },
      }));
    }
    let shipmentFlat = DUMMY_RESPONSE_DATA;
    if (!international && selectedPackages && selectedPackages.length > 0) {
      ({ data: shipmentFlat } = yield call(requestMaker, {
        ...requestSettings,
        data: { ...requestSettings.data, selectedPackages },
        timeout: LONG_TIMEOUT,
      }));
    }
    yield put(getShippingRatesSuccess(getShipment({
      weight: sizes.weight,
      shipment,
      shipmentDDP,
      shipmentFlat,
      isFood,
      international,
      profit,
    })));
  } catch (error) {
    yield put(getShippingRatesFailure(error));
  }
};
