import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';


import { makeValidatorDecimal, validateInteger } from '../../../services/validators';
import InputDisguised from '../../common/input_disguised/InputDisguised';
import Switch, { YesNoSwitch as SimpleSwitch } from '../../common/yes_no_switch/YesNoSwitch';
import '../../../styles/Table.css';
import { withLoader } from '../../../containers';
import { updateField, changeProductsBulkWithoutStore, updateLocalProduct } from '../../../redux/actions';
import { getNewYorkTime } from '../../../services/helpers';
import { renderImage } from '../../common/tableCellRenders';

const classGroupName = 'table';
const className = `${classGroupName}__column`;
const headerClassName = `${classGroupName}__head ${classGroupName}__head_marketplaces ${className}`;

function renderSwitch({ value, columnProps, original }) {
  return <Switch
    value={!!value}
    product={original}
    id={original.marketplaceid}
    nameField={columnProps.rest.accessor}
  />;
}

const SwitchWithLoader = withLoader(SimpleSwitch);

const makeSwitchRenderer = (makeSubmitter, id, loaderID) => ({ value, columnProps, original }) => {
  const usedLoaderID = loaderID || `${id}.${original.marketplaceid}.${columnProps.rest.accessor}`;
  return (
    <SwitchWithLoader
      loaderID={usedLoaderID}
      submit={makeSubmitter(original.marketplaceid, columnProps.accessor)}
      value={value}
      passLoaderID
    />
  );
};

function makeRendererDisguisedInput(stringCutoffLength, key, type, validate, format) {
  return function rendererDisguisedInput({ original, value }){
    return (<InputDisguised
      allowEmpty
      width={10}
      validate={validate}
      format={format}
      value={value}
      target={original}
      nameField={key}
      type={type}
      stringCutoffLength={stringCutoffLength}
    />);
  };
}

function setupColumn(settings) {
  return {
    Header: settings.Header || settings.accessor,
    className: settings.className || className,
    headerClassName,
    getProps: () => ({ accessor: settings.accessor }),
    ...settings,
  };
}
const toFixed = value => Number(value).toFixed(2);

const mapDispatchToProps = (dispatch, props) => ({
  makeIsFoodSubmitter: () => (isfood, loaderID) => {
    dispatch(changeProductsBulkWithoutStore(
      { upc: props.upc }, { marketplace: { isfood } },
      () => dispatch(updateLocalProduct(props.productId, loaderID)),
      loaderID,
    ));
  },
  makeUnpublishSubmitter: marketplaceid => (value, loaderID) => {
    dispatch(updateField({
      marketplaceid,
      sku: props.productId,
      unpublish: value,
      unpublishDate: value ? getNewYorkTime() : null,
    }, props.productNumber, props.productId, loaderID));
  },
});

function TableMarketplaces(props) {
  const {
    marketplaces, code, upc,
    forReq, idName, productId, productNumber,
    makeUnpublishSubmitter, makeIsFoodSubmitter,
  } = props;
  const unpublishSwitch = makeSwitchRenderer(makeUnpublishSubmitter, productId);
  const isFoodSwitch = makeSwitchRenderer(makeIsFoodSubmitter, productId, `${upc}.isFood`);
  const columnSettings = [
    {
      accessor: 'imageurl', minWidth: 131, Header: 'marketplace', Cell: renderImage.bind(this),
      className: 'table__column table__column_image',
    },
    {
      accessor: 'isfood', minWidth: 89, Header: 'food', Cell: isFoodSwitch,
    },
    {
      accessor: 'notes', minWidth: 340, Header: 'item notes', Cell: makeRendererDisguisedInput(100, 'notes', 'text'),
    },
    {
      accessor: 'weight',
      minWidth: 39,
      Cell: makeRendererDisguisedInput(5, 'weight', 'number', makeValidatorDecimal(6, 2), toFixed),
    },
    { Header: 'not save', accessor: 'denyWeightUpdate', minWidth: 49, Cell: renderSwitch },
    {
      accessor: 'map',
      minWidth: 63,
      Cell: makeRendererDisguisedInput(8, 'map', 'number', makeValidatorDecimal(7, 2), toFixed),
    },
    { accessor: 'uom', minWidth: 34, Cell: makeRendererDisguisedInput(5, 'uom', 'number', validateInteger) },
    { accessor: 'unpublish', minWidth: 58, Cell: unpublishSwitch },
  ];
  const columns = columnSettings.map(setupColumn);
  marketplaces.map((item) => {
    item.code = code;
    item.forReq = forReq;
    item.idName = idName;
    item.productNumber = productNumber;
    item.productId = productId;
  });
  return (
    <ReactTable
      noDataText="There are no products"
      data={marketplaces}
      columns={columns}
      className={`${classGroupName} table-products__${classGroupName}`}
      showPaginationBottom={false}
      loadingText=""
      resizable={false}
      minRows={1}
    />
  );
}

export default connect(null, mapDispatchToProps)(TableMarketplaces);
