import { connect } from 'react-redux';
import Pagination from '../../layout/pagination/Pagination';
import { changeLimitInventory, changePageInventory } from '../../../redux/actions';

const mapStateToProps = ({ inventory }) => ({
  count: inventory.count,
  offset: inventory.offset,
  limit: inventory.limit,
  page: inventory.page,
});

const mapDispatchToProps = dispatch => ({
  handlePageChange: activePage => dispatch(changePageInventory(activePage)),
  handleLimitChange: (event, { value }) => dispatch(changeLimitInventory(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);