import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import DropdownPackagesSelection from '../dropdown_packages_selection/DropdownPackagesSelection';
import ButtonReprintLabel from '../button_reprint_label/ButtonReprintLabel';
import ButtonUpdateRates from '../button_update_rates/ButtonUpdateRates';
import TableShippingServices from './table_shipping_services/TableShippingServices';
import '../PopupShipping.css';


function FormRateChoice(props) {
  const {
    ids, size, shipmentID, productsValue,
    services, submit, disabled, updateRates,
    packageSelection, onPackageSelectionChange,
    returning,
  } = props;
  return (
    <React.Fragment>
      <TableShippingServices
        ids={ids}
        size={size}
        services={services}
        productsValue={productsValue}
      />
      <div className="popup-shipping__button-row">
        <ButtonUpdateRates onClick={updateRates} />
        <ButtonReprintLabel shipmentID={shipmentID} />
        <Button
          size="huge"
          color="purple"
          className="popup-shipping__button"
          onClick={submit}
          disabled={disabled}
        >
          {returning ? 'SAVE LABEL' : 'PRINT LABEL'}
        </Button>
      </div>
      <div className="popup-shipping__package-selection-container">
        <div className="popup-shipping__package-selection-title"> Predefined Packages </div>
        <DropdownPackagesSelection
          onChange={onPackageSelectionChange}
          value={packageSelection}
        />
      </div>
    </React.Fragment>
  );
}


FormRateChoice.propTypes = {
  ids: PropTypes.shape({
    orderID: PropTypes.string,
    orderItemID: PropTypes.string,
    marketplaceID: PropTypes.number,
  }),
  size: PropTypes.shape({
    weight: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    length: PropTypes.number,
  }),
  returning: PropTypes.bool,
  shipmentID: PropTypes.string,
  services: PropTypes.arrayOf(PropTypes.object),
  submit: PropTypes.func,
  disabled: PropTypes.bool,
  switchReturning: PropTypes.func,
  updateRates: PropTypes.func,
  productsValue: PropTypes.number,
  packageSelection: PropTypes.arrayOf(PropTypes.string),
  onPackageSelectionChange: PropTypes.func,
};

FormRateChoice.defaultProps = {
  ids: {},
  size: {},
  shipmentID: '',
  services: [],
  submit() {},
  disabled: false,
  productsValue: 0,
  packageSelection: [],
  returning: false,
  switchReturning: () => {},
  updateRates: () => {},
  onPackageSelectionChange: () => {},
};


export default FormRateChoice;