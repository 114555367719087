import React from 'react';
import PropTypes from 'prop-types';
import { Input, Dropdown, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';

import { PanelTrackOrderLayout } from '../../page_orders';
import '../../../styles/Panel.css';
import WithLoaderAndSubmit from '../../../containers/WithLoaderAndSubmit';
import { withLoader } from '../../../containers';
import { makeOrderHash, orderStatus, getNewYorkTime } from '../../../services/helpers';
import { refundShipment } from '../../../redux/actions';

const ButtonWithLoader = withLoader(Button);

const mapDispatchToProps = dispatch => ({
  refund: (shipmentID, loaderID) => dispatch(refundShipment(shipmentID, loaderID)),
});

const mapStateToProps = (state, props) => ({
  carrierMap: state.meta.shippingCarrierMap,
  carrierOptions: state.meta.carrierOptions,
});

const InputWrapped = WithLoaderAndSubmit(Input);

class PanelTrackOrder extends React.PureComponent {
  static propTypes = {
    trackingNumber: PropTypes.string,
    setOrderField: PropTypes.func,
    refund: PropTypes.func,
    carrierOptions: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string,
      value: PropTypes.string,
    })),
    carrierMap: PropTypes.objectOf(PropTypes.string),
    chosenShippingCarrier: PropTypes.string,
    shipmentID: PropTypes.string,
  };
  static defaultProps = {
    trackingNumber: '',
    setOrderField: () => {},
    refund: (shipmentID, loaderID) => {},
    carrierMap: {},
    carrierOptions: [],
    chosenShippingCarrier: '',
    shipmentID: null,
  };
  constructor(props, context) {
    super(props, context);
    this.hash = makeOrderHash(props.ids);
    this.trNumHash = `${this.hash}.trackingNumber`;
    this.refundHash = `${this.hash}.refund`;
  }
  handleClickRefund = () => {
    const { refund, shipmentID } = this.props;
    refund(shipmentID, this.refundHash);
  };
  handleChangeCarrier = (event, { value }) => {
    const { ids, setOrderField } = this.props;
    setOrderField(ids, { shippingCarrier: value });
  };
  submitTrackingNumber = (trackingNumber) => {
    const { ids, setOrderField } = this.props;
    const changes = { trackingNumber };
    if (trackingNumber !== '') {
      changes.status = orderStatus.shipped;
      changes.shipTime = getNewYorkTime();
    }
    setOrderField(ids, changes, this.trNumHash);
  };
  render() {
    const {
      chosenShippingCarrier: chosen,
      carrierMap, carrierOptions,
      trackingNumber, shipmentID,
    } = this.props;
    const classNameVoidable = shipmentID ? 'panel__button_red' : '';
    return (
      <PanelTrackOrderLayout
        trackingNumberComponent={
          <InputWrapped
            loaderID={this.trNumHash}
            submit={this.submitTrackingNumber}
            className="panel__input"
            classNameOnEdit="panel__input_edit"
            placeholder="Tracking Number..."
            value={trackingNumber}
            disabled={!chosen}
          />
        }
        shippingCarrierSelectorComponent={
          <Dropdown
            className="panel__dropdown panel-track-order__dropdown"
            options={carrierOptions}
            placeholder="..."
            onChange={this.handleChangeCarrier}
            value={carrierMap[chosen]}
          />
        }
        buttonComponent={
          <ButtonWithLoader
            className={`panel__button ${classNameVoidable}`}
            loaderID={this.refundHash}
            onClick={this.handleClickRefund}
            disabled={!shipmentID}
          >
            Void
          </ButtonWithLoader>
        }
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelTrackOrder);