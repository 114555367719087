import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../header/Header.css';
import './NavLink.css';

export default function NavLinkExternal({ href, uniqueKey, title }) {
  return (
    <a
      href={href}
      key={uniqueKey}
      className="navbar__block"
    >
      {title}
    </a>
  );
}


NavLinkExternal.propTypes = {
  href: PropTypes.string,
  uniqueKey: PropTypes.string.isRequired,
  title: PropTypes.string,
};

NavLinkExternal.defaultProps = {
  href: '/',
  title: '',
};
