import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../../../styles/Panel.css';
import './PanelOrderInfo.css';
import CardSimple from '../../common/card_simple/CardSimple';
import CardLabeled from '../../common/card_labeled/CardLabeled';
import CardImage from '../../common/card_image/CardImage';
import CardHiding from '../../common/card_hiding/CardHiding';
import { isoDateToMonthSlashDay } from '../../../services/helpers';

const savedDealToString = ({ distrName, date, price }) => (
  `${distrName} ${isoDateToMonthSlashDay(date)} $${price}`
);

const marketURLs = {
  'Amazon US': 'https://www.amazon.com/',
  'Amazon CA': 'https://www.amazon.ca/',
  'Amazon UK': 'https://www.amazon.co.uk/',
  'Amazon DE': 'https://www.amazon.de/',
  'Amazon JP': 'https://www.amazon.co.jp/',
  eBay: 'https://www.ebay.com/',
};

const isDealSaved = (deal, distr) =>
  distr && deal && deal.price && deal.date;

function PanelOrderInfo({ orderInfo, distrInfo }) {
  const chosenDistr = distrInfo.list.find(distr => distr.id === distrInfo.choice);
  const savedDeal = isDealSaved(orderInfo.savedDeal, chosenDistr)
    ? { ...orderInfo.savedDeal, distrName: chosenDistr.name }
    : null;
  return (
    <div className="panel panel_small panel-order-info">
      <div className="panel__container panel-order-info__container_id">
        <CardSimple data={orderInfo.id} />
      </div>
      <div className="panel__container panel-order-info__container_ordered">
        <CardLabeled label="ordered">
          {isoDateToMonthSlashDay(orderInfo.dateOrdered)}
        </CardLabeled>
      </div>
      <div className="panel__container panel-order-info__container_ship-by">
        <CardLabeled label="ship by">
          {isoDateToMonthSlashDay(orderInfo.dateShipBy)}
        </CardLabeled>
      </div>
      <div className="panel__container panel-order-info__container_market">
        <CardImage
          imageUrl={orderInfo.marketImageURL}
          placeholderText={orderInfo.marketName}
          url={marketURLs[orderInfo.marketName]}
        />
      </div>
      <div className="panel__container panel-order-info__container_deal">
        <CardHiding
          data={savedDeal ? savedDealToString(savedDeal) : ''}
          hide={!savedDeal}
        />
      </div>
    </div>
  );
}

PanelOrderInfo.propTypes = {
  orderInfo: PropTypes.shape({
    id: PropTypes.string,
    dateOrdered: PropTypes.string,
    dateShipBy: PropTypes.string,
    marketImageURL: PropTypes.string,
    marketName: PropTypes.string,
    savedDeal: PropTypes.shape({
      distrName: PropTypes.string,
      date: PropTypes.string,
      price: PropTypes.number,
    }),
  }),
  distrInfo: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      name: PropTypes.string,
      price: PropTypes.number,
      qty: PropTypes.number,
      prodID: PropTypes.string,
    })),
    choice: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
};
PanelOrderInfo.defaultProps = {
  orderInfo: {
    id: '',
    dateOrdered: '',
    dateShipBy: '',
    marketImageURL: '',
    marketName: '',
    savedDeal: null,
  },
  distrInfo: {
    choice: 0,
    list: [],
  },
};

export default PanelOrderInfo;
