import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import PanelLabeled from './PanelLabeled';
import renderInputs from './renderInputs';
import '../../../../styles/Panel.css';
import './PanelLabeled.css';
import { validateIntegerWithRegEx, makeValidatorDecimalWithRegEx } from '../../../../services/validators';

const validatePrice = makeValidatorDecimalWithRegEx(7, 2);

export default function PanelOptional(props) {
  const { makeChangeHandler, unpublish, ...values } = props;
  const inputs = [
    { fieldName: 'upc', label: 'UPC Number', double: true },
    { fieldName: 'distrProdID', label: 'Item ID (Location)', double: true },
    { fieldName: 'uom', label: 'UOM', validate: validateIntegerWithRegEx },
    { fieldName: 'price', label: 'Price', validate: validatePrice },
    { fieldName: 'qty', label: 'Quantity', validate: validateIntegerWithRegEx },
  ];
  return (
    <PanelLabeled label="required" red>
      <div className="panel-labeled__grid">
        {renderInputs(inputs, makeChangeHandler, values)}
        <div className="panel-labeled__container panel-labeled__container_double panel-labeled__container_right">
          <label className="panel-labeled__dropdown-label">
            Unpublish:
          </label>
          <Dropdown
            icon="chevron down"
            className="panel-labeled__dropdown"
            options={[
              { text: 'YES', value: 1 },
              { text: 'NO', value: 0 },
            ]}
            value={unpublish}
            onChange={makeChangeHandler('unpublish')}
          />
        </div>
      </div>
    </PanelLabeled>
  );
}

const PTNumOrStr = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

PanelOptional.propTypes = {
  makeChangeHandler: PropTypes.func,
  upc: PropTypes.string.isRequired,
  distrProdID: PropTypes.string.isRequired,
  uom: PTNumOrStr.isRequired,
  unpublish: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]).isRequired,
  price: PTNumOrStr.isRequired,
  qty: PTNumOrStr.isRequired,
};

PanelOptional.defaultProps = {
  makeChangeHandler: field => (event, data) => null,
};