import { put, call, select } from 'redux-saga/effects';

import { saveMetadata, FINISH_REQUEST } from '../actions';
import { requestMaker } from '../../services/settings';

const getCarrierNames = list => [...new Set(list.map(entry => entry.displayName))];

const convertToDropdownOption = (carrier, i) => ({ text: carrier, value: carrier, id: i });

function mapShippingCarriers(list) {
  const mapObj = {};
  list.forEach(({ carrierName, displayName }) => {
    mapObj[carrierName] = displayName;
  });
  return mapObj;
}

export default function* getMetadataSaga() {
  const token = yield select(({ user }) => user.token);
  try {
    const response = yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'get',
      url: '/api/v1/table_metadata/',
    });
    const meta = {};
    meta.distributors = [{ text: 'All', id: 0, value: 0 }];
    response.data.distributors.forEach(({ id, text }, i) => {
      meta.distributors[i + 1] = { value: i + 1, text, id };
    });

    meta.marketplaces = [{ text: 'All', id: 0, value: 0 }];
    response.data.marketplaces.forEach(({ id, text }, i) => {
      meta.marketplaces[i + 1] = { value: i + 1, text, id };
    });

    meta.orderStatuses = response.data.orderStatuses
      .map(status => ({ ...status, value: status.id }));

    meta.carrierOptions = getCarrierNames(response.data.shippingCarriers)
      .map(convertToDropdownOption);
    meta.carrierOptions.unshift({ text: '...', value: '', id: 0 });

    meta.shippingCarrierMap = mapShippingCarriers(response.data.shippingCarriers);

    yield put(saveMetadata(meta));
  } catch (error) {
    yield call([console, 'log'], error);
  } finally {
    yield put({ type: FINISH_REQUEST });
  }
};
