import { put, call, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { v4 as getID } from 'uuid';

import { getReports } from '../../actions';
import { INTERVAL_GET_REPORTS, departmentNames } from '../../../services/settings';
import { getTimeBeforeIntervalEnd } from '../../../services/helpers';


const isLoggedIn = ({ user, reports }) => ({
  logged: user.logged,
  admin: user.department === departmentNames.admin,
  timerID: reports.timerID,
});

export default function* initReportTimer() {
  const { logged, admin, timerID } = yield select(isLoggedIn);
  if (!timerID && logged && admin) {
    yield call(delay, getTimeBeforeIntervalEnd(INTERVAL_GET_REPORTS));
    yield put(getReports(getID()));
  }
};
