import React from 'react';
import PropTypes from 'prop-types';

import './GridProduct.css';
import { sequreImageURL } from '../../../services/helpers';


export default function GridProduct({ product }) {
  const {
    sku, upc, asin, ebayid,
    imageurl, title, brand, category,
  } = product;
  return (
    <div className="grid-product table-products__grid-product">
      <div className="grid-product__card grid-product__card_ids">
        {sku ? <p className="grid-product__id">{sku}</p> : ''}
        {upc ? <p className="grid-product__id">{upc}</p> : ''}
        {asin ? <p className="grid-product__id">{asin}</p> : ''}
        {ebayid ? <p className="grid-product__id">{ebayid}</p> : ''}
      </div>
      <div className="grid-product__card grid-product__card_image">
        <img src={sequreImageURL(imageurl)} alt="image" className="grid-product__image" />
      </div>
      <div className="grid-product__card grid-product__card_title">{title}</div>
      <div className="grid-product__card grid-product__card_brand">{brand}</div>
      <div className="grid-product__card grid-product__card_category">{category}</div>
    </div>
  );
}


GridProduct.propTypes = {
  product: PropTypes.shape({
    sku: PropTypes.string,
    upc: PropTypes.string,
    asin: PropTypes.string,
    ebay_id: PropTypes.string,
    image_url: PropTypes.string,
    title: PropTypes.string,
    brand: PropTypes.string,
    category: PropTypes.string,
  }),
};

