import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import { makeOrderHash } from '../../../services/helpers';
import { changeOrder } from '../../../redux/actions';

const customerLabels = {
  name: 'Name',
  address1: 'Address 1',
  address2: 'Address 2',
  address3: 'Address 3',
  city: 'City',
  state: 'State',
  zipcode: 'Zip Code',
  shipPhone: 'Shipment Phone',
  buyerPhone: 'Buyer Phone',
  country: 'Country',
  email: 'Email',
  buyerID: 'Buyer ID',
};

const makeInputFieldRenderer = (values, labels, hash, makeChangeHandler) => field => (
  <Input
    key={`${hash}${labels[field]}`}
    label={labels[field] || field}
    size="mini"
    value={values[field]}
    onChange={makeChangeHandler(field)}
    fluid
  />
);

const mapDispatchToProps = (dispatch, props) => ({
  submitForm: customerInfo => dispatch(changeOrder(props.ids, customerInfo)),
});

const orderedFieldNames = [
  'name', 'address1', 'address2', 'address3',
  'city', 'state', 'zipcode', 'country',
  'shipPhone', 'buyerPhone', 'email', 'buyerID',
];

class PopupCustomerForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.hash = makeOrderHash(props.ids);
    this.state = {
      name: props.customerInfo.name,
      address1: props.customerInfo.address1,
      address2: props.customerInfo.address2,
      address3: props.customerInfo.address3,
      country: props.customerInfo.country,
      state: props.customerInfo.state,
      city: props.customerInfo.city,
      zipcode: props.customerInfo.zipcode,
      shipPhone: props.customerInfo.shipPhone,
      buyerPhone: props.customerInfo.buyerPhone,
      email: props.customerInfo.email,
    };
  }
  componentWillMount() {
    this.setState({ ...this.props.customerInfo });
  }
  submit = () => {
    const { setOrderField, ids } = this.props;
    setOrderField(ids, this.state);
  };
  makeChangeHandler = field => (event, { value }) => {
    this.setState({ [field]: value });
  };
  handleEnter = ({ key }) => {
    if (key === 'Enter') {
      this.submit();
      this.props.close();
    }
  };
  render() {
    return (
      <div className="card__popup_container" onKeyDown={this.handleEnter}>
        {orderedFieldNames.map(makeInputFieldRenderer(
          this.state, customerLabels,
          this.hash, this.makeChangeHandler,
        ))}
      </div>
    );
  }
}

PopupCustomerForm.propTypes = {
  customerInfo: PropTypes.shape({
    name: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.string,
    shipPhone: PropTypes.string,
    buyerPhone: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
  }),
  ids: PropTypes.shape({
    orderID: PropTypes.string,
    orderItemID: PropTypes.string,
    marketplaceID: PropTypes.number,
  }),
  setOrderField: PropTypes.func,
  close: PropTypes.func,
};
PopupCustomerForm.defaultProps = {
  customerInfo: {
    name: '',
    country: '',
    state: '',
    city: '',
    email: '',
    address1: '',
    address2: '',
    address3: '',
    zipcode: '',
    shipPhone: '',
    buyerPhone: '',
  },
  ids: {
    orderID: '',
    orderItemID: '',
    marketplaceID: 0,
  },
  setOrderField: (ids, changes) => null,
  close: () => {},
};

export default PopupCustomerForm;
