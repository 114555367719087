import React from 'react';
import PropTypes from 'prop-types';

import { Labeled } from '../../../common';
import './ShippedOrderedPair.css';

export default function ShippedOrderedPair({ shipped, ordered }) {
  return (
    <div className="shipped-ordered-pair">
      <Labeled label="Shipped" className="shipped-ordered-pair__labeled_shipped">
        {shipped}
      </Labeled>
      <Labeled label="Ordered" className="shipped-ordered-pair__labeled_ordered">
        {ordered}
      </Labeled>
    </div>
  );
}


ShippedOrderedPair.propTypes = {
  shipped: PropTypes.node,
  ordered: PropTypes.node,
};

ShippedOrderedPair.defaultProps = {
  shipped: '',
  ordered: '',
};
