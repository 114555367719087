import { put, call, select } from 'redux-saga/effects';

import { requestMaker } from '../../../services/settings';
import { updateLocalProductSuccess, updateLocalProductFailure } from '../../actions';

const selectToken = state => state.user.token;

export default function* updateLocalProductSaga({ sku, loaderId }) {
  const token = yield select(selectToken);
  try {
    const request = yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'get',
      url: '/api/v1/products/single/',
      params: { sku },
    });
    yield put(updateLocalProductSuccess(request.data, loaderId));
  } catch (error) {
    yield put(updateLocalProductFailure(error, loaderId));
  }
}
