import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { v4 as getID } from 'uuid';
import { shortenString } from '../../../services/helpers';

import 'semantic-ui-css/semantic.min.css';
import './InputDisguised.css';

class InputHiddenMultiline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disguised: true,
      value: props.value,
      error: false,
      maxCharacters: 0,
    };
    this.id = getID();
    this.focus = false;
    this.width = 0;
  }
  componentDidMount() {
    const { letterSize, lineCount } = this.props;
    const maxCharacters = Math.floor((this.width * lineCount) / letterSize);
    this.setState({ maxCharacters });
  }
  handleChange = ({ target }) => {
    const error = !this.props.validate(target.value);
    this.setState({ value: target.value, error });
  };
  handleSubmit = (value) => {
    if (!this.state.error && (value || this.props.allowEmpty)) this.props.submit(value);
    this.setState({ disguised: true, error: false });
  };
  handleKeyDown = ({ key }) => {
    if (key === 'Escape') {
      this.setState({ disguised: true, error: false });
    }
  };
  handleKeyPress = ({ key }) => {
    if (!this.state.error && (key === 'Enter' || key === 'Tab')) {
      this.handleSubmit(this.state.value);
    }
  };
  handleBlur = () => {
    this.handleSubmit(this.state.value);
  };
  handleClick = () => {
    this.focus = true;
    this.setState({ disguised: false, value: this.props.value });
  };
  getFormattedValue = () => {
    const { value, formatWhenHidden, fitString } = this.props;
    const formattedValue = formatWhenHidden(value);
    return (fitString && this.state.maxCharacters)
      ? shortenString(formattedValue, this.state.maxCharacters)
      : formattedValue;
  };
  getWidthRef = (element) => { if (element) this.width = element.offsetWidth; };
  render() {
    const { value, classNameInput, placeholder, loading } = this.props;
    const { focus } = this;
    this.focus = false;
    const classNameOnError = this.state.error ? this.props.classNameOnError : '';
    return (
      <div className={this.props.className} ref={this.getWidthRef}>
        {(this.state.disguised) ? (
          <div onClick={this.handleClick} role="cell">
            {!loading ? this.getFormattedValue() || placeholder : ''}
            <Loader size="tiny" inline active={loading} />
          </div>
          ) : (
          <textarea
            value={this.state.value}
            onBlur={this.handleBlur}
            onKeyPress={this.handleKeyPress}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
            className={`${classNameInput} ${classNameOnError}`}
            autoFocus={focus}
            type="textarea"
          />
        )}
      </div>
    );
  }
}

InputHiddenMultiline.propTypes = {
  className: PropTypes.string,
  classNameInput: PropTypes.string,
  classNameOnError: PropTypes.string,
  placeholder: PropTypes.string,
  validate: PropTypes.func,
  value: PropTypes.string,
  formatWhenHidden: PropTypes.func,
  loading: PropTypes.bool,
  submit: PropTypes.func,
  allowEmpty: PropTypes.bool,
  lineCount: PropTypes.number,
  letterSize: PropTypes.number,
  fitString: PropTypes.bool,
};
InputHiddenMultiline.defaultProps = {
  className: 'input-disguised',
  classNameInput: 'input-disguised__textarea',
  classNameOnError: 'input-disguised_error input-disguised__textarea_error',
  placeholder: '',
  value: '',
  formatWhenHidden: value => value,
  validate: value => true,
  submit: value => {},
  loading: false,
  allowEmpty: false,
  lineCount: 4,
  letterSize: 9,
  fitString: false,
};

export default InputHiddenMultiline;
