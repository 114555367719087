import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { sequreImageURL } from '../../../../../../services/helpers/index';
import RowToShipLayout from './RowToShipLayout';
import { InputHidden } from '../../../../../common/index';
import { validateIntegerWithRegEx } from '../../../../../../services/validators';

import '../../../../../../styles/Panel.css';
import './TableProductsToShip.css';

export default function RowTableProductsToShip({ product, handleChoice, makeSubmitter }) {
  const {
    sku, name, uom, chosen, shipQty, imageURL,
  } = product;
  return (
    <RowToShipLayout
      skuColumn={sku}
      nameColumn={name}
      imageColumn={
        <div
          style={{ backgroundImage: `url(${sequreImageURL(imageURL)})` }}
          className="popup-shipping__table__image"
        />
      }
      uomColumn={+uom}
      shipQtyColumn={
        <InputHidden
          value={+shipQty}
          submit={makeSubmitter(sku, 'shipQty')}
          validate={validateIntegerWithRegEx}
        />
      }
      checkColumn={
        <Checkbox
          className="panel__table__radio table-products-to-ship__checkbox"
          checked={chosen}
          onMouseDown={handleChoice}
          value={sku}
        />
      }
    />
  );
}


RowTableProductsToShip.propTypes = {
  product: PropTypes.shape({
    sku: PropTypes.string,
    name: PropTypes.string,
    qty: PropTypes.number,
    shipQty: PropTypes.number,
    chosen: PropTypes.bool,
  }).isRequired,
  handleChoice: PropTypes.func.isRequired,
  makeSubmitter: PropTypes.func,
};

RowTableProductsToShip.defaultProps = {
  makeSubmitter: (sku, field) => value => {},
};