import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import '../../../styles/Panel.css';
import './PanelCustomerInfo.css';
import CardSimple from '../../common/card_simple/CardSimple';
import CardLabeledWithPopup from '../../common/card_labeled/CardLabeledWithPopup';
import PopupCustomerForm from './PopupCustomerForm';
import CardOpinion from '../../common/card_opinion/CardOpinion';
import ListCustomer from './ListCustomer';
import { isUSA } from '../../../services/helpers';

const listCustomerContactData = customer => ([
  customer.name,
  customer.address1,
  customer.address2,
  customer.address3,
  customer.state,
  customer.city,
  customer.zipcode,
  customer.shipPhone || customer.buyerPhone,
]);



export default function PanelCustomerInfo({ customerInfo, ids, setOrderField }) {
  function startCopyToClipboard(event, text) {
    const inp = document.createElement('input');
    event.currentTarget.appendChild(inp);
    inp.value = text;
    inp.select();
    document.execCommand('copy', false);
    inp.remove();
  }
  return (
    <div className="panel panel-customer-info">
      <div className="panel__container panel-customer-info__container_name">
        <CardSimple
          data={customerInfo.name}
          lineLength={21}
        />
      </div>
      <div className="panel__container panel-customer-info__container_from">
        <CardOpinion
          data={customerInfo.state || customerInfo.city}
          good={isUSA(customerInfo.country)}
          lineLength={25}
        />
      </div>
      <div className="panel__container panel-customer-info__container_mail">
        <button
          className="card card__button-mail"
          onClick={event => startCopyToClipboard(event, customerInfo.email)}
        >
          <Icon name="mail" fitted className="card__button-mail_icon" />
        </button>
      </div>
      <div className="panel__container panel-customer-info__container_address">
        <CardLabeledWithPopup
          label="address"
          PopupContent={PopupCustomerForm}
          customerInfo={customerInfo}
          ids={ids}
          setOrderField={setOrderField}
          many
        >
          <ListCustomer customerInfo={customerInfo} ids={ids} />
        </CardLabeledWithPopup>
      </div>
    </div>
  );
}


PanelCustomerInfo.propTypes = {
  customerInfo: PropTypes.shape({
    name: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
    city: PropTypes.string,
    email: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    zipcode: PropTypes.string,
    shipPhone: PropTypes.string,
    buyerPhone: PropTypes.string,
    buyerID: PropTypes.string,
  }),
  ids: PropTypes.shape({
    orderID: PropTypes.string,
    orderItemID: PropTypes.string,
    marketplaceID: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  setOrderField: PropTypes.func,
};

PanelCustomerInfo.defaultProps = {
  customerInfo: {
    name: '',
    state: '',
    city: '',
    email: '',
    address1: '',
    address2: '',
    address3: '',
    zipcode: '',
    shipPhone: '',
    buyerPhone: '',
  },
  ids: null,
  setOrderField: (ids, changes) => null,
};
