export function makeValidatorDecimal(precision, scale) {
  return function validateDecimal(value) {
    if (Number.isNaN(value)) return false;
    const input = String(value);
    if (input === '') return false;
    let dotDivided = input.split('.');
    if (dotDivided.length < 2) dotDivided = input.split(',');
    if (dotDivided.length > 2) return false;
    return dotDivided[0].length <= precision - scale;
  };
}

export function validateInteger(value) {
  const input = String(value);
  if (Number.isNaN(value)) return false;
  if (input === '') return false;
  return !(['.', ',', 'e', 'E', '-', '+'].some(sign => input.includes(sign)));
}

export function makeValidatorDecimalWithRegEx(precision, scale) {
  const regex = new RegExp(`^(-?\\d{0,${precision}}(?:[.,]\\d{1,${scale}})?)$`);
  return value => regex.test(value);
}

const regExInteger = new RegExp('^(-?\\d+)$');

export const validateIntegerWithRegEx = value => regExInteger.test(value);