import React from 'react';
import PropTypes from 'prop-types';

import './Layout.css';

function Layout(props) {
  return (
    <div className="content">
      <header>{props.headerComponent}</header>
      <main className="content__main">{props.children}</main>
      <footer>{props.footerComponent}</footer>
      <div className="content__modal-triggers">{props.modalComponent}</div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  footerComponent: PropTypes.node,
  headerComponent: PropTypes.node,
  modalComponent: PropTypes.node,
};

Layout.defaultProps = {
  children: '',
  footerComponent: '',
  headerComponent: '',
  modalComponent: '',
};

export default Layout;
