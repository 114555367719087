import ReactTable from 'react-table';
import 'react-table/react-table.css';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../../../styles/Table.css';
import { YesNoSwitchConnected as Switch } from '../../common/';
import InputDisguised from '../../common/input_disguised/InputDisguised';
import { YesNoSwitch as SimpleSwitch } from '../../common/yes_no_switch/YesNoSwitch';
import { withLoader } from '../../../containers';
import {
  makeValidatorDecimalWithRegEx as makeValidatorDecimal,
  validateIntegerWithRegEx as validateInteger,
} from '../../../services/validators';
import { updateField } from '../../../redux/actions';
import { getNewYorkTime } from '../../../services/helpers';

const className = 'table__column';
const headerClassName = `table__head ${className}`;

function defaultValue(key, accessor) {
  return accessor.original[key] ? accessor.original[key] : '-';
}

function makeRendererDisguisedInput(stringCutoffLength, key, type, validate) {
  return function rendererDisguisedInput({ original, value }){
    return (<InputDisguised
      allowEmpty
      width={10}
      validate={validate}
      value={value}
      target={original}
      nameField={key}
      type={type}
      stringCutoffLength={stringCutoffLength}
    />);
  };
}

function renderSwitch(key, accessor) {
  return <Switch
    value={!!accessor.original[key]}
    product={accessor.original}
    nameField={key}
  />;
}

const SwitchWithLoader = withLoader(SimpleSwitch);

const makeSwitchRenderer = (makeSubmitter, id) => ({ value, columnProps, original }) => {
  const loaderID = `${id}.${original.distributorid}.${columnProps.rest.accessor}`;
  return (
    <SwitchWithLoader
      loaderID={loaderID}
      submit={makeSubmitter(original.distributorid, columnProps.rest.accessor)}
      value={value}
      passLoaderID
    />
  );
};

function setupColumn(settings) {
  return {
    Header: settings.Header || settings.accessor,
    className: 'table__column',
    headerClassName,
    ...settings,
  };
}

const toFixed = value => Number(value).toFixed(2);

const mapDispatchToProps = (dispatch, props) => ({
  makeUnpublishSubmitter: distributorid => (value, loaderID) => {
    dispatch(updateField({
      distributorid,
      upc: props.code,
      unpublish: value,
      unpublishDate: value ? getNewYorkTime() : null,
    }, props.productNumber, props.productId, loaderID));
  },
});

function TableAvailable({ availableProducts, code, forReq, idName, productId, productNumber, makeUnpublishSubmitter }) {
  availableProducts.map((item)=>{
    item.code=code;
    item.forReq=forReq;
    item.idName=idName;
    item.productNumber=productNumber;
    item.productId=productId;
    return { item }});
  const unpublishSwitch = makeSwitchRenderer(makeUnpublishSubmitter, productId);
  const columnSettings = [
    { accessor: 'name', minWidth: 20.5},
    { Header: 'id num', accessor: 'distributorproductid', minWidth: 31.5 },
    { accessor: 'price', minWidth: 22.25, Cell: defaultValue.bind(this, 'price') },
    { accessor: 'qty', minWidth: 16.5, Cell: defaultValue.bind(this, 'qty') },
    { accessor: 'brand', minWidth: 44.75 },
    { accessor: 'title', minWidth: 165.5 },
    { accessor: 'description', minWidth: 29 },
    {
      accessor: 'weight',
      minWidth: 22.25,
      Cell: makeRendererDisguisedInput(5, 'weight', 'number', makeValidatorDecimal(6, 2), toFixed),
    },
    { accessor: 'size', minWidth: 31.25},
    { accessor: 'uom', minWidth: 16.75, Cell: makeRendererDisguisedInput(8, 'uom', 'number', validateInteger) },
    { accessor: 'unpublish', minWidth: 29.75, Cell: unpublishSwitch },
  ];
  const columns = columnSettings.map(setupColumn);
  return (
    <ReactTable
      noDataText="There are no products"
      data={availableProducts}
      columns={columns}
      className="table table-products__table"
      showPaginationBottom={false}
      loadingText=""
      resizable={false}
      minRows={1}
    />
  );
}
export default connect(null, mapDispatchToProps)(TableAvailable);
