import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/Panel.css';
import './PanelInvInfo.css';
import CardImage from '../../common/card_image/CardImage';
import CardWhite from '../../common/card_white/CardWhite';
import { InputDisguised as InputHiddenWithoutLoader } from '../../common/input_disguised/InputDisguised';
import InputHiddenMultilineWithoutLoader from '../../common/input_disguised/InputHiddenMultiline';
import WithLoader from '../../../containers/WithLoader';

const InputHidden = WithLoader(InputHiddenWithoutLoader);
const InputHiddenMultiline = WithLoader(InputHiddenMultilineWithoutLoader);

export default function PanelInvInfo({ imageUrl, name, brand, makeSubmitter, getLoaderID }) {
  return (
    <div className="panel panel_short panel-inv-info">
      <div className="panel__container panel-inv-info__container_image">
        <CardImage imageUrl={imageUrl} placeholderText={name} />
      </div>
      <div className="panel__container panel-inv-info__container_name">
        <CardWhite>
          <InputHiddenMultiline
            value={name}
            submit={makeSubmitter('name')}
            loaderID={getLoaderID('name')}
            placeholder="-"
          />
        </CardWhite>
      </div>
      <div className="panel__container panel-inv-info__container_brand">
        <CardWhite>
          <InputHidden
            value={brand}
            submit={makeSubmitter('brand')}
            loaderID={getLoaderID('brand')}
          />
        </CardWhite>
      </div>
    </div>
  );
}


PanelInvInfo.propTypes = {
  getLoaderID: PropTypes.func,
  makeSubmitter: PropTypes.func,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  brand: PropTypes.string,
};

PanelInvInfo.defaultProps = {
  getLoaderID: field => field,
  makeSubmitter: field => value => null,
  imageUrl: '',
  name: '',
  brand: '',
};
