import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

import './InputDisguised.css';

class InputHiddenControlled extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      error: false,
    };
    this.focus = false;
    this.submit = props.submit;
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.inputRef = React.createRef();
  }
  componentDidUpdate({ hidden: wasHidden }) {
    const { hidden: isHidden } = this.props;
    if (wasHidden !== isHidden) {
      if (isHidden) this.handleBlur();
      else this.show();
    }
    if (!isHidden) {
      this.inputRef.current.focus();
    }
  }
  hide() {
    this.setState({ error: false });
    this.focus = false;
    this.props.onHide();
  }
  show() {
    this.focus = true;
    this.setState({ value: '' });
    this.props.onShow();
  }
  handleChange({ target }) {
    const error = !this.props.validate(target.value);
    this.setState({ value: target.value, error });
  }
  handleSubmit(value) {
    if (!this.state.error) {
      this.submit(value);
    }
    this.hide();
  }
  handleKeyDown({ key }) {
    if (key === 'Escape') this.hide();
  }
  handleKeyPress({ key }) {
    if (!this.state.error && (key === 'Enter')) {
      this.handleSubmit(this.state.value);
    }
  }
  handleBlur() {
    if (this.state.value) this.handleSubmit(this.state.value);
    else this.hide();
  }
  handleClick() {
    this.show();
  }
  render() {
    const { value, placeholder, loading, hidden } = this.props;
    const displayedValue = value || placeholder;
    const classNameOnError = this.state.error ? this.props.classNameOnError : '';
    return (
      <div className={`input-disguised ${this.props.className}`}>
        {(hidden) ? (
          <div onClick={this.handleClick} role="button">
            {!loading ? displayedValue : ''}
            <Loader size="tiny" inline active={loading} />
          </div>
        ) : (
          <input
            ref={this.inputRef}
            value={this.state.value}
            onBlur={this.handleBlur}
            onKeyPress={this.handleKeyPress}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
            className={`input-disguised__input ${classNameOnError}`}
            type="text"
          />
        )}
      </div>
    );
  }
}

InputHiddenControlled.propTypes = {
  value: PropTypes.oneOfType(
    PropTypes.string,
    PropTypes.number,
  ),
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  validate: PropTypes.func,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
  submit: PropTypes.func,
  className: PropTypes.string,
  classNameOnError: PropTypes.string,
  hidden: PropTypes.bool,
};
InputHiddenControlled.defaultProps = {
  value: '',
  placeholder: '',
  loading: '',
  validate: () => {},
  onHide: () => {},
  onShow: () => {},
  submit: () => {},
  className: '',
  classNameOnError: 'input-disguised_error',
  hidden: '',
};

export default InputHiddenControlled;
