import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

import GridOrder from '../grid_order/GridOrder';
import './TableOrders.css';


export default function RowOrders({ order, chosen, setOrderField }) {
  if (order) {
    return (
      <tr>
        <td className="table-orders__cell">
          <div className="table-orders__content">
            <GridOrder order={order} setOrderField={setOrderField}/>
          </div>
        </td>
      </tr>
    );
  }
  return null;
}


RowOrders.propTypes = {
  chosen: PropTypes.bool,
  order: PropTypes.shape({}),
  setOrderField: PropTypes.func,
};

RowOrders.defaultProps = {
  chosen: false,
  order: null,
  setOrderField: () => null,
};
