import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import '../header/Header.css';

const NavLinkBasic = ({ href, uniqueKey, title }) => (
  <NavLink
    to={href}
    key={uniqueKey}
    className="navbar__block"
    activeClassName="navbar__block_active">
    {title}
  </NavLink>
);

NavLinkBasic.propTypes = {
  href: PropTypes.string,
  uniqueKey: PropTypes.string.isRequired,
  title: PropTypes.string,
};

NavLinkBasic.defaultProps = {
  href: '/',
  title: '',
};

export default NavLinkBasic;