import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { connect } from 'react-redux';

import '../../../styles/Panel.css';

import GridOrderLayout from './GridOrderLayout';
import PanelOrderInfo from '../panel_order_info/PanelOrderInfo';
import PanelCustomerInfo from '../panel_customer_info/PanelCustomerInfo';
import PanelItemInfo from '../panel_item_info/PanelItemInfo';
import PanelBuyInfo from '../panel_buy_info/PanelBuyInfo';
import PanelTrackOrder from '../panel_track_order/PanelTrackOrder';
import PanelRefunds from '../panel_refunds/PanelRefunds';
import PanelDistrChoice from '../panel_distr_choice/PanelDistrChoice';
import TextArea from './TextAreaWithLoader';
import { makeOrderHash, orderStatus } from '../../../services/helpers';

const colorStyles = {
  [orderStatus.export]: 'grid-orders__container_export',
  [orderStatus.archive]: 'grid-orders__container_archive',
  [orderStatus.shipReady]: 'grid-orders__container_ship-ready',
  [orderStatus.shipped]: 'grid-orders__container_shipped',
  [orderStatus.service]: 'grid-orders__container_service',
  [orderStatus.onHold]: 'grid-orders__container_on-hold',
  [orderStatus.asap]: 'grid-orders__container_asap',
  [orderStatus.cancelled]: 'grid-orders__container_cancelled',
  [orderStatus.reorders]: 'grid-orders__container_reorders',
};

function mapStateToProps(state) {
  return {
    statusOptions: state.meta.orderStatuses,
  };
}

class GridOrder extends React.PureComponent{
  constructor(props, context) {
    super(props, context);
    this.hash = makeOrderHash(props.order.unique);
    this.notesLoaderID = `${this.hash}.notes`;
  }
  handleChangeStatus = (event, { value }) => {
    const { setOrderField, order: { unique } } = this.props;
    setOrderField(unique, { status: value });
  };
  submitNotes = (notes) => {
    const { setOrderField, order: { unique } } = this.props;
    setOrderField(unique, { notes }, this.notesLoaderID);
  };
  render() {
    const {
      order, statusOptions, setOrderField,
    } = this.props;
    return (
      <GridOrderLayout
        status={order.status}
        orderPanel={
          <PanelOrderInfo
            orderInfo={order.orderInfo}
            distrInfo={order.distrInfo}
          />
        }
        customerPanel={
          <PanelCustomerInfo
            customerInfo={order.customerInfo}
            ids={order.unique}
            setOrderField={setOrderField}
          />
        }
        itemPanel={
          <PanelItemInfo
            itemInfo={order.itemInfo}
            ids={order.unique}
            setOrderField={setOrderField}
          />
        }
        pricePanel={
          <PanelBuyInfo
            buyInfo={order.buyInfo}
            distrInfo={order.distrInfo}
            ids={order.unique}
            marketUOM={order.itemInfo.uom}
            setOrderField={setOrderField}
          />
        }
        distrPanel={
          <PanelDistrChoice
            distrInfo={order.distrInfo}
            ids={order.unique}
            sku={order.buyInfo.itemIDs.sku}
            upc={order.buyInfo.itemIDs.upc}
            setOrderField={setOrderField}
          />
        }
        notesPanel={
          <TextArea
            className="panel__input"
            classNameOnEdit="panel__input_edit"
            placeholder="Notes..."
            value={order.notes}
            rows="2"
            submit={this.submitNotes}
            Input={TextArea}
            loaderID={this.notesLoaderID}
          />
        }
        trackingPanel={
          <PanelTrackOrder
            trackingNumber={order.trackingNumber || ''}
            chosenShippingCarrier={order.shippingCarrier}
            ids={order.unique}
            setOrderField={setOrderField}
          />
        }
        refundsPanel={
          <PanelRefunds
            ids={order.unique}
            refund={order.refund}
            setOrderField={setOrderField}
          />}
        statusPanel={
          <Dropdown
            className="panel__dropdown grid-orders__status-dropdown"
            options={statusOptions}
            icon="chevron down"
            value={order.status}
            onChange={this.handleChangeStatus}
          />
        }
      />
    );
  }
}

GridOrder.propTypes = {
  order: PropTypes.shape({
    unique: PropTypes.object,
    orderInfo: PropTypes.object,
    customerInfo: PropTypes.object,
    itemInfo: PropTypes.object,
    buyInfo: PropTypes.object,
    distrInfo: PropTypes.object, // shortened distributor to distr to avoid typos
    refund: PropTypes.object,
    status: PropTypes.number,
    trackingNumber: PropTypes.string,
    notes: PropTypes.string,
    shippingCarrier: PropTypes.string,
  }),
  statusOptions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.any,
  })),
  setOrderField: PropTypes.func,
};
GridOrder.defaultProps = {
  order: {
    unique: null,
    orderInfo: null,
    customerInfo: null,
    itemInfo: null,
    buyInfo: null,
    distrInfo: null,
    refund: null,
    status: 0,
    trackingNumber: '',
    shippingCarrier: null,
    notes: '',
  },
  statusOptions: [],
  setOrderField: () => null,
};

export default connect(mapStateToProps)(GridOrder);

