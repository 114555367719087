import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/Card.css';
import './CardOpinion.css';
import { shortenString } from '../../../services/helpers';

function CardOpinion({ data, good, lineLength }) {
  return (
    <div className={`card card-opinion ${good ? 'card-opinion_good' : ''}`}>
      {shortenString(data, lineLength)}
    </div>
  );
}

CardOpinion.propTypes = {
  data: PropTypes.string,
  good: PropTypes.bool,
  lineLength: PropTypes.number,
};
CardOpinion.defaultProps = {
  data: '',
  good: true,
  lineLength: 0,
};

export default CardOpinion;
