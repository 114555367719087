import React from 'react';
import { Input } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import '../../../../styles/Panel.css';
import './PanelLabeled.css';

const wrapValidator = (validate) => {
  if (validate) return value => value !== '' && !validate(value);
  return () => false;
};

const makeInputRenderer = (makeChangeHandler, values) =>
  ({ label, fieldName, double, type, validate }) => {
    const isError = wrapValidator(validate);
    return (
      <div
        key={`InventoryInsertProductPopUP_${fieldName}`}
        className={`panel-labeled__container ${double ? 'panel-labeled__container_double' : ''}`}
      >
        <Input
          value={values[fieldName]}
          placeholder={label}
          onChange={makeChangeHandler(fieldName, isError)}
          type={type || 'text'}
          className="panel__input panel-labeled__input"
          error={isError(values[fieldName])}
        />
      </div>
    );
  };

const renderInputs = (inputData, makeChangeHandler, values) => inputData
  .map(makeInputRenderer(makeChangeHandler, values));

export default renderInputs;