import { call, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { WITHHOLD_SEARCH_TIME } from '../../../services/settings';
import { orderStatus } from '../../../services/helpers';
import searchOrders from './searchOrders';

const isSearchFieldUpdating = params => params && [
  'shipBy', 'itemID', 'itemInfo', 'customer', 'orderID', 'searchAll',
].some(paramType => Object.keys(params).includes(paramType));

const paramsWithOpenStatusFilter = [
  'sortBy', 'addData', 'limit', 'offset',
];

const isOpenStatusFilter = ({ status, ...otherParams }) => status === orderStatus.open
  && !Object.keys(otherParams)
    .some(field => otherParams[field] && !paramsWithOpenStatusFilter.includes(field));

const shouldSearchIgnoreOpenStatus = searchQuery =>
  searchQuery
  && searchQuery.status === orderStatus.open
  && !isOpenStatusFilter(searchQuery);

const withSortingParams = params => (params && isOpenStatusFilter(params) && !params.sortBy)
  ? { ...params, sortBy: 'orderTime' }
  : params;

const makeGetOrdersSaga = (makeParamSelector, { successAction, failureAction, url }) =>
  function* getOrders({ params }) {
    if (isSearchFieldUpdating(params)) yield call(delay, WITHHOLD_SEARCH_TIME);
    const searchQuery = yield select(makeParamSelector(params));

    if (shouldSearchIgnoreOpenStatus(searchQuery)) {
      delete searchQuery.status;
    }
    yield call(searchOrders, withSortingParams(searchQuery), url, successAction, failureAction);
  };

export default makeGetOrdersSaga;