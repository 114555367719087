import { put, call, select } from 'redux-saga/effects';
import { requestMaker } from '../../../services/settings';

export default function* searchOrders(searchQuery, url, getOrdersSuccess, getOrdersFailure) {
  if (searchQuery) {
    const token = yield select( state => state.user.token);
    const params = { ...searchQuery };
    Object.keys(params).forEach((key) => {
      if (key !== 'status' && !params[key]) delete params[key];
    });
    if (params.status < 0) {
      params.notStatus = -params.status;
      delete params.status;
    }
    try {
      const response = yield call(requestMaker, {
        headers: { Authorization: `JWT ${token}` },
        method: 'get',
        url,
        params,
      });
      yield put(getOrdersSuccess(response.data.list, response.data.count));
    } catch (error) {
      yield put(getOrdersFailure(error));
    }
  }
  else yield put(getOrdersSuccess([], 0));
}