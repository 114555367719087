import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

import GridInventory from '../grid_inventory/GridInventory';
import './TableInventory.css';


export default function RowOrders({ product, chosen }) {
  if (product) {
    return (
      <tr>
        <td className="table-inventory__cell">
          <div className="table-inventory__content">
            <GridInventory product={product} />
          </div>
        </td>
      </tr>
    );
  }
  return null;
}


RowOrders.propTypes = {
  chosen: PropTypes.bool,
  product: PropTypes.shape({}),
};

RowOrders.defaultProps = {
  chosen: false,
  product: null,
};
