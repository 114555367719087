import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { v4 as getId } from 'uuid';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

import { updateField } from '../../../redux/actions';
import './InputDisguised.css';
import { shortenString } from '../../../services/helpers';

const mapDispatchToProps = {
  updateField,
};
const mapStateToProps = state => ({
  loaders: state.loaders,
});

export class InputDisguised extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      disguised: true,
      value: props.keepOldValue ? props.value : '',
      loading: false,
      error: false,
    };
    this.id = getId();
    this.focus = false;
    this.submit = props.submit || this.submit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.loading && !nextProps.loaders[this.id]) this.setState({ loading: false });
  }
  submit(value) {
    const {
      code, forReq, idName, productNumber, productId,
    } = this.props.target;
    const { nameField } = this.props;
    const body = {
      [forReq]: code,
      [idName]: this.props.target[idName],
      [nameField]: this.props.formatInput(value),
    };
    this.setState({ loading: true });
    this.props.updateField(body, productNumber, productId, this.id);
  }
  handleChange({ target }) {
    const error = !this.props.validate(target.value);
    this.setState({ value: target.value, error });
  }
  handleSubmit(value) {
    if (!this.state.error && (value || this.props.allowEmpty)) {
      if (this.props.formatInput) this.submit(this.props.formatInput(value));
      else this.submit(value);
    }
    this.setState({ disguised: true, error: false });
  }
  handleKeyDown({ key }) {
    if (key === 'Escape') {
      this.setState({ disguised: true, error: false });
    }
  }
  handleKeyPress({ key }) {
    if (!this.state.error && (key === 'Enter' || key === 'Tab')) {
      this.handleSubmit(this.state.value);
    }
  }
  handleBlur() {
    if (this.state.value) this.handleSubmit(this.state.value);
    else this.setState({ disguised: true, error: false });
  }
  handleClick() {
    this.focus = true;
    const { keepOldValue, value } = this.props;
    this.setState({ disguised: false, value: keepOldValue ? value : '' });
  }
  render() {
    const { value, inputClassName, placeholder, maxStringLength } = this.props;
    const shortenedValue = shortenString(value, maxStringLength);
    const displayedValue = shortenedValue
      ? this.props.formatWhenHidden(shortenedValue)
      : placeholder;
    const { focus } = this;
    const loading = this.props.loading || this.state.loading;
    this.focus = false;
    const classNameOnError = this.state.error ? this.props.classNameOnError : '';
    return (
      <div className={`input-disguised ${this.props.className}`}>
        {(this.state.disguised) ? (
          <div onClick={this.handleClick} role="button">
            {!loading ? displayedValue : ''}
            <Loader size="tiny" inline active={loading} />
          </div>
        ) : (
          <input
            value={this.state.value}
            onBlur={this.handleBlur}
            onKeyPress={this.handleKeyPress}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
            className={`input-disguised__input ${inputClassName} ${classNameOnError}`}
            autoFocus={focus}
            type="text"
            maxLength={this.props.stringCutoffLength}
          />
        )}
      </div>
    );
  }
}

InputDisguised.propTypes = {
  className: PropTypes.string,
  allowEmpty: PropTypes.bool,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  classNameOnError: PropTypes.string,
  maxStringLength: PropTypes.number,
  validate: PropTypes.func,
  formatInput: PropTypes.func,
  formatWhenHidden: PropTypes.func,
  submit: PropTypes.func,
  stringCutoffLength: PropTypes.number,
  target: PropTypes.shape({
    code: PropTypes.string,
    forReq: PropTypes.any,
    idName: PropTypes.string,
    productNumber: PropTypes.any,
    productId: PropTypes.string,
  }),
  nameField: PropTypes.string,
  loaders: PropTypes.objectOf(PropTypes.bool),
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  loading: PropTypes.bool,
  keepOldValue: PropTypes.bool,
};

InputDisguised.defaultProps = {
  className: '',
  inputClassName: '',
  placeholder: '-',
  allowEmpty: true,
  classNameOnError: 'input-disguised_error',
  maxStringLength: 500,
  validate: value => true,
  formatInput: value => value,
  formatWhenHidden: value => value,
  submit: null,
  stringCutoffLength: 200,
  target: null,
  nameField: '',
  loaders: {},
  type: 'text',
  value: '',
  loading: false,
  keepOldValue: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(InputDisguised);

