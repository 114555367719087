import React from 'react';
import PropTypes from 'prop-types';
import InputDate from '../../common/input_date/InputDate';
import { connect } from 'react-redux';

import '../../../styles/Header.css';
import './HeaderSystem.css';
import { getNewYorkDate, getNumberSeparated } from '../../../services/helpers';
import { changeShipDateSystemPage, printScanform } from '../../../redux/actions';
import { ButtonWithIcon } from '../../common';

const mapStateToProps = ({ systemStats }) => ({
  shipDate: systemStats.shipDate,
  total: systemStats.totalShipped,
});

const mapDispatchToProps = dispatch => ({
  handleDateChange: date => dispatch(changeShipDateSystemPage(date)),
  printScanform: () => dispatch(printScanform()),
});

function HeaderSystem({ shipDate, total, handleDateChange, printScanform }) {
  return (
    <div className="header_content header-system">
      <div className="header__container header-system__container">
        <label className="header__dropdown-label">
          SHIP DATE:
        </label>
        <InputDate
          date={shipDate}
          handleChange={handleDateChange}
        />
      </div>
      <div className="header__container header-system__container header-system__container_text header__text">
        <span className="header__text_count">
          {getNumberSeparated(total)}
        </span> Total Shipments
      </div>
      <div
        className="header__container header-system__container header-system__container_scanform-button"
      >
        <ButtonWithIcon
          icon="download"
          title="Download Scan Form"
          className="header-system__button"
          onClick={printScanform}
        />
      </div>
    </div>
  );
}


HeaderSystem.propTypes = {
  shipDate: PropTypes.string,
  total: PropTypes.number,
  handleDateChange: PropTypes.func,
  printScanform: PropTypes.func,
};

HeaderSystem.defaultProps = {
  shipDate: getNewYorkDate(),
  total: 0,
  handleDateChange: date => null,
  printScanform: () => null,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSystem);