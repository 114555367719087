import { put, call, select, take } from 'redux-saga/effects';
import { SAVE_METADATA, getDistrStatsFailure, getDistrStatsSuccess } from '../../actions';
import { requestMaker } from '../../../services/settings';

const setupRequest = (params, token) => ({
  headers: {
    Authorization: `JWT ${token}`,
  },
  method: 'get',
  url: '/api/v1/statistics/distributors/',
  params,
});

const makeGetByDistr = list => (distr) => {
  const found = list.find(similar => similar.id === distr.id);
  return found || { count: 0 };
};

const mapStats = (getHavePrice, getHaveQty) => distr => ({
  name: distr.text || distr.name,
  id: distr.id,
  havePrice: getHavePrice(distr).count,
  haveQty: getHaveQty(distr).count,
});

const pickNotEmpty = listOfLists => listOfLists.find(list =>
  (list instanceof Array) && (list.length > 0));

const getStats = (distributors, havePrice, haveQty) => {
  const getHavePrice = makeGetByDistr(havePrice);
  const getHaveQty = makeGetByDistr(haveQty);
  return pickNotEmpty([distributors, havePrice, haveQty])
    .map(mapStats(getHavePrice, getHaveQty));
};

export default function* getDistrStatsSaga() {
  const token = yield select(({ user }) => user.token);
  let distributors = yield select(({ meta }) => meta.distributors);
  if (!distributors.length) {
    const { meta } = yield take(SAVE_METADATA);
    distributors = meta.distributors;
  }
  try {
    const hasPriceAndTotalRequest = yield call(
      requestMaker,
      setupRequest({ priceMoreThan: 0, totalProducts: true }, token),
    );
    const hasQtyRequest = yield call(
      requestMaker,
      setupRequest({ qtyMoreThan: 0, totalProducts: true }, token),
    );
    const usedDistributors = distributors.filter(distr => distr.id !== 0);
    const stats =
      getStats(usedDistributors, hasPriceAndTotalRequest.data.list, hasQtyRequest.data.list);
    yield put(getDistrStatsSuccess(stats, hasPriceAndTotalRequest.data.totalProducts));
  } catch (error) {
    yield put(getDistrStatsFailure(error));
  }
};