import { call, put, select } from 'redux-saga/effects';
import { requestMaker } from '../../../services/settings';
import { addDays, getOrDefault, orderStatus } from '../../../services/helpers';
import { getMarketplaceStatsFailure, getMarketplaceStatsSuccess } from '../../actions';

const setupRequest = (params, token) => ({
  headers: {
    Authorization: `JWT ${token}`,
  },
  method: 'get',
  url: '/api/v1/statistics/marketplaces/',
  params,
});

const makeGetShipped = shippedList => (market) => {
  const shipped = shippedList.find(similar => similar.id === market.id);
  return shipped || { count: 0 };
};

const mapStats = getShipped => ({ count, ...market }) => {
  const shipped = getShipped(market);
  return {
    shipped: shipped.count,
    notShipped: count - shipped.count,
    total: count,
    ...market,
  };
};

const getStats = (totalList, shippedList) => {
  const getShipped = makeGetShipped(shippedList);
  return totalList.map(mapStats(getShipped));
};

export default function* getSystemStatsSaga(action) {
  const { token, params } = yield select(({ user, systemStats }) => {
    return {
      params: {
        shipBy: getOrDefault('shipDate', action, systemStats),
      },
      token: user.token,
    };
  });
  try {
    const total = yield call(
      requestMaker,
      setupRequest(params, token),
    );
    const shipped = yield call(
      requestMaker,
      setupRequest({ ...params, status: orderStatus.shipped }, token),
    );
    const stats = getStats(total.data, shipped.data);
    yield put(getMarketplaceStatsSuccess(stats));
  } catch ( error ) {
    yield put(getMarketplaceStatsFailure(error));
  }
};
