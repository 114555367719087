import React from 'react';
import PropTypes from 'prop-types';

import './GridProduct.css';
import { sequreImageURL } from '../../../services/helpers';


export default function GridProduct({ product }) {
  const { sku, title } = product;
  return (
    <div className="grid-product grid-product_lite table-products__grid-product">
      <div className="grid-product__card grid-product__card_ids_lite"> {sku} </div>
      <div className="grid-product__card grid-product__card_title_lite">{title}</div>
    </div>
  );
}


GridProduct.propTypes = {
  product: PropTypes.shape({
    sku: PropTypes.string,
    title: PropTypes.string,
  }),
};

