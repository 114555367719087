import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { TableInventory, withPermissionRedirection } from '../../containers';
import { getProdsInventory } from '../../redux/actions';

function mapStateToProps(state) {
  return {
    products: state.inventory.list,
    loading: state.inventory.loading,
    count: state.inventory.count,
    auth: state.user.auth,
    login: state.user.logged,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initialize: () => {
      dispatch(getProdsInventory());
    },
  };
}

class PageInventory extends React.PureComponent {
  static propTypes = {
    products: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    count: PropTypes.number,
    initialize: PropTypes.func,
    login: PropTypes.bool,
  };
  static defaultProps = {
    products: [],
    loading: false,
    count: 0,
    initialize: () => {},
    login: false,
  };
  componentDidMount() {
    this.props.initialize();
  }
  render() {
    return (
      <TableInventory {...this.props} />
    );
  }
}

export default withPermissionRedirection(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageInventory));