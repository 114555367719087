import { put, call, select } from 'redux-saga/effects';
import { printScanformSuccess, printScanformFailure } from '../../actions';
import { isUSA, getNewYorkTime } from '../../../services/helpers';
import { requestMaker } from '../../../services/settings';
import saveBase64EncodedFile from './saveBase64EncodedFile';

const prepareOrderChangesBulk = changes => ({ unique }) => ({ ...unique, changes });

const keepDomestic = order => isUSA(order.customerInfo.country);

const getShipmentID = order => order.shipInfo.shipmentID;

const selector = ({ user, systemStats }) => ({
  token: user.token,
  shipTime: systemStats.shipDate,
});

export default function* printScanformSaga() {
  const { token, shipTime } = yield select(selector);
  const headers = { Authorization: `JWT ${token}` };
  try {
    const orderResponse = yield call(requestMaker, {
      headers,
      method: 'GET',
      url: '/api/v1/order-shipping/',
      params: {
        shipTime,
        shippingCarrierContains: 'USPS',
        scanFormIdExist: false,
        shipmentIdExist: true,
        limit: 0,
      },
    });
    const scanFormOrderList = orderResponse.data.list.filter(keepDomestic);
    const shipmentIdList = scanFormOrderList.map(getShipmentID);
    const { data: { scanFormID, formURL } } = yield call(requestMaker, {
      headers,
      method: 'POST',
      url: '/api/v1/shipping/scanform/',
      data: { shipmentIdList },
    });
    if (formURL) {
      yield call(requestMaker, {
        headers,
        method: 'PATCH',
        url: '/api/v1/order-shipping/',
        data: {
          bulk: scanFormOrderList.map(prepareOrderChangesBulk({ scanFormID })),
        },
      });
      const { data: b64EncodedFile } = yield call(requestMaker, {
        headers,
        method: 'GET',
        url: '/api/v1/shipping/zpl/',
        params: { labelURL: formURL },
      });
      yield call(saveBase64EncodedFile, b64EncodedFile, `USPS_ScanForm_${getNewYorkTime()}.pdf`);
      yield put(printScanformSuccess());
    } else {
      yield put(printScanformFailure(Error('No scan form URL received')));
    }
  } catch (error) {
    yield put(printScanformFailure(error));
  }
}
