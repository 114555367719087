import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import { WithSubmit } from '../../../../../../containers';
import '../../../../../../styles/Panel.css';
import './InputNotes.css';

export default function InputNotes({ submit, value }) {
  return (
    <div className="popup-shipping__package-selection-container shipping-notes">
      <div className="shipping-notes__title">
        Shipping Note
      </div>
      <WithSubmit
        className="panel__input shipping-notes__input"
        classNameOnError="panel__input_error"
        classNameOnEdit="panel__input_edit"
        placeholder="To put orders into service input service note and press Enter"
        Input={Input}
        submit={submit}
        value={value}
      />
    </div>
  );
}


InputNotes.propTypes = {
  value: PropTypes.string,
  submit: PropTypes.func,
};

InputNotes.defaultProps = {
  value: '',
  submit() {},
};
