import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { connect } from 'react-redux';

import TableDistrChoice from '../table_distr_choice/TableDistrChoice';
import '../../../styles/Panel.css';
import './PanelDistrChoice.css';
import {
  popupSetAvailProdsTableCell,
  popupSetMarketsTableCell,
  popupGetAvailableProducts,
  popupClear,
  popupSetMarketsTableCellSuccess,
  popupChangeProductsBulk,
} from '../../../redux/actions';
import { getNewYorkTime, makeOrderHash } from '../../../services/helpers';
import PopupAvailProds from '../popup_avail_prods/PopupAvailProds';

const mapStateToProps = state => ({
  marketplaces: state.popupAvailProds.marketplaces,
  availableProducts: state.popupAvailProds.available,
  loading: state.popupAvailProds.loading,
});

const mapDispatchToProps = (dispatch, { sku, upc, ids }) => ({
  handlePopupOpen: () => { dispatch(popupGetAvailableProducts(sku, ids.marketplaceID)); },
  handlePopupClose: () => { dispatch(popupClear()) },
  makeMarketSubmitter: (marketplaceID, field) => value =>
    dispatch(popupSetMarketsTableCell(sku, marketplaceID, { [field]: value })),
  makeMarketUnpublishSubmitter: (marketplaceID) => value =>
    dispatch(popupSetMarketsTableCell(sku, marketplaceID, {
      unpublish: value,
      unpublishDate: value ? getNewYorkTime() : null,
    })),
  makeMarketIsFoodSubmitter: () => value => dispatch(popupChangeProductsBulk(
    { upc }, { marketplace: { isfood: value } },
    () => dispatch(popupSetMarketsTableCellSuccess()),
  )),
  makeAvailProdsSubmitter: (distributorID, field) => value =>
    dispatch(popupSetAvailProdsTableCell(upc, distributorID, { [field]: value })),
  makeAvailProdsUnpublishSubmitter: (distributorID, field) => value =>
    dispatch(popupSetAvailProdsTableCell(upc, distributorID, {
      unpublish: value,
      unpublishDate: value ? getNewYorkTime() : null,
    })),
});

class PanelDistrChoice extends PureComponent {
  render() {
    return (
      <div className="panel panel_one-column panel-distr-choice">
        <div className="panel__container panel-distr-choice__container_table">
          <TableDistrChoice
            distrInfo={this.props.distrInfo}
            ids={this.props.ids}
          />
        </div>
        <div className="panel__container panel-distr-choice__container_button">
          <Popup
            trigger={
              <Button className="panel__button">
                distributor details
              </Button>
            }
            onOpen={this.props.handlePopupOpen}
            onClose={this.props.handlePopupClose}
            on="click"
            position="bottom right"
            className="table-distr-choice__popup"
            keepInViewPort={false}
          >
            <PopupAvailProds
              loading={this.props.loading}
              marketplaces={this.props.marketplaces}
              availableProducts={this.props.availableProducts}
              makeMarketSubmitter={this.props.makeMarketSubmitter}
              makeMarketUnpublishSubmitter={this.props.makeMarketUnpublishSubmitter}
              makeMarketIsFoodSubmitter={this.props.makeMarketIsFoodSubmitter}
              makeAvailProdsSubmitter={this.props.makeAvailProdsSubmitter}
              makeAvailProdsUnpublishSubmitter={this.props.makeAvailProdsUnpublishSubmitter}
            />
          </Popup>
        </div>
      </div>
    );
  }
}

PanelDistrChoice.propTypes = {
  distrInfo: PropTypes.shape({
    choice: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    list: PropTypes.arrayOf(PropTypes.object),
  }),
  ids: PropTypes.shape({
    orderID: PropTypes.string,
    orderItemID: PropTypes.string,
    marketplaceID: PropTypes.number,
  }),
  sku: PropTypes.string,
  upc: PropTypes.string,
  makeAvailProdsUnpublishSubmitter: PropTypes.func,
  makeMarketUnpublishSubmitter: PropTypes.func,
  makeAvailProdsSubmitter: PropTypes.func,
  makeMarketSubmitter: PropTypes.func,
  makeMarketIsFoodSubmitter: PropTypes.func,
};

PanelDistrChoice.defaultProps = {
  distrInfo: {
    choice: 0,
    list: [],
  },
  ids: null,
  sku: '',
  upc: '',
  makeAvailProdsUnpublishSubmitter: () => () => {},
  makeMarketUnpublishSubmitter: () => () => {},
  makeAvailProdsSubmitter: () => () => {},
  makeMarketSubmitter: () => () => {},
  makeMarketIsFoodSubmitter: () => () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelDistrChoice);

// distrList: PropTypes.arrayOf(PropTypes.shape({
//   id: PropTypes.oneOfType([
//     PropTypes.number,
//     PropTypes.string,
//   ]),
//   name: PropTypes.string,
//   price: PropTypes.number,
//   qty: PropTypes.number,
//   prodID: PropTypes.string,
// })),