import { put, call } from 'redux-saga/effects';
import { connectAndPrintZPL } from './print';
import { reprintLabelSuccess, reprintLabelFailure } from '../../actions';
import { requestMaker } from '../../../services/settings';

export default function* reprintLabel({ shipmentID }) {
  try {
    const zpl = yield call(requestMaker, {
      url: '/api/v1/shipping/zpl/',
      method: 'get',
      params: { shipmentID },
    });
    yield call(connectAndPrintZPL, zpl.data);
    yield put(reprintLabelSuccess());
  } catch (error) {
    yield put(reprintLabelFailure(error));
  }
};
