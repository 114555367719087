import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputDate from '../../common/input_date/InputDate';

import { DropDown as Dropdown } from '../../common/dropdown/DropDown';
import { InputSearchAll } from '../../common/input_search_all/InputSearchAll';
import { changeOrderSearch, resetOrderSearch } from '../../../redux/actions';
import { getNewYorkDate, orderStatus } from '../../../services/helpers';
import HeaderOrderSearchLayout from './HeaderOrderSearchLayout';
import ButtonBulkEdit from '../button_bulk_edit/ButtonBulkEdit';
import PopupOrderBulkEdit from '../popup_order_bulk_edit/PopupOrderBulkEditContainer';

const dropdownStyle = {
  dropdown: 'header-order-search__dropdown',
  label: 'header-order-search__dropdown-label',
};

const extraStatusFilters = [
  { text: 'Not Shipped', value: orderStatus.notShipped },
];

function mapStateToProps(state) {
  return {
    dateShipBy: state.orderSearch.shipBy,
    statusFilter: state.orderSearch.status,
    marketFilter: state.orderSearch.marketplace,
    distrFilter: state.orderSearch.distributor,
    statusOptions: [...state.meta.orderStatuses, ...extraStatusFilters],
    distrOptions: state.meta.distributors,
    marketOptions: state.meta.marketplaces,
    searchAll: state.orderSearch.searchAll,
  };
}

function mapDistpatchToProps(dispatch) {
  return {
    resetOrderSearch: () => dispatch(resetOrderSearch()),
  };
}

class HeaderOrderSearch extends React.PureComponent {
  makeChangeHandler = field => (event, { value }) => {
    this.props.changeSearch({ [field]: value });
  };
  handleChangeShipByDate = (date) => this.props.changeSearch({ shipBy: date });
  handleChangeStatus = (event, {value}) => {
    this.props.resetOrderSearch();
    this.props.changeSearch({ status: value });
  };
  render(){
    const { props } = this;
    return (
      <HeaderOrderSearchLayout
        count={this.props.count}
        statusSpot={
          <Dropdown
            styles={dropdownStyle}
            options={props.statusOptions}
            value={props.statusFilter}
            handleChange={this.handleChangeStatus}
            placeholder="All"
            label="Status"
          />
        }
        marketSpot={
          <Dropdown
            styles={dropdownStyle}
            options={props.marketOptions}
            value={props.marketFilter}
            handleChange={this.makeChangeHandler('marketplace')}
            placeholder="All"
            label="Market"
          />
        }
        shipBySpot={
          <React.Fragment>
            <div className={dropdownStyle.label}>
              Ship by:
            </div>
            <InputDate
              date={props.dateShipBy}
              handleChange={this.handleChangeShipByDate}
              default={getNewYorkDate()}
            />
          </React.Fragment>
        }
        distrSpot={
          <Dropdown
            styles={dropdownStyle}
            options={props.distrOptions}
            value={props.distrFilter}
            handleChange={this.makeChangeHandler('distributor')}
            placeholder="All"
            label="Distributor"
          />
        }
        generalSearchSpot={
          <InputSearchAll
            className="header-order-search__search"
            placeholder="Search all..."
            value={props.searchAll}
            handleChange={this.makeChangeHandler('searchAll')}
          />
        }
        bulkEditSpot={
          <PopupOrderBulkEdit
            changeSearch={props.changeSearch}
          >
            <ButtonBulkEdit />
          </PopupOrderBulkEdit>
        }
      />
    );
  }
}

HeaderOrderSearch.propTypes = {
  statusOptions: PropTypes.arrayOf(PropTypes.object),
  distrOptions: PropTypes.arrayOf(PropTypes.object),
  marketOptions: PropTypes.arrayOf(PropTypes.object),
  changeSearch: PropTypes.func,
  statusFilter: PropTypes.number,
  distrFilter: PropTypes.number,
  marketFilter: PropTypes.number,
  searchAll: PropTypes.string,
  dateShipBy: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  count: PropTypes.number,
};

HeaderOrderSearch.defaultProps = {
  statusOptions: [],
  distrOptions: [],
  marketOptions: [],
  changeSearch: change => {},
  statusFilter: 0,
  distrFilter: 0,
  marketFilter: 0,
  searchAll: '',
  dateShipBy: '',
  count: 0,
};

export default connect(mapStateToProps, mapDistpatchToProps)(HeaderOrderSearch);
