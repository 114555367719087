import { connect } from 'react-redux';
import Pagination from '../../layout/pagination/Pagination';
import { changeLimitOrders, changePageOrders } from '../../../redux/actions';

const mapStateToProps = ({ orders }) => ({
  count: orders.count,
  offset: orders.offset,
  limit: orders.limit,
  page: orders.page,
});

const mapDispatchToProps = dispatch => ({
  handlePageChange: activePage => dispatch(changePageOrders(activePage)),
  handleLimitChange: (event, { value }) => dispatch(changeLimitOrders(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);