import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

export class InputSearchAll extends React.PureComponent {
  render() {
    return (
      <Input
        icon="search"
        loading={this.props.loading}
        onChange={this.props.handleChange}
        className={this.props.className}
        placeholder={this.props.placeholder}
        value={this.props.value}
      />
    );
  }
}

InputSearchAll.propTypes = {
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};
InputSearchAll.defaultProps = {
  loading: false,
  handleChange: () => {},
  className: '',
  placeholder: '',
  value: '',
};

