import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { flagAsForbidden } from '../redux/actions';
import { isPageAllowedForDepartment } from '../services/settings';

const mapStateToProps = state => ({
  auth: state.user.auth,
  login: state.user.logged,
  department: state.user.department,
  forbidden: state.user.forbidden,
});

const mapDispatchToProps = dispatch => ({
  flagAsForbidden: () => dispatch(flagAsForbidden()),
});

const newPropTypes = ({
  auth: PropTypes.bool,
  login: PropTypes.bool,
  forbidden: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  flagAsForbidden: PropTypes.func.isRequired,
  department: PropTypes.string,
});

const newDefaultProps = ({
  auth: false,
  login: false,
  forbidden: false,
  department: 'None',
});

function withPermissionRedirection(PageComponent) {
  const pageComponentName = PageComponent.displayName
    || PageComponent.name
    || 'Component';
  class Page extends React.PureComponent {
    static displayName = `withPermissionRedirection(${pageComponentName})`;
    static propTypes = { ...newPropTypes, ...PageComponent.propTypes };
    static defaultProps = { ...newDefaultProps, ...PageComponent.defaultProps };
    componentDidMount() {
      const { department, location } = this.props;
      if (!isPageAllowedForDepartment(department, location.pathname)) this.props.flagAsForbidden();
      this.redirrectIfUnavailable();
    }
    componentDidUpdate() {
      this.redirrectIfUnavailable();
    }
    redirrectIfUnavailable() {
      if (!this.props.login) this.props.history.push({ pathname: '/login' });
      if (this.props.forbidden) this.props.history.replace('/forbidden');
    }
    render() {
      const { auth, login, forbidden, history, location, ...props } = this.props;
      return <PageComponent {...props} />;
    }
  }
  return withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
}

export default withPermissionRedirection;