import React from 'react';
import PropTypes from 'prop-types';
import { Popup, Loader, List } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { connect } from 'react-redux';

import {
  getShippingRates, clearShippingRates, getProductsToShipSuccess, getProductsToShip,
  buyShipment, clearProductsToShip, setShipmentNote, setShipmentReturningFlag,
  setShipmentSignatureRequiredFlag,
} from '../../../../redux/actions';
import FormRateChoice from './form_rate_choice/FormRateChoice';
import FormProductsToShip from './form_products_to_ship/FormProductsToShip';
import FormConfirmShipment from './form_confirm_shipment/FormConfirmShipment';
import { makeSelectorProductToShip } from '../../../../services/selectors';
import ReprintLabelProposal from '../FragmentReprintLabelProposal';
import {
  ASK_SIGNATURE_ORDER_PRICE_THRESHOLD,
  SIGNATURE_BY_DEFAULT_ORDER_PRICE_THRESHOLD,
} from '../../../../services/settings';
import { isUSA, rateTags } from '../../../../services/helpers';

const OVERWEIGHT_THRESHOLD = 70;
const NO_DATA_MESSAGE = 'No shipment services found';
const CONFIRM_NO_PROFIT_MESSAGE = 'Rate of this shipping service is unprofitable. Are you sure?';
const CONFIRM_OVERSIZED_MESSAGE = 'Oversized shipment. Are you sure?';
const SIZES_MISSING = 'To get shipping rates you need to input weight';
const DIMENSIONS_MISSING = 'Not all dimensions are present. Input missing dimensions or set them all to 0 to use 1x1x1 package';

function makeMapStateToProps() {
  const selectCurrentProduct = makeSelectorProductToShip();
  const mapStateToProps = (state, props) => ({
    loading: state.shipment.loading || state.productsToShip.loading,
    services: state.shipment.rates,
    chosenRateID: state.shipment.choice,
    error: state.shipment.error,
    carrierMessages: state.shipment.carrierMessages,
    shipmentNote: state.shipment.shipmentNote,
    products: state.productsToShip.list,
    currentProduct: selectCurrentProduct(state, props),
  });
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  setProductsToShipList: list => dispatch(getProductsToShipSuccess(list)),
  setShipmentNote: value => dispatch(setShipmentNote(value)),
  switchReturning: (event, { checked }) => dispatch(setShipmentReturningFlag(!checked)),
  getRates: (...args) => dispatch(getShippingRates(...args)),
  getProducts: orderID => dispatch(getProductsToShip(orderID)),
  raiseRequireSignatureFlag: () => dispatch(setShipmentSignatureRequiredFlag(true)),
  cleanUp: () => {
    dispatch(clearProductsToShip());
    dispatch(clearShippingRates());
  },
  buy: (size) => dispatch(buyShipment(size)),
});

const containsTags = tags => rate => tags.every(tag => rate.tags.includes(tag));

class ButtonPopup extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      productsChoice: false,
      confirmationNeeded: false,
      confirmationMessage: '',
      packageSelection: [],
    };
    this.buttonY = 0;
  }
  isInvalid = () => this.notEnoughData() || this.notAllDimensions();
  // When popup opens we either request for products list from server
  // if we have more than one products for the same order
  // or it adds current products to the list automatically and gets rates
  updateRates = (event) => {
    const { size, ids, getRates, returning } = this.props;
    const { packageSelection } = this.state;
    this.updateProductsToShipList();
    getRates(ids, size, returning, packageSelection);
  };
  updateProductsToShipList(){
    const {
      getProducts, orderCount, currentProduct, ids, setProductsToShipList, size,
    } = this.props;
    // retrieve the list of products, which correspond with selected order
    if (orderCount > 1) {
      getProducts(ids.orderID);
    }
    // if it is only one product, then no need to request from database.
    // So we just insert in the list one we have
    else {
      setProductsToShipList([{
        ...currentProduct,
        chosen: true,
        shipWeight: size.weight,
      }]);
    }
  }
  buy = () => {
    const { size, chosenRateID, services } = this.props;
    const chosenRate = services.find(rate => rate.id === chosenRateID);
    let message = null;
    if (!this.state.confirmationNeeded) {
      if (chosenRate.profit < 0) message = CONFIRM_NO_PROFIT_MESSAGE;
      if (size.weight >= OVERWEIGHT_THRESHOLD) message = CONFIRM_OVERSIZED_MESSAGE;
    }
    if (message) {
      this.setState({
        confirmationNeeded: true,
        confirmationMessage: message,
      });
    } else {
      this.props.buy(size);
      if (this.state.confirmationNeeded) this.setState({ confirmationNeeded: false });
    }
  };
  cancelAndBackToRates = () => {
    this.setState({ confirmationNeeded: false });
  };
  handleOpen = () => {
    if (!this.isInvalid()) {
      const {
        ids, size, getRates, raiseRequireSignatureFlag, returning,
        currentProduct: { country },
      } = this.props;
      const productsChoice = this.shouldChooseProduct();
      this.updateProductsToShipList();
      if (!productsChoice) getRates(ids, size, returning);
      this.setState({ packageSelection: [], productsChoice });
      if (this.getProductsValue() > SIGNATURE_BY_DEFAULT_ORDER_PRICE_THRESHOLD && isUSA(country)) {
        raiseRequireSignatureFlag();
      }
    }
  };
  getProductsValue() {
    return this.props.currentProduct.price;
  }
  shouldChooseProduct = () => {
    const { orderCount, currentProduct: { qty, uom, country } } = this.props;
    return orderCount > 1 || qty > 1 || uom > 1
      || (this.getProductsValue() > ASK_SIGNATURE_ORDER_PRICE_THRESHOLD && isUSA(country));
  };
  changePackageSelection = (event, { value }) => {
    this.setState({ packageSelection: value });
  };
  submitProductsChoice = (event) => {
    const { getRates, ids, size, returning } = this.props;
    getRates(ids, size, returning);
    this.setState({ productsChoice: false });
  };
  notAllDimensions = () => {
    const { size } = this.props;
    const dimensions = ['length', 'width', 'height'];
    const allPresent = dimensions.every(field => size[field]);
    const allAbsent = dimensions.every(field => !size[field]);
    return !allPresent && !allAbsent;
  };
  notEnoughData = () => !this.props.size.weight;
  productsValue() {
    return this.props.products.reduce((summ, prod) => prod.chosen ? summ + prod.price : summ, 0);
  }
  renderAllCarrierMessages() {
    const uniqueCarrierMessageLines = new Set();
    this.props.carrierMessages.forEach(({ message, carrier }) => {
      uniqueCarrierMessageLines.add(`${carrier}: ${message}`);
    });
    return <List items={[...uniqueCarrierMessageLines.values()]}/>;
  }
  renderMessage = (message) => (
    <div className="popup-shipping__message">
      {message}
      {
        this.props.shipmentID
        && <ReprintLabelProposal shipmentID={this.props.shipmentID} />
      }
    </div>
  );
  // Here is decided what should be rendered in popup
  renderPopupContent() {
    const {
      ids, size, services, loading,
      products, buy, chosenRateID, shipmentID,
      error, setShipmentNote, carrierMessages,
      shipmentNote, returning,
    } = this.props;
    const tags = returning ? [rateTags.returning] : [rateTags.shipping];
    const filteredServices = services ? services.filter(containsTags(tags)) : [];
    const {
      productsChoice, packageSelection, confirmationNeeded, confirmationMessage,
    } = this.state;
    if (loading) return <Loader active />;
    if (error && error.message) return this.renderMessage(error.message);
    if (this.notEnoughData()) return this.renderMessage(SIZES_MISSING);
    if (this.notAllDimensions()) return this.renderMessage(DIMENSIONS_MISSING);
    if (confirmationNeeded) {
      return (
        <FormConfirmShipment
          confirm={this.buy}
          cancel={this.cancelAndBackToRates}
        >
          {confirmationMessage}
        </FormConfirmShipment>
      );
    }
    if (productsChoice && products.length) {
      return (
        <FormProductsToShip
          products={products}
          submit={this.submitProductsChoice}
          setShipmentNote={setShipmentNote}
          shipmentNote={shipmentNote}
          shipmentID={shipmentID}
        />
      );
    }
    else if (!filteredServices.length) {
      if (carrierMessages.length > 1) {
        return this.renderMessage(this.renderAllCarrierMessages());
      }
      return this.renderMessage(NO_DATA_MESSAGE);
    }
    return (
      <FormRateChoice
        ids={ids}
        size={size}
        services={filteredServices}
        submit={this.buy}
        returning={returning}
        disabled={!chosenRateID}
        shipmentID={shipmentID}
        updateRates={this.updateRates}
        productsValue={this.productsValue()}
        packageSelection={packageSelection}
        onPackageSelectionChange={this.changePackageSelection}
      />
    );
  }
  render() {
    const {
      children, cleanUp, positionBottom,
    } = this.props;
    return (
      <Popup
        flowing
        trigger={children}
        position={positionBottom ? 'bottom right' : 'top right'}
        keepInViewPort
        on="click"
        onOpen={this.handleOpen}
        onClose={cleanUp}
      >
        <div className="popup-shipping">
          {this.renderPopupContent()}
        </div>
      </Popup>
    );
  }
}


ButtonPopup.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  size: PropTypes.objectOf(PropTypes.number),
  ids: PropTypes.shape({
    orderID: PropTypes.string,
    orderItemID: PropTypes.string,
    marketplaceID: PropTypes.number,
  }),
  getRates: PropTypes.func,
  getProducts: PropTypes.func,
  setProductsToShipList: PropTypes.func,
  setShipmentNote: PropTypes.func,
  shipmentNote: PropTypes.string,
  cleanUp: PropTypes.func,
  services: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
  orderCount: PropTypes.number,
  buy: PropTypes.func,
  currentProduct: PropTypes.shape({
    sku: PropTypes.string,
    name: PropTypes.string,
    qty: PropTypes.number,
  }),
  chosenRateID: PropTypes.string,
  shipmentID: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  carrierMessages: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string,
    carrier: PropTypes.string,
  })),
  positionBottom: PropTypes.bool,
  returning: PropTypes.bool,
  raiseRequireSignatureFlag: PropTypes.func,
};

ButtonPopup.defaultProps = {
  ids: {},
  loading: false,
  size: {},
  getRates: (ids, size) => {},
  getProducts: orderID => null,
  setProductsToShipList: (list) => {},
  setShipmentNote: () => {},
  shipmentNote: '',
  cleanUp: () => {},
  buy: () => {},
  services: [],
  products: [],
  orderCount: 1,
  currentProduct: {},
  chosenRateID: '',
  shipmentID: '',
  error: null,
  carrierMessages: [],
  positionBottom: false,
  returning: false,
  raiseRequireSignatureFlag: () => {},
};

export default connect(makeMapStateToProps, mapDispatchToProps)(ButtonPopup);