import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import Layout from './RowShippingServicesLayout';

export default function RowShippingServices({ service, chosen, current, handleChoice }) {
  const {
    id, carrier, service: name, rate, type, predefinedPackage, profit,
  } = service;
  return (
    <Layout
      cellClassName={current ? 'panel__table__cell_purple' : ''}
      isProfitable={profit >= 0}
      carrierColumn={carrier}
      nameColumn={name}
      rateColumn={`$${rate}`}
      incotermColumn={type}
      packageColumn={predefinedPackage}
      profitColumn={`${(profit < 0) ? '-' : ''}$${Math.abs(profit).toFixed(2)}`}
      checkColumn={
        <Checkbox
          className="panel__table__radio table-shipping-services__radio"
          checked={chosen}
          onMouseDown={handleChoice}
          value={id}
          radio
        />
      }
    />
  );
}

RowShippingServices.propTypes = {
  chosen: PropTypes.bool,
  current: PropTypes.bool,
  service: PropTypes.shape({
    carrier: PropTypes.string,
    service: PropTypes.string,
    rate: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    ddp: PropTypes.bool,
  }).isRequired,
  handleChoice: PropTypes.func.isRequired,
};

RowShippingServices.defaultProps = {
  chosen: false,
  current: false,
};
