import React from 'react';
import PropTypes from 'prop-types';
import { Input, Dropdown, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';
import PanelTrackOrderLayout from './PanelTrackOrderLayout';

import '../../../styles/Panel.css';

import WithLoaderAndSubmit from '../../../containers/WithLoaderAndSubmit';
import { makeOrderHash, orderStatus } from '../../../services/helpers';


const mapStateToProps = (state, props) => ({
  carrierMap: state.meta.shippingCarrierMap,
  carrierOptions: state.meta.carrierOptions,
});

const InputWrapped = WithLoaderAndSubmit(Input);

class PanelTrackOrder extends React.PureComponent {
  static propTypes = {
    trackingNumber: PropTypes.string,
    setOrderField: PropTypes.func,
    carrierOptions: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string,
      value: PropTypes.string,
    })),
    carrierMap: PropTypes.objectOf(PropTypes.string),
    chosenShippingCarrier: PropTypes.string,
  };
  static defaultProps = {
    trackingNumber: '',
    setOrderField: () => {},
    carrierMap: {},
    carrierOptions: [],
    chosenShippingCarrier: '',
  };
  constructor(props, context) {
    super(props, context);
    this.hash = makeOrderHash(props.ids);
    this.trNumHash = `${this.hash}.trackingNumber`;
  }
  handleChangeCarrier = (event, { value }) => {
    const { ids, setOrderField } = this.props;
    setOrderField(ids, { shippingCarrier: value });
  };
  submitTrackingNumber = (trackingNumber) => {
    const { ids, setOrderField } = this.props;
    const changes = { trackingNumber };
    if (trackingNumber !== '') changes.status = orderStatus.shipped;
    setOrderField(ids, changes, this.trNumHash);
  };
  render() {
    const {
      chosenShippingCarrier: chosen,
      carrierMap, carrierOptions,
      trackingNumber,
    } = this.props;
    return (
      <PanelTrackOrderLayout
        trackingNumberComponent={
          <InputWrapped
            loaderID={this.trNumHash}
            submit={this.submitTrackingNumber}
            className="panel__input"
            classNameOnEdit="panel__input_edit"
            placeholder="Tracking Number..."
            value={trackingNumber}
            disabled={!chosen}
          />
        }
        shippingCarrierSelectorComponent={
          <Dropdown
            className="panel__dropdown panel-track-order__dropdown"
            options={carrierOptions}
            placeholder="..."
            onChange={this.handleChangeCarrier}
            value={carrierMap[chosen]}
          />
        }
        buttonComponent={
          <Button className="panel__button">
            track order
          </Button>
        }
      />
    );
  }
}

export default connect(mapStateToProps)(PanelTrackOrder);
