import makeChangeOrderSaga from './makeChangeOrderSaga';
import { changeOrderSuccess, changeOrderFailure } from '../../actions';

export const orderIDsMatch = ids => order => order.unique.orderID === ids.orderID
  && order.unique.orderItemID === ids.orderItemID
  && order.unique.marketplaceID === ids.marketplaceID;

const selectOrderByIDs = ids => state => state.orders.list.find(orderIDsMatch(ids));

export default makeChangeOrderSaga({
  orderSelector: selectOrderByIDs,
  url: '/api/v1/order/',
  successAction: changeOrderSuccess,
  failureAction: changeOrderFailure,
});
