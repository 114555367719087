import { call, put, select } from 'redux-saga/effects';
import fileDownload from 'js-file-download';

import { requestMaker } from '../../../services/settings';
import { CSVNullToSpace, formatCSVString, getChosenDistr, getNewYorkTime, orderStatus } from '../../../services/helpers';
import { downloadCSVFailure, downloadCSVSuccess, getOrders } from '../../actions';

const getQty = ({ qty, remainingQty }) => remainingQty || qty;

function groupOrderDataForCSV(order) {
  const distr = getChosenDistr(order.distrInfo);
  return {
    upc: CSVNullToSpace(order.buyInfo.itemIDs.upc),
    asin: CSVNullToSpace(order.itemInfo.marketData.asin),
    // distrProdID: CSVNullToSpace(orders.distrProdID),
    distrProdID: CSVNullToSpace(distr.prodID),
    marketUOM: CSVNullToSpace(order.itemInfo.uom),
    distrUOM: CSVNullToSpace(distr.uom),
    title: formatCSVString(order.itemInfo.name),
    qty: CSVNullToSpace(getQty(order.buyInfo)),
    price: CSVNullToSpace(distr.price),
    notes: CSVNullToSpace(order.marketplaceNotes),
    shipNotes: CSVNullToSpace(order.shipInfo.shipNotes),
  };
}

export const makeCSVOrderString = order => [
  `"${order.upc}"`, `"${order.asin}"`, `"${order.distrProdID}"`, `"${order.title}"`,
  order.qty, order.price, order.marketUOM, order.distrUOM,
  `"${order.notes}"`, `"${order.shipNotes}"`,
].join(',');

const columnHeadersLine = [
  '"UPC"', '"ASIN"', '"Distr Prod ID"', '"Title"',
  '"Qty"', '"Price"', '"Marketplace UOM"',
  '"Distributor UOM"', '"Notes"', '"ShipNotes"',
].join(',');

const makeGrouper = groupByFunction => (newList, order) => {
  const same = newList.findIndex(groupByFunction(order));
  if (same >= 0) {
    const copy = [...newList];
    copy[same].qty += order.qty;
    return copy;
  }
  return [...newList, order];
};

const mergeByUPC = makeGrouper(order => addedOrder => addedOrder.upc === order.upc);
const mergeByASIN = makeGrouper(order => addedOrder => addedOrder.asin === order.asin);

export const getOrderListCSV = list => list
  .map(groupOrderDataForCSV)
  .reduce(mergeByASIN, [])
  .map(makeCSVOrderString)
  .join('\n');

export const getOrderListCSVwithHeader = list =>
  `sep=,\n${columnHeadersLine}\n${getOrderListCSV(list)}`;

const getList = (params, token) => call(requestMaker, {
  headers: { Authorization: `JWT ${token}` },
  method: 'get',
  url: '/api/v1/order-shipping/',
  params,
});

const chosen = state => distr => distr.id === state.orderSearch.distributor;

const getDate = () => {
  const [year, month, day] = getNewYorkTime().split('T')[0].split('-');
  return `${month}.${day}.${year}`;
};

const hasAvailableProd = chosenDistrID => order => order.distrInfo.list
  .some(distr => distr.id === chosenDistrID);

const hasAvailableProdAndPublished = chosenDistrID => order => order.distrInfo.list
  .some(distr => distr.id === chosenDistrID && !distr.unpublish);

const setDistrProductID = filterDistrID => (order) => {
  const filterDistr = order.distrInfo.list.find(distr => distr.id === filterDistrID);
  return { ...order, distrProdID: filterDistr.prodID };
};

const setDistrChoice = choice => order => ({ ...order, distrInfo: { ...order.distrInfo, choice } });


const getOrderBulk = (orderList, choice) => orderList
  .map((order) => {
    const distr = getChosenDistr({ choice, list: order.distrInfo.list });
    return ({
      ...order.unique,
      changes: {
        distrID: choice,
        processingDate: getNewYorkTime(),
        distrPrice: distr.price,
        distrUOM: distr.uom,
        distrProdID: distr.prodID,
      },
    });
  });

function* updateOrders(distrID, token){
  yield call(requestMaker, {
    headers: { Authorization: `JWT ${token}` },
    method: 'patch',
    url: '/api/v1/order/update-distributors/',
    data: {
      exportStatus: orderStatus.export,
      reorderStatus: orderStatus.reorders,
      processingTime: getNewYorkTime(),
      distrID,
    },
  });
}

export default function* downloadCSVSaga() {
  const distr = yield select(state => state.orderSearch.distributor);
  const token = yield select(state => state.user.token);
  const chosenDistr = yield select(state => state.meta.distributors.find(chosen(state)));
  if (!chosenDistr) yield put(downloadCSVFailure({ distributor: 'distributor was not chosen' }));
  else {
    try {
      const exportParams = {
        status: orderStatus.export,
        limit: 0,
        distr,
      };
      const reorderParams = {
        status: orderStatus.reorders,
        limit: 0,
      };
      const exportResponse = yield getList(exportParams, token);
      const reordersResponse = yield getList(reorderParams, token);
      const reorderList = reordersResponse.data.list
        .filter(hasAvailableProd(distr)).map(setDistrProductID(distr)).map(setDistrChoice(distr));
      const orderList = [...exportResponse.data.list, ...reorderList];
      yield call(fileDownload,
        getOrderListCSVwithHeader(orderList),
        `${chosenDistr.text}-${getDate()}.csv`,
      );
      yield call(updateOrders, distr, token);
      yield put(downloadCSVSuccess());
      yield put(getOrders());
    }
    catch (error) {
      console.log(error);
      yield put(downloadCSVFailure(error));
    }
  }
};
