

export const departmentNames = {
  admin: 'Admin',
  customerService: 'Customer Service',
  shipping: 'Shipping',
  ordering: 'Ordering',
};

const departments = [
  {
    name: departmentNames.customerService,
    allowedPages: ['/orders', '/products'],
  },
  {
    name: departmentNames.admin,
    allowedPages: 'all',
  },
  {
    name: departmentNames.shipping,
    allowedPages: ['/orders', '/shipping', '/inventory'],
  },
  {
    name: departmentNames.ordering,
    allowedPages: ['/orders', '/distributors', '/inventory', '/products'],
  },
];

export const getDepartmentData = departmentName =>
  departments.find(dep => dep.name === departmentName);

export function isPageAllowedForDepartment(departmentName, path) {
  const department = getDepartmentData(departmentName);
  if (department) {
    if (department.allowedPages instanceof Array) {
      return department.allowedPages.includes(path);
    }
    return department.allowedPages === 'all';
  }
  return false;
}

export default departments