import {
  GET_PRODS_INVENTORY,
  CHANGE_INVENTORY_SEARCH,
  GET_PRODS_INVENTORY_SUCCESS,
  GET_PRODS_INVENTORY_FAILURE, CHANGE_PROD_INVENTORY_SUCCESS,
  DELETE_PROD_INVENTORY_SUCCESS, INSERT_PROD_INVENTORY_SUCCESS, CHANGE_LIMIT_INVENTORY, CHANGE_PAGE_INVENTORY,
} from '../actions';
import { getArrayWithReplaced } from '../../services/helpers';
import { getOffset } from '../getOffset';


const fakeProduct = upc => ({
  distrProdID: '5140123',
  imageUrl: 'item.png',
  name: 'Strawberry Big Plumbus Extra Carbs',
  brand: 'Pumbus intradimentional',
  weight: 2.4,
  uom: 1,
  size: '1foot',
  unpublish: false,
  price: 2.99,
  qty: 4,
  upc,
});

const initialInventory = {
  count: 0,
  offset: 0,
  limit: 50,
  loading: false,
  list: [],
};

const byUPC = upc => product => product.upc === upc;

export default function inventoryReducer(inventory = initialInventory, action){
  switch (action.type) {
    case DELETE_PROD_INVENTORY_SUCCESS:
      return {
        ...inventory,
        list: getArrayWithReplaced(inventory.list, null, byUPC(action.upc)),
        count: inventory.count - 1,
      };
    case CHANGE_LIMIT_INVENTORY:
      return { ...inventory, limit: action.limit };
    case CHANGE_PAGE_INVENTORY:
      return { ...inventory, page: action.page, offset: getOffset(inventory, action) };
    case GET_PRODS_INVENTORY:
      return { ...inventory, loading: true };
    case CHANGE_INVENTORY_SEARCH:
      return {
        ...inventory,
        page: 1,
        offset: 0,
        loading: true,
      };
    case INSERT_PROD_INVENTORY_SUCCESS:
      return { ...inventory, loading: true };
    case GET_PRODS_INVENTORY_FAILURE:
      return { ...inventory, list: initialInventory.list, loading: false };
    case GET_PRODS_INVENTORY_SUCCESS:
      return { ...inventory, loading: false, list: action.list, count: action.count };
    case CHANGE_PROD_INVENTORY_SUCCESS:
      return {
        ...inventory,
        list: getArrayWithReplaced(inventory.list, action.product, byUPC(action.upc)),
      };
    default: return inventory;
  }
}