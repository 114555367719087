import makeCountryNameCheck from './makeCountryNameCheck';

const USANames = [
  'us',
  'usa',
  'united states',
  'united states of america',
  'u.s.',
  'u.s.a.',
  'american samoa',
  'as',
  'guam',
  'gu',
  'northern mariana islands',
  'mp',
  'puerto rico',
  'pr',
  'united states virgin islands',
  'u.s. virgin islands',
  'us virgin islands',
  'vi',
];

const isUSA = makeCountryNameCheck(USANames);

export default isUSA;

