import React from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import { connect } from 'react-redux';

import '../../../styles/Table.css';
import {
  bulkEditPresets, makeHiddenInputRenderer, makeColumnSetuper,
} from '../../common/tableCellRenders';
import { changeProductsBulk } from '../../../redux/actions';
import { getNewYorkTime } from '../../../services/helpers';
import { makeSelectorRenderer } from './YesNoSelector';

const setupColumn = makeColumnSetuper('table__column', 'table__head table__head_marketplaces table__column');
const { decimalFieldPreset, integerFieldPreset, textFieldPreset } = bulkEditPresets;

const mapDispatchToProps = dispatch => ({
  makeSubmitter: (rowID, field) => value => dispatch(changeProductsBulk({
    marketplace: {
      [field]: value,
    },
  }, {
    marketplace: rowID,
  })),
  makeUnpublishSubmitter: (rowID) => value => dispatch(changeProductsBulk({
    marketplace: {
      unpublish: value,
      unpublishDate: value ? getNewYorkTime() : null,
    },
  }, {
    marketplace: rowID,
  })),
});

function TableChangeFilteredMarkets({ data, makeSubmitter, makeUnpublishSubmitter }) {
  const switchWithSubmit = makeSelectorRenderer(makeSubmitter, 'marketplaceid');
  const switchUnpublish = makeSelectorRenderer(makeUnpublishSubmitter, 'marketplaceid');
  const inputWithSubmit = preset =>
    makeHiddenInputRenderer(makeSubmitter, preset, 'marketplaceid');
  const columnSettings = [
    { accessor: 'isfood', minWidth: 89, Header: 'food', Cell: switchWithSubmit },
    { accessor: 'notes', minWidth: 349, Header: 'item notes', Cell: inputWithSubmit(textFieldPreset)},
    { accessor: 'weight', minWidth: 59, Cell: inputWithSubmit(decimalFieldPreset) },
    { accessor: 'map', minWidth: 63, Cell: inputWithSubmit(integerFieldPreset) },
    { accessor: 'uom', minWidth: 34, Cell: inputWithSubmit(integerFieldPreset) },
    { accessor: 'unpublish', minWidth: 78, Cell: switchUnpublish },
  ];
  const columns = columnSettings.map(setupColumn);
  return (
    <ReactTable
      noDataText="There are no products"
      data={data}
      columns={columns}
      className="table table-products__table"
      showPaginationBottom={false}
      loadingText=""
      resizable={false}
      minRows={1}
    />
  );
}


TableChangeFilteredMarkets.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  makeSubmitter: PropTypes.func,
  makeUnpublishSubmitter: PropTypes.func,
};

TableChangeFilteredMarkets.defaultProps = {
  data: [{
    marketplaceID: 0,
    isfood: 0,
    weight: '',
    map: '',
    uom: '',
    unpublish: 0,
  }],
  makeSubmitter: (rowID, field) => value => null,
  makeUnpublishSubmitter: (rowID, field) => value => null,
};

export default connect(null, mapDispatchToProps)(TableChangeFilteredMarkets)