import React from 'react';
import PropTypes from 'prop-types';
import PageError from '../../components/layout/page_error/PageError';


export default function PageNotFound() {
  return (
    <PageError message="Page not found" />
  );
}


PageNotFound.propTypes = {};

PageNotFound.defaultProps = {};
