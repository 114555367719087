import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './PageShipping.css';
import { TableOrdersShipping, withPermissionRedirection } from '../../containers';
import {
  getMetadata, resetOrderSearch, changeOrderWithShippingSearch, getOrdersWithShippingInfo,
  changeOrderWithShippingInfo
} from '../../redux/actions';

function mapStateToProps(state) {
  return {
    products: state.ordersShipping.list,
    loading: state.ordersShipping.loading,
    count: state.ordersShipping.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initialize: () => {
      dispatch(getMetadata());
      dispatch(getOrdersWithShippingInfo());
    },
    changeSearch: change => dispatch(changeOrderWithShippingSearch(change)),
    setOrderField: (ids, changes, loaderId) =>
      dispatch(changeOrderWithShippingInfo(ids, changes, loaderId)),
    cleanUp: () => {
      dispatch(resetOrderSearch());
    },
  };
}

class PageOrders extends React.PureComponent {
  static propTypes = {
    initialize: PropTypes.func,
    cleanUp: PropTypes.func,
    products: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    count: PropTypes.number,
    changeSearch: PropTypes.func,
  };
  static defaultProps = {
    initialize: () => {},
    cleanUp: () => {},
    loading: false,
    products: [],
    count: 0,
    changeSearch: null,
  };
  componentDidMount() {
    this.props.initialize();
  }
  componentWillUnmount() {
    this.props.cleanUp();
  }
  render() {
    return (
      <TableOrdersShipping {...this.props} />
    );
  }
}

export default withPermissionRedirection(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageOrders));