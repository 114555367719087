import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import TableAvailProds from './TableAvailProds';
import TableMarkets from './TableMarkets';

export default function PopupAvailProds({ availableProducts, marketplaces, loading, ...props }) {
  return (
    <div className="popup-avail-prods">
      <Loader size="big" active={loading} />
      <div className="popup-avail-prods__table-group">
        <TableAvailProds
          availableProducts={availableProducts}
          makeSubmitter={props.makeAvailProdsSubmitter}
          makeUnpublishSubmitter={props.makeAvailProdsUnpublishSubmitter}
        />
        <TableMarkets
          marketplaces={marketplaces}
          makeSubmitter={props.makeMarketSubmitter}
          makeUnpublishSubmitter={props.makeMarketUnpublishSubmitter}
          makeIsFoodSubmitter={props.makeMarketIsFoodSubmitter}
        />
      </div>
    </div>
  );
}

const PropTypesBoolNumber = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.number,
]);

PopupAvailProds.propTypes = {
  availableProducts: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    distributorproductid: PropTypes.string,
    price: PropTypes.number,
    qty: PropTypes.number,
    brand: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    weight: PropTypes.number,
    size: PropTypes.string,
    uom: PropTypes.number,
    unpublish: PropTypesBoolNumber,
  })),
  marketplaces: PropTypes.arrayOf(PropTypes.shape({
    imageurl: PropTypes.string,
    isfood: PropTypesBoolNumber,
    notes: PropTypes.string,
    weight: PropTypes.number,
    map: PropTypes.number,
    uom: PropTypes.number,
    unpublish: PropTypesBoolNumber,
  })),
  loading: PropTypes.bool,
  makeMarketSubmitter: PropTypes.func,
  makeMarketUnpublishSubmitter: PropTypes.func,
  makeMarketIsFoodSubmitter: PropTypes.func,
  makeAvailProdsSubmitter: PropTypes.func,
  makeAvailProdsUnpublishSubmitter: PropTypes.func,
};

PopupAvailProds.defaultProps = {
  availableProducts: [],
  marketplaces: [],
  loading: false,
  makeMarketSubmitter: (marketplaceid, field) => value => null,
  makeMarketUnpublishSubmitter: (marketplaceid, field) => value => null,
  makeMarketIsFoodSubmitter: () => value => null,
  makeAvailProdsSubmitter: (distributorid, field) => value => null,
  makeAvailProdsUnpublishSubmitter: distributorid => value => null,
};
