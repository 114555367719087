import { takeEvery, takeLatest, call, select } from 'redux-saga/effects';
import logInSaga from './logInSaga';
import {
  LOG_IN, LOAD_STATE, GET_METADATA,
  UPDATE_FIELED, POPUP_GET_AVAILABLE_PRODUCTS,
  GET_ORDERS, CHANGE_ORDER_SEARCH, CHANGE_ORDER,
  SAVE_DISTRIBUTOR_CHOICES_SUCCESS, SAVE_DISTRIBUTOR_CHOICES,
  DOWNLOAD_CSV, SEND_TO_ARCHIVE, SEND_TO_ARCHIVE_SUCCESS,
  CHANGE_DROPDOWN_VALUE, CHANGE_INPUT_VALUE, failureActions, POPUP_SET_MARKETS_TABLE_CELL,
  POPUP_SET_AVAIL_PRODS_TABLE_CELL, POPUP_SET_AVAIL_PRODS_TABLE_CELL_SUCCESS,
  POPUP_SET_MARKETS_TABLE_CELL_SUCCESS, CHANGE_PRODUCTS_BULK_SUCCESS,
  CHANGE_PRODUCTS_BULK, GET_PRODS_INVENTORY,
  CHANGE_INVENTORY_SEARCH, CHANGE_PROD_INVENTORY, INSERT_PROD_INVENTORY,
  INSERT_PROD_INVENTORY_SUCCESS, DELETE_PROD_INVENTORY,
  GET_MARKETPLACE_STATS, CHANGE_SHIP_DATE_SYSTEM_PAGE,
  GET_DISTR_STATS, DOWNLOAD_MARKET_CSV, CHANGE_PAGE_PRODUCTS, CHANGE_LIMIT_PRODUCTS,
  CHANGE_PAGE_ORDERS, CHANGE_PAGE_INVENTORY, CHANGE_LIMIT_ORDERS,
  CHANGE_LIMIT_INVENTORY, GET_SHIPPING_RATES, GET_ORDERS_WITH_SHIPPING_INFO,
  CHANGE_ORDER_WITH_SHIPPING_SEARCH, CHANGE_PAGE_SHIPPING, CHANGE_LIMIT_SHIPPING,
  CHANGE_ORDER_WITH_SHIPPING_INFO, BUY_SHIPMENT, REPRINT_LABEL,
  getOrders, getProdsInventory, changePageOrders, changePageInventory,
  getOrdersWithShippingInfo, changePageShipping, GET_PRODUCTS_TO_SHIP,
  CHANGE_ORDER_BULK, GET_REPORTS, INIT_REPORT_TIMER, CHANGE_PRODUCTS_BULK_WITHOUT_STORE,
  UPDATE_LOCAL_ORDER_SHIPPING, UPDATE_LOCAL_PRODUCT, POPUP_CHANGE_PRODUCTS_BULK, REFUND_SHIPMENT,
  PRINT_SCANFORM,
} from '../actions';
import saveStateSaga from './presistent_state/saveStateSaga';
import loadStateSaga from './presistent_state/loadStateSaga';
import getProductsSaga from './products/getProductsSaga';
import getMetadataSaga from './getMetadataSaga';
import getOrdersSaga from './orders/getOrdersSaga';
import updateFieldSaga from './products/updateFieldProductSaga';
import changeOrderSaga from './orders/changeOrderSaga';
import saveDistrChoices from './orders/saveDistrChoices';
import downloadCSVSaga from './csv/downloadCSVSaga';
import sendToArchiveSaga from './orders/sendToArchiveSaga';
import failureSaga from './failureSaga';
import getPopupAvailProdsSaga from './products_popup/getPopupAvailProdsSaga';
import setPopupMarketsSaga from './products_popup/setPopupMarketsSaga';
import setPopupAvailProdsSaga from './products_popup/setPopupAvailProdSaga';
import setProductBulkSaga from './products/setProductBulkSaga';
import getProdsInventorySaga from './inventory/getProdsInventorySaga';
import changeProdInvSaga from './inventory/changeProdInvSaga';
import insertProdInvSaga from './inventory/insertProdInvSaga';
import deleteProdInvSaga from './inventory/deleteProdInvSaga';
import getSystemStatsSaga from './statistics/getMarketplaceStatsSaga';
import getDistrStatsSaga from './statistics/getDistrStatsSaga';
import downloadMarketCSVSaga from './csv/downloadMarketCSVSaga';
import makeSagaChangePageAndCall from './pagination/makeSagaChangePageAndCall';
import makeSagaChangeLimitAndCall from './pagination/makeSagaChangeLimitAndCall';
import getShippingServiceRatesSaga from './shipping/getShippingServiceRatesSaga';
import getOrdersShippingSaga from './orders/getOrdersShippingSaga';
import changeOrderShippingSaga from './orders/changeOrderShippingSaga';
import buyShipmentSaga from './shipping/buyShipmentSaga';
import getProductsToShipSaga from './shipping/getProductsToShipSaga';
import reprintLabelSaga from './shipping/reprintLabelSaga';
import changeOrderBulkSaga from './orders/changeOrderBulkSaga';
import initReportTimer from './reports/initReportTimerSaga';
import repeatOnTimerGetReportsSaga from './reports/repeatOnTimerGetReportsSaga';
import setProductBulkWithoutStoreSaga from './products/setProductBulkWithoutStoreSaga';
import updateLocalOrderShippingSaga from './orders/updateLocalOrderShippingSaga';
import updateLocalProductSaga from './products/updateLocalProductSaga';
import refundShipmentSaga from './shipping/refundShipmentSaga';
import printScanformSaga from './shipping/saveScanformSaga';

export default function* rootSaga() {
  yield takeEvery(PRINT_SCANFORM, printScanformSaga);
  yield takeEvery(INIT_REPORT_TIMER, initReportTimer);
  yield takeEvery(GET_REPORTS, repeatOnTimerGetReportsSaga);
  yield takeEvery(LOAD_STATE, loadStateSaga);
  yield takeLatest([
    CHANGE_INPUT_VALUE,
    CHANGE_DROPDOWN_VALUE,
    CHANGE_PRODUCTS_BULK_SUCCESS,
    CHANGE_PAGE_PRODUCTS,
    CHANGE_LIMIT_PRODUCTS,
  ], getProductsSaga);
  yield takeLatest([
    GET_ORDERS,
    CHANGE_ORDER_SEARCH,
    SAVE_DISTRIBUTOR_CHOICES_SUCCESS,
    SEND_TO_ARCHIVE_SUCCESS,
  ], getOrdersSaga);
  yield takeEvery(UPDATE_LOCAL_ORDER_SHIPPING, updateLocalOrderShippingSaga);
  yield takeLatest(SAVE_DISTRIBUTOR_CHOICES, saveDistrChoices);
  yield takeEvery(CHANGE_ORDER, changeOrderSaga);
  yield takeEvery(CHANGE_ORDER_WITH_SHIPPING_INFO, changeOrderShippingSaga);
  yield takeEvery(UPDATE_LOCAL_PRODUCT, updateLocalProductSaga);
  yield takeLatest(SEND_TO_ARCHIVE, sendToArchiveSaga);
  yield takeEvery(GET_METADATA, getMetadataSaga);
  yield takeEvery(UPDATE_FIELED, updateFieldSaga);
  yield takeEvery(CHANGE_ORDER_BULK, changeOrderBulkSaga);
  yield takeLatest('*', saveStateSaga);
  yield takeLatest([
    POPUP_GET_AVAILABLE_PRODUCTS,
    POPUP_SET_AVAIL_PRODS_TABLE_CELL_SUCCESS,
    POPUP_SET_MARKETS_TABLE_CELL_SUCCESS,
  ], getPopupAvailProdsSaga);
  yield takeLatest([
    GET_PRODS_INVENTORY,
    CHANGE_INVENTORY_SEARCH,
    INSERT_PROD_INVENTORY_SUCCESS,
  ], getProdsInventorySaga);
  yield takeLatest([
    GET_MARKETPLACE_STATS,
    CHANGE_SHIP_DATE_SYSTEM_PAGE,
  ], getSystemStatsSaga);
  yield takeLatest([
    GET_ORDERS_WITH_SHIPPING_INFO,
    CHANGE_ORDER_WITH_SHIPPING_SEARCH,
  ], getOrdersShippingSaga);
  yield takeEvery(BUY_SHIPMENT, buyShipmentSaga);
  yield takeEvery(REFUND_SHIPMENT, refundShipmentSaga);
  yield takeEvery(REPRINT_LABEL, reprintLabelSaga);
  yield takeLatest(GET_SHIPPING_RATES, getShippingServiceRatesSaga);
  yield takeLatest(DOWNLOAD_MARKET_CSV, downloadMarketCSVSaga);
  yield takeLatest(CHANGE_PRODUCTS_BULK, setProductBulkSaga);
  yield takeLatest(GET_DISTR_STATS, getDistrStatsSaga);
  yield takeLatest(CHANGE_PAGE_ORDERS, makeSagaChangePageAndCall(getOrders));
  yield takeLatest(CHANGE_PAGE_INVENTORY, makeSagaChangePageAndCall(getProdsInventory));
  yield takeLatest(CHANGE_PAGE_SHIPPING, makeSagaChangePageAndCall(getOrdersWithShippingInfo));
  yield takeLatest(CHANGE_LIMIT_ORDERS, makeSagaChangeLimitAndCall(changePageOrders));
  yield takeLatest(CHANGE_LIMIT_INVENTORY, makeSagaChangeLimitAndCall(changePageInventory));
  yield takeLatest(CHANGE_LIMIT_SHIPPING, makeSagaChangeLimitAndCall(changePageShipping));
  yield takeLatest(GET_PRODUCTS_TO_SHIP, getProductsToShipSaga);
  yield takeEvery([
    CHANGE_PRODUCTS_BULK_WITHOUT_STORE,
    POPUP_CHANGE_PRODUCTS_BULK,
  ], setProductBulkWithoutStoreSaga);
  yield takeEvery(INSERT_PROD_INVENTORY, insertProdInvSaga);
  yield takeEvery(CHANGE_PROD_INVENTORY, changeProdInvSaga);
  yield takeEvery(DELETE_PROD_INVENTORY, deleteProdInvSaga);
  yield takeEvery(POPUP_SET_MARKETS_TABLE_CELL, setPopupMarketsSaga);
  yield takeEvery(POPUP_SET_AVAIL_PRODS_TABLE_CELL, setPopupAvailProdsSaga);
  yield takeEvery(LOG_IN, logInSaga);
  yield takeEvery(DOWNLOAD_CSV, downloadCSVSaga);
  yield takeEvery(failureActions, failureSaga);
  // yield takeEvery('*', log);
}
