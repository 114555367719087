import React from 'react';
import PropTypes from 'prop-types';
import { Loader, TextArea } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import InputWrapper from '../../../containers/WithLoaderAndSubmit';


function TextAreaWithLoader({ className, loading, loaderID, ...props }) {
  return (
    <div className={className}>
      {loading
        ? <div className="loader-placeholder">
            <Loader active />
          </div>
        : <TextArea {...props} className={className} />}
    </div>
  );
}


TextAreaWithLoader.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
};

TextAreaWithLoader.defaultProps = {
  loading: false,
  className: '',
};

export default InputWrapper(TextAreaWithLoader);
