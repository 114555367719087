import React from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';

import RowTableProducts from './RowTableProducts';
// import HeaderTableProducts from '../../../containers/HeaderTableProducts';
import HeaderTableProducts from './HeaderTableProducts';
import './TableProducts.css';


function renderRowTableProducts(product, i) {
  return (
    <RowTableProducts key={uuidv4()} product={product} productId={product.sku} productNumber={i} />
  );
}


export default function TableProducts({ products, marketplaces, distributors, loading }) {
  return (
    <div className="working-area__table-products">
      <table className="table-products">
        <tbody>
          <HeaderTableProducts distributors={distributors} marketplaces={marketplaces} />
          {!loading && products.map(renderRowTableProducts)}
        </tbody>
      </table>
    </div>
  );
}


TableProducts.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
};
