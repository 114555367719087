import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import './SwitchRequireSignature.css';
import { setShipmentSignatureRequiredFlag } from '../../../../../redux/actions';
import { poke } from '../../../../../services/helpers/index'

const mapStateToProps = state => ({ checked: state.shipment.signatureRequired});

const mapDispatchToProps = dispatch => ({
  handleChange: (event, { checked }) => dispatch(setShipmentSignatureRequiredFlag(checked)),
});

function SwitchRequireSignature({ checked, handleChange }) {
  return (
    <div className="switch-require-signature">
      <Checkbox
        toggle
        label="Require signature"
        checked={checked}
        onChange={handleChange}
        className="switch-require-signature__checkbox"
      />
    </div>
  );
}


SwitchRequireSignature.propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
};

SwitchRequireSignature.defaultProps = {
  checked: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchRequireSignature)