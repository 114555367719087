import {
  LOG_IN_SUCCESS, LOG_OUT, LOAD_STATE_SUCCESS, failureActions, LOG_IN_FAILURE,
  HIDE_LOGIN_ERROR, FLAG_AS_ALLOWED, FLAG_AS_FORBIDDEN,
} from '../actions';
import { getResponseStatus } from '../../services/helpers';

const initialUser = {
  logged: false,
  username: '',
  token: '',
  forbidden: false,
  error: null,
  department: '',
  imageURL: '',
};

const getError = action => action.error && action.error.response && action.error.response.data;

const getNonFieldErrors = (action) => {
  const errorData = getError(action);
  if (errorData && errorData.non_field_errors) {
    return errorData.non_field_errors;
  }
  return null;
};

const isExpired = action => {
  const errorData = getError(action);
  if (errorData) {
    return errorData.details === 'Signature has expired.';
  }
  return false;
};

const makeStatusDetector = statusCode => action =>
  failureActions.includes(action.type) && getResponseStatus(action.error) === statusCode;

const notAuthorized = makeStatusDetector(401);

const isStatusForbidden = makeStatusDetector(403);

const isForbidden = action => isStatusForbidden(action) || action.type === FLAG_AS_FORBIDDEN;

export default function userReducer(user = initialUser, action) {
  if (notAuthorized(action)) {
    return { ...user, logged: false};
  } else if (isForbidden(action)) {
    return { ...user, forbidden: true };
  }
  switch (action.type) {
    case LOAD_STATE_SUCCESS:
      return { ...action.data.user, error: null };
    case LOG_IN_FAILURE:
      return { ...user, error: getNonFieldErrors(action) };
    case LOG_IN_SUCCESS:
      return {
        auth: true,
        logged: true,
        department: action.department,
        username: action.username,
        imageURL: action.image_url,
        token: action.token,
      };
    case HIDE_LOGIN_ERROR:
      return { ...user, error: null };
    case LOG_OUT:
      return initialUser;
    case FLAG_AS_ALLOWED:
      return { ...user, forbidden: false };
    default:
      return user;
  }
}
