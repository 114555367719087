import React from 'react';
import PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import { connect } from 'react-redux';

import { ButtonWithIcon, InputSearch } from '../../common/';
import './TableOrders.css';
import '../../../styles/Panel.css';
import { changeOrderSearch, saveDistributorChoices } from '../../../redux/actions';

const styles = {
  header: 'table-orders__cell_head table-orders__cell',
  headerFirst: 'table-orders__cell_head table-orders__cell table-orders__column_first',
  search: 'table-orders__search-bar ',
  checkbox: 'table-orders__checkbox',
  button: 'table-orders__button',
  buttonContainer: 'table-orders__input-container',
  input: 'table-orders__input',
  inputContainer: 'table-orders__input-container',
};

const mapStateToProps = ({ orderSearch }) => ({
  itemInfo: orderSearch.itemInfo,
  customer: orderSearch.customer,
  orderID: orderSearch.orderID,
  itemID: orderSearch.itemID,
});

const mapDispatchToProps = (dispatch, props) => ({
  setDistributors: () => dispatch(saveDistributorChoices()),
});


export class HeaderTableProducts extends React.PureComponent {
  makeSearchSubmitter = field => ({ target }) => this.props.changeSearch({
    [field]: target.value,
  });
  render() {
    return (
      <tr>
        <th className={styles.header}>
          <div className={styles.search}>
            <InputSearch
              placeHolder="OrderID..."
              styles={styles}
              value={this.props.orderID}
              submit={this.makeSearchSubmitter('orderID')}
            />
            <InputSearch
              placeHolder="Buyers Info..."
              styles={styles}
              value={this.props.customer}
              submit={this.makeSearchSubmitter('customer')}
            />
            <InputSearch
              placeHolder="Item Info..."
              styles={styles}
              value={this.props.itemInfo}
              submit={this.makeSearchSubmitter('itemInfo')}
            />
            <InputSearch
              placeHolder="Item ID..."
              styles={styles}
              value={this.props.itemID}
              submit={this.makeSearchSubmitter('itemID')}
            />
            <div className={styles.buttonContainer}>
              <ButtonWithIcon
                icon="add to cart"
                className={styles.button}
                title="Set Distributor"
                onClick={this.props.setDistributors}
              />
            </div>
          </div>
        </th>
      </tr>
    );
  }
}

HeaderTableProducts.propTypes = {
  itemInfo: PropTypes.string,
  customer: PropTypes.string,
  orderID: PropTypes.string,
  itemID: PropTypes.string,
  changeSearch: PropTypes.func,
  setDistributors: PropTypes.func,
};

HeaderTableProducts.defaultProps = {
  itemInfo: '',
  customer: '',
  orderID: '',
  itemID: '',
  changeSearch: changes => {},
  setDistributors: () => {},
};


export default connect(mapStateToProps, mapDispatchToProps)(HeaderTableProducts);
