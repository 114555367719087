import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/lib/css/_datepicker.css';

import { SingleDatePicker, toMomentObject } from 'react-dates';
import './InputDate.css';

class InputDate extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      focused: false,
    };
    this.cleared = false;
  }
  getDefaultWhenFocused = () => (this.state.focused && this.props.default) ? this.props.default : '';
  handleChange = (date) => {
    this.props.handleChange(date ? date.format('YYYY-MM-DD') : null);
  };
  handleFocus = ({ focused }) => this.setState({ focused });
  handleKeyUp = ({ key }) => {
    if (key === 'Backspace') {
      this.setState({ focused: false });
      this.handleChange(null);
    }
  };
  isOutsideRange = () => false;
  render() {
    return (
      <div
        className={`input-date__container ${this.props.className}`}
        onKeyUp={this.handleKeyUp}
      >
        <SingleDatePicker
          date={toMomentObject(this.props.date || this.getDefaultWhenFocused())}
          focused={this.state.focused}
          onFocusChange={this.handleFocus}
          onDateChange={this.handleChange}
          isOutsideRange={this.isOutsideRange}
          readOnly={this.state.focused}
          noBorder
        />
      </div>
    );
  }
}

InputDate.propTypes = {
  date: PropTypes.string,
  handleChange: PropTypes.func,
  default: PropTypes.string,
  className: PropTypes.string,
};
InputDate.defaultProps = {
  date: null,
  handleChange: () => {},
  default: '',
  className: '',
};

export default InputDate;
