import React from 'react';
import PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import { connect } from 'react-redux';

import { InputSearch } from '../../common';
import './TableInventory.css';
import '../../../styles/Panel.css';
import '../grid_inventory/GridInventory.css';
import { changeInventorySearch } from '../../../redux/actions';

const styles = {
  header: 'table-inventory__cell_head table-inventory__cell',
  headerFirst: 'table-inventory__cell_head table-inventory__cell table-inventory__column_first',
  search: 'table-inventory__search-bar ',
  checkbox: 'table-inventory__checkbox',
  input: 'table-inventory__input',
  inputContainer: 'table-inventory__input-container grid-inventory__container_info',
  space: 'table-inventory__container grid-inventory__container_props',
};

const itemIDstyles = {
  input: styles.input,
  inputContainer: 'table-inventory__input-container grid-inventory__container_id',
};

const mapStateToProps = ({ inventorySearch }) => ({
  itemInfo: inventorySearch.itemInfo,
  itemID: inventorySearch.itemID,
});

const makeFieldDispatchFactory = (dispatch, actionMaker) => field => ({ target }) =>
  dispatch(actionMaker({ [field]: target.value }));

const mapDispatchToProps = (dispatch, props) => {
  const makeSearchFieldDispatcher = makeFieldDispatchFactory(dispatch, changeInventorySearch);
  return ({
    submitItemInfo: makeSearchFieldDispatcher('itemInfo'),
    submitItemID: makeSearchFieldDispatcher('itemID'),
  });
};

export class HeaderTableProducts extends React.PureComponent {
  render() {
    return (
      <tr>
        <th className={styles.header}>
          <div className={styles.search}>
            <InputSearch
              placeHolder="Item ID..."
              styles={itemIDstyles}
              value={this.props.itemID}
              submit={this.props.submitItemID}
            />
            <InputSearch
              placeHolder="Item Info..."
              styles={styles}
              value={this.props.itemInfo}
              submit={this.props.submitItemInfo}
            />
            <div className={styles.space} />
          </div>
        </th>
      </tr>
    );
  }
}

HeaderTableProducts.propTypes = {
  itemInfo: PropTypes.string,
  customer: PropTypes.string,
  orderID: PropTypes.string,
  itemID: PropTypes.string,
  submitItemInfo: PropTypes.func,
  submitItemID: PropTypes.func,
  submitOrderID: PropTypes.func,
  submitCustomer: PropTypes.func,
  setDistributors: PropTypes.func,
};

HeaderTableProducts.defaultProps = {
  itemInfo: '',
  customer: '',
  orderID: '',
  itemID: '',
  submitItemInfo: () => '',
  submitItemID: () => '',
  submitOrderID: () => '',
  submitCustomer: () => '',
  setDistributors: () => null,
};


export default connect(mapStateToProps, mapDispatchToProps)(HeaderTableProducts);
