import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../../../styles/Panel.css';
import './PanelItemInfo.css';
import {
  CardWhite, CardLabeled, CardImage,
  InputHiddenMultiline as InputHiddenMultilineWithoutLoader,
  InputDisguised as InputHiddenWithoutLoader,
} from '../../common';
import { withLoader } from '../../../containers';
import {
  makeValidatorDecimalWithRegEx as makeValidatorDecimal,
  validateIntegerWithRegEx as validateInteger } from '../../../services/validators';
import { makeOrderHash } from '../../../services/helpers';
import PanelItemInfoLayout from './PanelItemInfoLayout';


const itemTitleValidator = value => value.trim().length > 3;

const InputHidden = withLoader(InputHiddenWithoutLoader);
const InputHiddenMultiline = withLoader(InputHiddenMultilineWithoutLoader);

const makeItemLink = ({ marketplaceID, asin, ebayID }) => {
  switch (marketplaceID) {
    case 1: return `https://www.amazon.com/dp/${asin}`;
    case 2: return `https://www.amazon.ca/dp/${asin}`;
    case 4: return `https://www.amazon.co.uk/dp/${asin}`;
    case 3: return `https://www.amazon.de/dp/${asin}`;
    case 5: return `https://www.ebay.com/itm/${ebayID}`;
    case 6: return `https://www.amazon.co.jp/dp/${asin}`;
    case 8: return `https://www.amazon.com.au/dp/${asin}`;
    default: return '';
  }
};

const SMALL_SCREEN = {
  WIDTH: 1024,
  LETTER_SIZE: 7.5,
};
const LETTER_SIZE = 9.5;

const getLetterSize = () => {
  if (window.innerWidth > SMALL_SCREEN.WIDTH) return LETTER_SIZE;
  return SMALL_SCREEN.LETTER_SIZE;
};

class PanelItemInfo extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.hash = makeOrderHash(props.ids);
  }
  getFieldHash(field) {
    return `${this.hash}.${field}`;
  }
  submitTitle = (value) => {
    const { ids, setOrderField } = this.props;
    setOrderField(ids, {
      title: value,
      customsTitle: value,
    }, this.getFieldHash('title'));
  };
  makeSubmitter = field => (value) => {
    const { ids, setOrderField } = this.props;
    setOrderField(ids, { [field]: value }, this.getFieldHash(field));
  };
  render(){
    const { itemInfo, shipInfo } = this.props;
    return (
      <PanelItemInfoLayout
        nameComponent={
          <CardWhite>
            <InputHiddenMultiline
              value={itemInfo.name}
              loaderID={this.getFieldHash('title')}
              submit={this.submitTitle}
              validate={itemTitleValidator}
              letterSize={getLetterSize()}
              placeholder="-"
              fitString
            />
          </CardWhite>
        }
        imageComponent={
          <CardImage imageUrl={itemInfo.imageURL} url={makeItemLink(itemInfo.marketData)} />
        }
        uomComponent={
          <CardLabeled label="uom" caution={itemInfo.uom > 1}>
            <InputHidden
              loaderID={this.getFieldHash('uom')}
              value={itemInfo.uom}
              validate={validateInteger}
              submit={this.makeSubmitter('uom')}
              type="number"
            />
          </CardLabeled>
        }
        weightComponent={
          <CardLabeled
            label="lbs"
          >
            <InputHidden
              loaderID={this.getFieldHash('weight')}
              value={itemInfo.weight}
              validate={makeValidatorDecimal(6, 2)}
              submit={this.makeSubmitter('weight')}
              type="number"
            />
          </CardLabeled>
        }
      />
    );
  }
}

PanelItemInfo.propTypes = {
  itemInfo: PropTypes.shape({
    name: PropTypes.string,
    imageURL: PropTypes.string,
    uom: PropTypes.number,
    weight: PropTypes.number,
    marketData: PropTypes.shape({
      asin: PropTypes.string,
      ebayID: PropTypes.string,
      marketplaceID: PropTypes.number,
    }),
  }),
  ids: PropTypes.shape({
    orderID: PropTypes.string,
    orderItemID: PropTypes.string,
    marketplaceID: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  makeSubmitter: PropTypes.func,
  makeTitleSubmitter: PropTypes.func,
  setOrderField: PropTypes.func,
};

PanelItemInfo.defaultProps = {
  itemInfo: {
    name: '',
    imageURL: '',
    uom: 0,
    weight: 0,
    marketData: {
      asin: '',
      ebayID: '',
      marketplaceID: 0,
    },
  },
  ids: {
    orderID: '',
    orderItemID: '',
    marketplaceID: 0,
  },
  makeSubmitter: (field, loaderID) => value => null,
  makeTitleSubmitter: loaderID => value => null,
  setOrderField: () => null,
};


export default PanelItemInfo;
