import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/Panel.css';
import './PanelInvID.css';
import CardSimple from '../../common/card_simple/CardSimple';
import CardWhite from '../../common/card_white/CardWhite';
import { InputDisguised as InputHiddenWithoutLoader } from '../../common/input_disguised/InputDisguised';
import WithLoader from '../../../containers/WithLoader';

const InputHidden = WithLoader(InputHiddenWithoutLoader);

export default function PanelInvID({ upc, distrProdID, makeSubmitter, getLoaderID }) {
  return (
    <div className="panel panel_short panel_one-column panel-inv-id">
      <div className="panel__container">
        <CardSimple data={upc} />
      </div>
      <div className="panel__container">
        <CardWhite>
          <InputHidden
            value={distrProdID}
            submit={makeSubmitter('distrProdID')}
            loaderID={getLoaderID('distrProdID')}
          />
        </CardWhite>
      </div>
    </div>
  );
}


PanelInvID.propTypes = {
  upc: PropTypes.string,
  distrProdID: PropTypes.string,
  makeSubmitter: PropTypes.func,
  getLoaderID: PropTypes.func,
};

PanelInvID.defaultProps = {
  upc: '',
  distrProdID: '',
  makeSubmitter: field => value => null,
  getLoaderID: field => field,
};
