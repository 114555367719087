import React from 'react';
import PropTypes from 'prop-types';

import RowTableInventory from './RowTableInventory';
import HeaderTableInventory from './HeaderTableInventory';
import './TableInventory.css';

function renderRowTableInventory(product, i) {
  return (
    <RowTableInventory
      key={`prod-${product.upc}`}
      product={product}
    />
  );
}


export default function TableOrders({ products, loading }) {
  return (
    <div className="working-area__table-products">
      <table className="table-products">
        <tbody>
          <HeaderTableInventory />
          {!loading && products.map(renderRowTableInventory)}
        </tbody>
      </table>
    </div>
  );
}


TableOrders.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    upc: PropTypes.string,
  })),
  loading: PropTypes.bool,
};

TableOrders.defaultProps = {
  products: [],
  loading: false,
};
