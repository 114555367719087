import { put, call, select } from 'redux-saga/effects';
import { requestMaker } from '../../../services/settings';

export default function* setAvailableProduct(data, successAction, failureAction) {
  const token = yield select(state => state.user.token);
  try {
    yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'patch',
      url: '/api/v1/products/',
      data,
    });
    yield put(successAction());
  } catch (error) {
    yield put(failureAction(error));
  }
};
