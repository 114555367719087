import React from 'react';
import PropTypes from 'prop-types';


export default function PanelItemInfoLayout(props) {
  const {
    nameComponent, imageComponent, uomComponent, weightComponent,
  } = props;
  return (
    <div className="panel panel-item_info">
      <div className="panel__container panel-item_info__container_name">
        {nameComponent}
      </div>
      <div className="panel__container panel-item_info__container_image">
        {imageComponent}
      </div>
      <div className="panel__container panel-item_info__container_uom">
        {uomComponent}
      </div>
      <div className="panel__container panel-item_info__container_lbs">
        {weightComponent}
      </div>
    </div>
  );
}


PanelItemInfoLayout.propTypes = {
  nameComponent: PropTypes.node,
  imageComponent: PropTypes.node,
  uomComponent: PropTypes.node,
  weightComponent: PropTypes.node,
};

PanelItemInfoLayout.defaultProps = {
  nameComponent: '',
  imageComponent: '',
  uomComponent: '',
  weightComponent: '',
};
