import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { changeShipDateSystemPage, getDistrStats, getMetadata } from '../../redux/actions';
import { getNewYorkDate } from '../../services/helpers';
import { withPermissionRedirection, ContentSystem } from '../../containers/index';

function mapDispatchToProps(dispatch) {
  return {
    initialize: () => {
      dispatch(getMetadata());
      dispatch(changeShipDateSystemPage(getNewYorkDate()));
      dispatch(getDistrStats());
    },
  };
}

class PageSystem extends React.PureComponent {
  static propTypes = {
    initialize: PropTypes.func,
  };
  static defaultProps = {
    initialize: () => {},
  };
  componentDidMount() {
    this.props.initialize();
  }
  render() {
    return (
      <ContentSystem />
    );
  }
}

export default withPermissionRedirection(connect(
  null,
  mapDispatchToProps,
)(PageSystem));