const sequrePaths = {
  'http://ecx.images-amazon.com': 'https://images-na.ssl-images-amazon.com',
};

export default function sequreImageURL(imageURL) {
  if (typeof (imageURL) !== 'string') return imageURL;
  const domain = Object.keys(sequrePaths)
    .find(path => imageURL.startsWith(path));
  if (domain) {
    return `${sequrePaths[domain]}${imageURL.slice(domain.length)}`;
  }
  return imageURL;
}