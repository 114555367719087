import { combineReducers } from 'redux';

import loaders from './loadersReducer';
import productList from './productListReducer';
import orders from './ordersReducer';
import ordersShipping from './ordersShippingReducer'
import user from './userReducer';
import search from './searchReducer';
import orderSearch from './orderSearchReducer';
import meta from './metaReducer';
import orderDistrBuffer from './orderDistrBufferReducer';
import inventory from './inventoryReducer';
import popupAvailProds from './popupAvailProdsReducer';
import inventorySearch from './inventorySearchReducer';
import systemStats from './systemStatsReducer';
import shipment from './shipmentReducer';
import productsToShip from './productsToShipReducer';
import reports from './reportsReducer';
import tempParcelSizes from './tempParcelSizesReducer';

export default combineReducers({
  loaders,
  productList,
  orders,
  ordersShipping,
  inventory,
  inventorySearch,
  orderSearch,
  orderDistrBuffer,
  user,
  search,
  meta,
  popupAvailProds,
  systemStats,
  productsToShip,
  shipment,
  reports,
  tempParcelSizes,
});
