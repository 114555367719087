import makeReducer from '../makeReducer';
import {
  activateLoading, setFirstPageLoading, setLimit, changePage,
  saveList, makeClearList, handleOrderChange,
} from './ordersReducer';
import {
  GET_ORDERS_WITH_SHIPPING_INFO, GET_ORDERS_WITH_SHIPPING_INFO_FAILURE,
  GET_ORDERS_WITH_SHIPPING_INFO_SUCCESS, CHANGE_ORDER_WITH_SHIPPING_SEARCH,
  CHANGE_ORDER_WITH_SHIPPING_INFO_SUCCESS, CHANGE_PAGE_SHIPPING, CHANGE_LIMIT_SHIPPING,
  UPDATE_SHIPPED_ORDERS,
} from '../actions';

const initialOrders = {
  list: [],
  loading: false,
  offset: 0,
  count: 0,
  limit: 50,
};

const withSameIDs = order => newOrder => newOrder.unique.orderID === order.unique.orderID
  && newOrder.unique.orderItemID === order.unique.orderItemID
  && newOrder.unique.marketplaceID === order.unique.marketplaceID;

const updateOrderByList = updateOrders => order => updateOrders.find(withSameIDs(order)) || order;

const updateOrders = (state, { list }) => ({
  ...state, list: state.list.map(updateOrderByList(list)),
});

const actionHandlers = {
  [GET_ORDERS_WITH_SHIPPING_INFO]: activateLoading,
  [CHANGE_ORDER_WITH_SHIPPING_SEARCH]: setFirstPageLoading,
  [CHANGE_LIMIT_SHIPPING]: setLimit,
  [CHANGE_PAGE_SHIPPING]: changePage,
  [UPDATE_SHIPPED_ORDERS]: updateOrders,
  [GET_ORDERS_WITH_SHIPPING_INFO_SUCCESS]: saveList,
  [GET_ORDERS_WITH_SHIPPING_INFO_FAILURE]: makeClearList(initialOrders),
  [CHANGE_ORDER_WITH_SHIPPING_INFO_SUCCESS]: handleOrderChange,
};

export default makeReducer(initialOrders, actionHandlers);