import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

import GridShipping from '../grid_shipping/GridShipping';
import '../../page_orders/table_orders/TableOrders.css';


export default function RowOrders({ order, setOrderField }) {
  if (order) {
    return (
      <tr>
        <td className="table-orders__cell">
          <div className="table-orders__content">
            <GridShipping order={order} setOrderField={setOrderField} />
          </div>
        </td>
      </tr>
    );
  }
  return null;
}


RowOrders.propTypes = {
  order: PropTypes.shape({}),
  setOrderField: PropTypes.func,
};

RowOrders.defaultProps = {
  order: null,
  setOrderField: () => {},
};
