import { put, call, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { INTERVAL_GET_REPORTS, requestMaker } from '../../../services/settings';
import { getNewYorkTime, getTimeBeforeIntervalEnd } from '../../../services/helpers';
import { getReports, getReportsSuccess, getReportsFailure, stopReportTimer } from '../../actions';

const selector = ({ reports, user }) => ({
  newerThan: reports.lastReportTime,
  timerID: reports.timerID,
  user,
});

const ifListIsNotEmpty = (list, currentTime, previousTime) =>
  (list && list.length) ? currentTime : previousTime;

function* runTimer(params, user, timerID) {
  const { currentTime, newerThan } = params;
  try {
    const response = yield call(requestMaker, {
      headers: { Authorization: `JWT ${user.token}` },
      method: 'get',
      url: '/api/v1/system-reports/',
      params,
    });
    yield put(getReportsSuccess(
      response.data.list,
      ifListIsNotEmpty(response.data.list, currentTime, newerThan),
    ));
  } catch (error) {
    yield put(getReportsFailure(error));
  }
  yield call(delay, getTimeBeforeIntervalEnd(INTERVAL_GET_REPORTS));
  if (user.logged) yield put(getReports(timerID));
  else yield put(stopReportTimer());
}

export default function* repeatOnTimerGetReportsSaga(action) {
  const currentTime = yield call(getNewYorkTime);
  const { newerThan, user, timerID } = yield select(selector);
  if (user.logged) {
    if (!timerID || timerID === action.timerID) {
      const params = { currentTime, enabled: 1, resultAvoid: 0 };
      if (newerThan) params.newerThan = newerThan;
      yield call(runTimer, params, user, action.timerID);
    }
  } else {
    yield put(stopReportTimer());
  }
};
