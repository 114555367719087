import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import './ButtonUpdateRates.css';

export default function ButtonUpdateRates({ onClick }) {
  return (
    <Button
      size="huge"
      color="grey"
      className="button-update-rates"
      onMouseUp={onClick}
    >
      UPDATE RATES
    </Button>
  );
}

ButtonUpdateRates.propTypes = {
  onClick: PropTypes.func,
};

ButtonUpdateRates.defaultProps = {
  onClick: () => {},
};
