import React from 'react';
import PropTypes from 'prop-types';
import CardImage from '../../common/card_image/CardImage';
import CardWhite from '../../common/card_white/CardWhite';
import TableMarketShipCount from '../table_market_ship_count/TableMarketShipCount';
import '../../../styles/Panel.css';
import './PanelMarketShipCount.css';

export default function PanelMarketShipCount({ marketplace }) {
  const { imageURL, name, ...stats } = marketplace;
  return (
    <div className="panel panel-market-ship-count">
      <div className="panel__container panel-market-ship-count__container panel-market-ship-count__container_image">
        <CardImage
          imageUrl={imageURL}
          placeholderText={name}
          className="panel-market-ship-count__image-card"
        />
      </div>
      <div className="panel__container panel-market-ship-count__container panel-market-ship-count__container_image">
        <CardWhite>
          <TableMarketShipCount {...stats} />
        </CardWhite>
      </div>
    </div>
  );
}


PanelMarketShipCount.propTypes = {
  marketplace: PropTypes.shape({
    shipped: PropTypes.number,
    notShipped: PropTypes.number,
    total: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    imageURL: PropTypes.string,
  }).isRequired,
};

PanelMarketShipCount.defaultProps = {};
