import { put, call, select } from 'redux-saga/effects';
import { getIDsFromHash, getNewYorkTime, makeOrderHash, orderStatus } from '../../../services/helpers';
import { requestMaker } from '../../../services/settings';
import {
  saveDistributorChoicesSuccess,
  saveDistributorChoicesFailure,
} from '../../actions';


const getOrder = (list, hash) => list.find( order => makeOrderHash(order.unique) === hash);

const getChosenDistr = (choice, order) =>
  order.distrInfo.list.find( distr => distr.id === choice);

const getOrderBulk = state => Object.keys(state.orderDistrBuffer)
  .map((hash) => {
    const choice = state.orderDistrBuffer[hash];
    const order = getOrder(state.orders.list, hash);
    if (!order) return null;
    const distr = getChosenDistr(choice, order);
    return ({
      ...getIDsFromHash(hash),
      changes: {
        distrID: choice,
        status: orderStatus.export,
        processingDate: getNewYorkTime(),
        distrPrice: distr.price,
        distrUOM: distr.uom,
        distrProdID: distr.prodID,
      },
    });
  }).filter(entry => entry);

const getShipOrderBulk = state => Object.keys(state.orderDistrBuffer)
  .map((hash) => {
    const order = getOrder(state.orders.list, hash);
    if (!order) return null;
    return ({
      upc: order.buyInfo.itemIDs.upc,
      orderID: order.unique.orderID,
    });
  }).filter(entry => entry);

export default function* saveDistrChoices() {
  const bulk = yield select(getOrderBulk);
  const shipOrderDeleteBulk = yield select(getShipOrderBulk);
  const token = yield select(state => state.user.token);
  try {
    yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'patch',
      url: '/api/v1/order/',
      data: { bulk },
    });
    yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'delete',
      url: '/api/v1/ship-order/',
      data: { bulk: shipOrderDeleteBulk },
    });
    yield put(saveDistributorChoicesSuccess());
  }
  catch (error) {
    yield put(saveDistributorChoicesFailure(error));
  }
};
