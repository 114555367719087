import React from 'react';
import PropTypes from 'prop-types';

import ButtonReprintLabel from './popup_shipping/button_reprint_label/ButtonReprintLabel';
import './PanelSize.css';
import './popup_shipping/PopupShipping.css';

export default function FragmentReprintLabelProposal({ shipmentID }) {
  return (
    <div className="popup-shipping__message__line">
      This order has a purchased label. Do you want to reprint it?
      <div className="popup-shipping__message__button-container">
        <ButtonReprintLabel shipmentID={shipmentID} />
      </div>
    </div>
  );
}


FragmentReprintLabelProposal.propTypes = {
  shipmentID: PropTypes.string,
};

FragmentReprintLabelProposal.defaultProps = {
  shipmentID: '',
};
