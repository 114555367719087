import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import '../PopupShipping.css';
import ButtonReprintLabel from '../button_reprint_label/ButtonReprintLabel';
import TableProductsToShip from './table_products_to_ship/TableProductsToShip';
import InputNotes from './input_notes/InputNotes';
import SwitchRequireSignature from '../switch_require_signature/SwitchRequireSignature';

const isChosen = product => product.chosen;

function FormRateChoice(props) {
  const {
    ids, size, products, submit, shipmentID, setShipmentNote, shipmentNote,
  } = props;
  return (
    <React.Fragment>
      <TableProductsToShip ids={ids} size={size} products={products} />
      <div className="popup-shipping__button-row">
        <SwitchRequireSignature />
        <ButtonReprintLabel shipmentID={shipmentID} />
        <Button
          size="huge"
          color="purple"
          className="popup-shipping__button"
          onMouseUp={submit}
          disabled={!products.some(isChosen)}
        >
          GET RATES
        </Button>
      </div>
      <InputNotes
        submit={setShipmentNote}
        value={shipmentNote}
      />
    </React.Fragment>
  );
}


FormRateChoice.propTypes = {
  ids: PropTypes.shape({
    orderID: PropTypes.string,
    orderItemID: PropTypes.string,
    marketplaceID: PropTypes.number,
  }),
  size: PropTypes.objectOf(PropTypes.number),
  products: PropTypes.arrayOf(PropTypes.object),
  submit: PropTypes.func,
  shipmentID: PropTypes.string,
  setShipmentNote: PropTypes.func,
  shipmentNote: PropTypes.string,
};

FormRateChoice.defaultProps = {
  ids: {},
  size: {},
  products: [],
  submit() {},
  shipmentID: '',
  setShipmentNote() {},
  shipmentNote: '',
};


export default FormRateChoice;