import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import '../../../../../styles/Panel.css';
import '../PopupShipping.css';
import './FormConfirmShipment.css';


class FormConfirmShipment extends React.Component {
  render() {
    const { confirm, cancel, children } = this.props;
    return (
      <div className="form-confirm-shipment">
        <div className="form-confirm-shipment__message">
          <div className="form-confirm-shipment__icon-area">
            <Icon name="exclamation triangle" size="big" />
          </div>
          <div className="form-confirm-shipment__text">
            {children}
          </div>
        </div>
        <div className="form-confirm-shipment__button-line">
          <div className="form-confirm-shipment__button-container">
            <Button
              onMouseUp={cancel}
              color="red"
              className="popup-shipping__button form-confirm-shipment__button"
            >
              <Icon name="times" />
              NO
            </Button>
            <Button
              onMouseUp={confirm}
              color="green"
              className="popup-shipping__button form-confirm-shipment__button"
            >
              <Icon name="check" />
              YES
            </Button>
          </div>
        </div>
      </div>
    );
  }
  componentWillUnmount() {
    this.props.cancel();
  }
}

FormConfirmShipment.propTypes = {
  children: PropTypes.node,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
};

FormConfirmShipment.defaultProps = {
  children: '',
  confirm: () => {},
  cancel: () => {},
};

export default FormConfirmShipment;

