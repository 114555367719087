import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { connect } from 'react-redux';

import '../../../styles/Panel.css';
import '../../../styles/Grid.css';
import '../../page_orders/grid_order/GridOrder.css';
import {
  PanelOrderInfo, PanelCustomerInfo, PanelRefunds,
  PanelItemInfo, PanelBuyInfo, TextArea,
  GridOrderLayout,
} from '../../page_orders';
import { makeOrderHash } from '../../../services/helpers';
import { PanelSize, PanelTrackOrder, PanelShippingControls } from '../';
import {
  changeProductsBulkWithoutStore,
  updateLocalOrderShipping,
} from '../../../redux/actions';

function mapStateToProps(state) {
  return {
    statusOptions: state.meta.orderStatuses,
  };
}

function mapDispatchToProps(dispatch) {
  const makeUpdate = (ids, loaderID) => () => dispatch(updateLocalOrderShipping(ids, loaderID));
  return {
    makeIsFoodChangeHandler: (order, loaderID) => (isfood) => {
      dispatch(changeProductsBulkWithoutStore(
        { upc: order.buyInfo.itemIDs.upc },
        { marketplace: { isfood } },
        makeUpdate(order.unique, loaderID),
        loaderID,
      ));
    },
  };
}

const isPanelSizeDisabled = ({ qty, orderCount }) => orderCount > 0 || qty !== 1;

class GridShipping extends React.PureComponent{
  constructor(props, context) {
    super(props, context);
    this.hash = makeOrderHash(props.order.unique);
    this.notesLoaderID = `${this.hash}.notes`;
    this.foodLoaderID = `${this.hash}.food`;
  }
  handleChangeStatus = (event, { value }) => {
    const { setOrderField, order: { unique } } = this.props;
    setOrderField(unique, { status: value });
  };
  submitNotes = (notes) => {
    const { setOrderField, order: { unique } } = this.props;
    setOrderField(unique, { notes }, this.notesLoaderID);
  };
  render() {
    const {
      order, statusOptions, setOrderField, makeIsFoodChangeHandler,
    } = this.props;
    return (
      <GridOrderLayout
        status={order.status}
        orderPanel={
          <PanelOrderInfo
            orderInfo={order.orderInfo}
            distrInfo={order.distrInfo}
          />
        }
        customerPanel={
          <PanelCustomerInfo
            customerInfo={order.customerInfo}
            ids={order.unique}
            setOrderField={setOrderField}
          />
        }
        itemPanel={
          <PanelItemInfo
            itemInfo={order.itemInfo}
            ids={order.unique}
            setOrderField={setOrderField}
          />
        }
        pricePanel={
          <PanelBuyInfo
            buyInfo={order.buyInfo}
            distrInfo={order.distrInfo}
            ids={order.unique}
            marketUOM={order.itemInfo.uom}
            setOrderField={setOrderField}
          />
        }
        distrPanel={
          <PanelSize
            shipInfo={order.shipInfo}
            ids={order.unique}
            disabled={isPanelSizeDisabled(order.buyInfo)}
            orderCount={order.buyInfo.orderCount}
            setOrderField={setOrderField}
            setIsFood={makeIsFoodChangeHandler(order, this.foodLoaderID)}
            isFoodLoaderID={this.foodLoaderID}
          />
        }
        notesPanel={
          <TextArea
            className="panel__input"
            classNameOnEdit="panel__input_edit"
            placeholder="Notes..."
            value={order.notes}
            rows="2"
            submit={this.submitNotes}
            Input={TextArea}
            loaderID={this.notesLoaderID}
          />
        }
        trackingPanel={
          <PanelTrackOrder
            trackingNumber={order.trackingNumber || ''}
            chosenShippingCarrier={order.shippingCarrier}
            ids={order.unique}
            shipmentID={order.shipInfo.shipmentID}
            setOrderField={setOrderField}
          />
        }
        refundsPanel={
          <PanelShippingControls
            ids={order.unique}
            shipInfo={order.shipInfo}
            orderCount={order.buyInfo.orderCount}
          />
        }
        statusPanel={
          <Dropdown
            className="panel__dropdown grid-orders__status-dropdown"
            options={statusOptions}
            icon="chevron down"
            value={order.status}
            onChange={this.handleChangeStatus}
          />
        }
      />
    );
  }
}

GridShipping.propTypes = {
  order: PropTypes.shape({
    unique: PropTypes.object,
    orderInfo: PropTypes.object,
    customerInfo: PropTypes.object,
    itemInfo: PropTypes.object,
    buyInfo: PropTypes.object,
    distrInfo: PropTypes.object, // shortened distributor to distr to avoid typos
    refund: PropTypes.object,
    status: PropTypes.number,
    trackingNumber: PropTypes.string,
    notes: PropTypes.string,
    shippingCarrier: PropTypes.string,
  }),
  statusOptions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.any,
  })),
  setOrderField: PropTypes.func,
  makeIsFoodChangeHandler: PropTypes.func,
};

GridShipping.defaultProps = {
  order: {
    unique: null,
    orderInfo: null,
    customerInfo: null,
    itemInfo: null,
    buyInfo: null,
    distrInfo: null,
    refund: null,
    status: 0,
    trackingNumber: '',
    shippingCarrier: null,
    notes: '',
  },
  notesLoaderID: '',
  statusOptions: [],
  setOrderField: () => null,
  makeIsFoodChangeHandler: upc => isFood => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(GridShipping);

