import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { connect } from 'react-redux';

import { reprintLabel } from '../../../../../redux/actions';
import './ButtonReprintLabel.css';

const mapDispatchToProps = dispatch => ({
  printLabel: shipmentID => dispatch(reprintLabel(shipmentID)),
});

class ButtonReprintLabel extends Component {
  handleClick = () => {
    const { printLabel, shipmentID } = this.props;
    printLabel(shipmentID);
  };
  render() {
    return (
      <Button
        size="huge"
        color="green"
        className={this.props.className}
        disabled={!this.props.shipmentID}
        onMouseUp={this.handleClick}
      >
        REPRINT LABEL
      </Button>
    );
  }
}

ButtonReprintLabel.propTypes = {
  printLabel: PropTypes.func,
  shipmentID: PropTypes.string,
  className: PropTypes.string,
};
ButtonReprintLabel.defaultProps = {
  printLabel: ()=>{},
  shipmentID: '',
  className: 'button-reprint-label',
};

export default connect(null, mapDispatchToProps)(ButtonReprintLabel);
