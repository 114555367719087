import { connect } from 'react-redux';

import { InputSearch } from '../../common';
import { changeInputValue } from '../../../redux/actions';


const mapDispatchToProps = (dispatch, { inputKey }) => {
  return {
    submit: (event) => {
      dispatch(changeInputValue(inputKey, event));
    },
  };
};

const mapStateToProps = (state, props) => ({
  value: state.search[props.inputKey],
});

export default connect(mapStateToProps, mapDispatchToProps)(InputSearch)