import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Input as InputWithoutLoader, Dropdown as DropdownWithoutLoader } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import InputDate from '../../common/input_date/InputDate';

import WithLoader from '../../../containers/WithLoader';
import WithLoaderAndSubmit from '../../../containers/WithLoaderAndSubmit';
import { getNewYorkDate, makeOrderHash, makeOrderSubmitMaker } from '../../../services/helpers';

import '../../../styles/Panel.css';
import '../../../styles/Card.css';
import './PanelRefunds.css';
import { changeOrder } from '../../../redux/actions';
import { makeValidatorDecimalWithRegEx } from '../../../services/validators';

const refundValidator = makeValidatorDecimalWithRegEx(7, 2);

const possibleRefundReasons = [
  { text: 'No refund', value: '' },
  { text: 'Full refund', value: 'FULL REFUND' },
  { text: 'Partial refund', value: 'PARTIAL REFUND' },
  { text: 'Returned', value: 'RETURNED' },
  { text: 'Lost in transit', value: 'LOST IN TRANSIT' },
  { text: 'Damaged', value: 'DAMAGED' },
  { text: 'Wrong item', value: 'WRONG ITEM' },
  { text: 'Expired item', value: 'EXPIRED ITEM' },
];

const Dropdown = WithLoader(DropdownWithoutLoader);
const Input = WithLoaderAndSubmit(InputWithoutLoader);

class PanelRefunds extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.hash = makeOrderHash(props.ids);
    const getFieldHash = field => `${this.hash}/refund/${field}`;
    this.loaderIDs = {
      refundAmount: getFieldHash('refundAmount'),
      refundDate: getFieldHash('refundDate'),
      refundReason: getFieldHash('reason'),
    };
  }
  makeSubmitter = field => (value) => {
    const { ids, setOrderField } = this.props;
    setOrderField(ids, { [field]: value }, this.loaderIDs[field]);
  };
  handleChangeReason = (event, { value }) => {
    const { ids, setOrderField } = this.props;
    setOrderField(ids, { refundReason: value }, this.loaderIDs.refundReason);
  };
  render() {
    const { reason, amount, date } = this.props.refund;
    return (
      <div className="panel panel_small panel_short panel-refunds">
        <div className="panel__container panel-refunds__container_amount">
          <Input
            className="panel__input"
            classNameOnEdit="panel__input_edit"
            classNameOnError="panel__input_error"
            placeholder="Amount"
            value={amount || ''}
            submit={this.makeSubmitter('refundAmount')}
            loaderID={this.loaderIDs.amount}
            validate={refundValidator}
          />
        </div>
        <div className="panel__container panel-refunds__container_date">
          <InputDate
            className="panel__input"
            placeholder="Date"
            date={(date) ? date.slice(0, 10) : ''}
            handleChange={this.makeSubmitter('refundDate')}
            loaderID={this.loaderIDs.refundDate}
            default={getNewYorkDate()}
          />
        </div>
        <div className="panel__container panel-refunds__container_refund">
          <Dropdown
            className="panel__dropdown"
            options={possibleRefundReasons}
            placeholder="..."
            value={reason}
            icon="chevron down"
            onChange={this.handleChangeReason}
            loaderID={this.loaderIDs.refundReason}
          />
        </div>
      </div>
    );
  }
}


const possibleRefundReasonValues = possibleRefundReasons.map(reason => reason.value);

PanelRefunds.propTypes = {
  refund: PropTypes.shape({
    reason: PropTypes.oneOf(possibleRefundReasonValues),
    amount: PropTypes.number,
    date: PropTypes.string,
  }),
  ids: PropTypes.shape({
    orderID: PropTypes.string,
    orderItemID: PropTypes.string,
    marketplaceID: PropTypes.number,
  }),
  setOrderField: PropTypes.func,
};
PanelRefunds.defaultProps = {
  refund: {
    reason: '',
    amount: 0,
    date: '',
  },
  ids: {},
  setOrderField: () => {},
};

export default PanelRefunds;