import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import './HeaderProducts.css';

const SELECTION_INTERVAL = 4000;

const options = [
  { value: -1, text: 'SELECT' },
  { value: 0, text: 'NO' },
  { value: 1, text: 'YES' },
];

export const makeSelectorRenderer = (makeSubmitter, rowIdFieldName) =>
  ({ value, columnProps, original }) => (
  <YesNoDropdown
    value={value}
    submit={makeSubmitter(original[rowIdFieldName], columnProps.rest.accessor)}
  />
);

class YesNoDropdown extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      select: false,
    };
  }
  handleClickYes = () => {
    this.props.submit(1);
    this.setState({ select: false });
  };
  handleClickNo = () => {
    this.props.submit(0);
    this.setState({ select: false });
  };
  handleClickSelect = () => {
    this.setState({ select: true });
    setTimeout(() => this.setState({ select: false }), SELECTION_INTERVAL);
  };
  render() {
    return (
      <div className="header-products__selector">
        {
          (this.state.select)
            ? <div className="selector__container">
              <div className="selector__container">
                <button className="selector__button yes" onClick={this.handleClickYes}>
                  yes
                </button>
              </div>
              <div className="selector__container">
                <button className="selector__button no" onClick={this.handleClickNo}>
                  no
                </button>
              </div>
            </div>
            : <button className="selector__button" onClick={this.handleClickSelect}>
              select
            </button>
        }
      </div>
    );
  }
}

YesNoDropdown.propTypes = {
  submit: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
};

YesNoDropdown.defaultProps = {
  submit: value => null,
  value: -1,
};

export default YesNoDropdown;