import { put, call, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { updateProductListSuccess, FINISH_REQUEST, START_REQUEST, getProductsFailure } from '../../actions';
import { requestMaker, WITHHOLD_SEARCH_TIME } from '../../../services/settings';

const getParams = (state) => {
  const clone = {};
  Object.keys(state.search).forEach((v) => {
    if (state.search[v]) {
      clone[v] = state.search[v];
    }
  });
  clone.offset = state.productList.offset;
  clone.limit = state.productList.limit;
  return clone;
};

export default function* getProductsSaga(action) {
  const params = yield select(getParams);
  const token = yield select(state => state.user.token);
  if (params.distributor <= 0) {
    delete params.distributor;
  }
  if (params.marketplace <= 0) {
    delete params.marketplace;
  }
  if (
    (params.id && params.id.length >= 8) || (!params.id
      && (params.info || params.brand))
  ) {
    try {
      yield call(delay, WITHHOLD_SEARCH_TIME);
      yield put({ type: START_REQUEST });
      const response = yield call(requestMaker, {
        headers: { Authorization: `JWT ${token}` },
        method: 'get',
        url: '/api/v1/products/',
        params,
      });
      yield put(updateProductListSuccess(response.data.list, response.data.count));
    } catch (error) {
      yield put(getProductsFailure(error));
    } finally {
      yield put({ type: FINISH_REQUEST });
    }
  }
  return null;
}
