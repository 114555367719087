import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import './DropdownPackagesSelection.css';

const option = (value, text) => ({ key: value, value, text });

const parcelOptions = [
  option('RegionalRateBoxA', 'Regional Rate Box A'),
  option('RegionalRateBoxB', 'Regional Rate Box B'),
  option('FlatRateEnvelope', 'Flat Rate Envelop'),
  option('FlatRateLegalEnvelope', 'Flat Rate Legal Envelope'),
  option('FlatRatePaddedEnvelope', 'Flat Rate Padded Envelope'),
  option('SmallFlatRateBox', 'Small Flat Rate Box'),
  option('MediumFlatRateBox', 'Medium Flat Rate Box'),
  option('LargeFlatRateBox', 'Large Flat Rate Box'),
  option('LargeFlatRateBoardGameBox', 'Large Flat Rate Board Game Box'),
];


class DropdownPackagesSelection extends React.PureComponent {
  render() {
    const { value, onChange } = this.props;
    return (
      <Dropdown
        selection
        multiple
        fluid
        placeholder="Select Predefined Packages to get Flat Rates"
        className="dropdown-packages-selection"
        options={parcelOptions}
        onChange={onChange}
        value={value}
      />
    );
  }
}

DropdownPackagesSelection.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};
DropdownPackagesSelection.defaultProps = {
  value: [],
};

export default DropdownPackagesSelection;
