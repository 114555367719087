import React from 'react';

import { YesNoSwitch } from './yes_no_switch/YesNoSwitch';
import { InputDisguised as InputHidden } from './input_disguised/InputDisguised';
import {
  makeValidatorDecimalWithRegEx as makeValidatorDecimal,
  validateIntegerWithRegEx as validateInteger,
} from '../../services/validators';

export const makeSwitchRenderer = (makeSubmitter, rowIdFieldName) =>
  ({ value, columnProps, original }) => (
    <YesNoSwitch
      value={value}
      submit={makeSubmitter(original[rowIdFieldName], columnProps.rest.accessor)}
    />
  );

export const textFieldPreset = {
  type: 'text',
};

export const decimalFieldPreset = {
  validator: makeValidatorDecimal(6, 2),
  formatter: value => (+value).toFixed(2),
  type: 'number',
};

export const integerFieldPreset = {
  validator: validateInteger,
  type: 'number',
};

const bulkEditPlaceholder = 'EDIT';
export const bulkEditPresets = {
  integerFieldPreset: { ...integerFieldPreset, placeholder: bulkEditPlaceholder},
  decimalFieldPreset: { ...decimalFieldPreset, placeholder: bulkEditPlaceholder},
  textFieldPreset: { ...textFieldPreset, placeholder: bulkEditPlaceholder},
};

export const makeHiddenInputRenderer = (makeSubmitter, { validator, formatter, type, placeholder }, rowIdFieldName) =>
  ({ value, columnProps, original }) => (
    <InputHidden
      allowEmpty
      width={10}
      validate={validator}
      value={value}
      type={type}
      submit={makeSubmitter(original[rowIdFieldName], columnProps.rest.accessor)}
      formatInput={formatter}
      placeholder={placeholder}
    />
  );

export function renderImage({ value }) {
  const imgStyle = {
    backgroundImage: `url(${value})`,
  };
  return <div className="table__image" style={imgStyle} />
}

export const makeColumnSetuper = (className, headerClassName) => settings => ({
  Header: settings.Header || settings.accessor,
  className: settings.className || className,
  headerClassName,
  getProps: () => ({ accessor: settings.accessor }),
  ...settings,
});
