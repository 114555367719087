import { put, call, select } from 'redux-saga/effects';

import { persistentStateSelector } from '../../../services/selectors';
import { localStorageKey } from '../../../services/settings';
import { LOAD_STATE, LOAD_STATE_SUCCESS } from '../../actions';

const initActionTypes = [LOAD_STATE, LOAD_STATE_SUCCESS];

function isInitAction(actionType) {
  return initActionTypes.some(initActionType => initActionType === actionType);
}

export default function* saveStateSaga({ type }) {
  if (!isInitAction(type)) {
    const persistentState = yield select(persistentStateSelector);
    yield call([localStorage, 'setItem'], localStorageKey, persistentState);
  }
};

