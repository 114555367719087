import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import 'semantic-ui-css/semantic.min.css';

import Row from './RowTableDistrChoice';
import './TableDistrChoice.css';
import '../../../styles/Panel.css';
import {
  chooseDistributor, removeDistrChoice,
} from '../../../redux/actions';
import { makeOrderHash } from '../../../services/helpers';


const mapStateToProps = state => ({
  buffer: state.orderDistrBuffer,
});

const mapDispatchToProps = dispatch => ({
  makeChoiceHandler: (hash, chosen) => (event, { value }) => {
    if (chosen) dispatch(removeDistrChoice(hash));
    else dispatch(chooseDistributor(hash, value));
  },
});

const isChosen = (buffer, id, hash) => buffer[hash] === id;

const isCurrentDistr = (choice, id) => choice === id;

class TableDistrChoice extends Component {
  constructor(props, context) {
    super(props, context);
    this.hash = makeOrderHash(props.ids);
  }
  renderRow = distr => {
    const chosen = isChosen(this.props.buffer, distr.id, this.hash);
    return (
      <Row
        key={`${this.hash}.distributorChoiceTableRow.${distr.id}`}
        distr={distr}
        chosen={chosen}
        current={isCurrentDistr(this.props.distrInfo.choice, distr.id)}
        handleChoice={this.props.makeChoiceHandler(this.hash, chosen)}
      />
    );
  };
  render() {
    return (
      <div className="table-distr-choice panel__table table-distr-choice_cramped">
        {this.props.distrInfo.list.map(this.renderRow)}
      </div>
    );
  }
}

TableDistrChoice.propTypes = {
  distrInfo: PropTypes.shape({
    choice: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    list: PropTypes.arrayOf(PropTypes.object),
  }),
  ids: PropTypes.shape({
    orderID: PropTypes.string,
    orderItemID: PropTypes.string,
    marketplaceID: PropTypes.number,
  }),
  makeChoiceHandler: PropTypes.func,
  makeMarketSubmitter: PropTypes.func,
  makeMarketUnpublishSubmitter: PropTypes.func,
  makeAvailProdsSubmitter: PropTypes.func,
  buffer: PropTypes.objectOf(PropTypes.number),
  availableProducts: PropTypes.arrayOf(PropTypes.object),
  marketplaces: PropTypes.arrayOf(PropTypes.object),
  handlePopupOpen: PropTypes.func,
  loading: PropTypes.bool,
};
TableDistrChoice.defaultProps = {
  distrInfo: { choice: 0, list: [] },
  ids: {
    orderID: '',
    orderItemID: '',
    marketplaceID: 0,
  },
  makeChoiceHandler: hash => choice => null,
  makeMarketSubmitter: (marketplaceID, field) => value => null,
  makeMarketUnpublishSubmitter: (marketplaceID, field) => value => null,
  makeAvailProdsSubmitter: (marketplaceID, field) => value => null,
  handlePopupOpen: () => null,
  buffer: {},
  availableProducts: [],
  marketplaces: [],
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableDistrChoice);
