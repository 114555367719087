import { connect } from 'react-redux';
import Pagination from '../../layout/pagination/Pagination';
import { changeLimitShipping, changePageShipping } from '../../../redux/actions';

const mapStateToProps = ({ ordersShipping }) => ({
  count: ordersShipping.count,
  offset: ordersShipping.offset,
  limit: ordersShipping.limit,
  page: ordersShipping.page,
});

const mapDispatchToProps = dispatch => ({
  handlePageChange: activePage => dispatch(changePageShipping(activePage)),
  handleLimitChange: (event, { value }) => dispatch(changeLimitShipping(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);