import ReactTable from 'react-table';
import 'react-table/react-table.css';

import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/Table.css';
import {
  decimalFieldPreset, integerFieldPreset, makeHiddenInputRenderer, makeSwitchRenderer, renderImage,
  textFieldPreset, makeColumnSetuper,
} from '../../common/tableCellRenders';
import { YesNoSwitch } from '../../common';

const setupColumn = makeColumnSetuper('table__column', 'table__head table__head_marketplaces table__column');

export const makeIsFoodSwitchRenderer = (makeSubmitter) =>
  ({ value, columnProps, original }) => (
    <YesNoSwitch
      value={value}
      submit={makeSubmitter()}
    />
  );

function TableMarkets({ marketplaces, makeSubmitter, makeUnpublishSubmitter, makeIsFoodSubmitter }) {
  const switchWithSubmit = makeSwitchRenderer(makeSubmitter, 'marketplaceid');
  const switchIsFood = makeSwitchRenderer(makeIsFoodSubmitter);
  const switchUnpublish = makeSwitchRenderer(makeUnpublishSubmitter, 'marketplaceid');
  const inputWithSubmit = preset =>
    makeHiddenInputRenderer(makeSubmitter, preset, 'marketplaceid');
  const columnSettings = [
    {
      accessor: 'imageurl', minWidth: 131, Header: 'marketplace', Cell: renderImage,
      className: 'table__column table__column_image',
    },
    { accessor: 'isfood', minWidth: 89, Header: 'food', Cell: switchIsFood },
    { accessor: 'notes', minWidth: 340, Header: 'item notes', Cell: inputWithSubmit(textFieldPreset)},
    { accessor: 'weight', minWidth: 39, Cell: inputWithSubmit(decimalFieldPreset) },
    { Header: 'not save', accessor: 'denyWeightUpdate', minWidth: 49, Cell: switchWithSubmit },
    { accessor: 'map', minWidth: 63, Cell: inputWithSubmit(integerFieldPreset) },
    { accessor: 'uom', minWidth: 34, Cell: inputWithSubmit(integerFieldPreset) },
    { accessor: 'unpublish', minWidth: 58, Cell: switchUnpublish },
  ];
  const columns = columnSettings.map(setupColumn);
  return (
    <ReactTable
      noDataText="There are no products"
      data={marketplaces}
      columns={columns}
      className="table table-products__table"
      showPaginationBottom={false}
      loadingText=""
      resizable={false}
      minRows={1}
    />
  );
}

export default TableMarkets;
