import React from 'react';
import PropTypes from 'prop-types';
import { CardLabeled as CardLabeledBasic } from '../../common';
import './PanelSize.css';

export default function CardLabeled({ disabled, old, children, contentClassName, ...props }) {
  const oldValueClassName = old ? 'panel-size__card-labeled_old' : '';
  return (
    <CardLabeledBasic
      strange={disabled}
      strangeClassName="panel-size__card-labeled_disabled"
      labelStrangeClassName="panel-size__card-labeled_label_disabled"
      contentClassName={`${contentClassName} ${oldValueClassName}`}
      {...props}
    >
      {!disabled ? children : null}
    </CardLabeledBasic>
  );
}


CardLabeled.propTypes = {
  old: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.element,
  ]),
};

CardLabeled.defaultProps = {
  contentClassName: '',
  old: true,
  children: null,
  disabled: false,
  label: '',
};
