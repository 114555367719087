import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import { DropDown as Dropdown } from '../../common/dropdown/DropDown';
import './HeaderDistr.css';
import { changeOrderSearch, downloadCSV, sendToArchive } from '../../../redux/actions';
import { ButtonWithIcon } from '../../common/';
import { PopupOrderBulkEditContainer, ButtonBulkEdit } from '../../page_orders';

const mapStateToProps = state => ({
  distrOptions: state.meta.distributors,
  distrFilter: state.orderSearch.distributor,
  count: state.orders.count,
});

const mapDispatchToProps = dispatch => ({
  changeSearch: changes => dispatch(changeOrderSearch(changes)),
  handleChangeDistr:  (event, { value }) =>
    dispatch(changeOrderSearch({ distributor: value })),
  handleClickDownCSV: () => dispatch(downloadCSV()),
  handleClickSendToArchive: () => dispatch(sendToArchive()),
});

const dropdownStyle = {
  dropdown: 'header-distr__dropdown',
  label: 'header-distr__dropdown-label',
};

function HeaderDistr(props) {
  return (
    <div className="header-distr">
      <div className="header-distr__container header-distr__counter">
        {props.count}
      </div>
      <div className="header-distr__container">
        <Dropdown
          styles={dropdownStyle}
          options={props.distrOptions}
          value={props.distrFilter}
          handleChange={props.handleChangeDistr}
          placeholder="All"
          label="Distributor"
        />
      </div>
      <div className="header-distr__container header-distr__container_space" />
      <div className="header-distr__container header_distr__container_bulk">
        <PopupOrderBulkEditContainer changeSearch={props.changeSearch}>
          <ButtonBulkEdit/>
        </PopupOrderBulkEditContainer>
      </div>
      <div className="header-distr__container header-distr__container_button">
        <Button
          className="header-distr__button header-distr__button_blue"
          onClick={props.handleClickSendToArchive}
        >
          Send to ARCHIVE
        </Button>
      </div>
      <div className="header-distr__container header-distr__container_button">
        <ButtonWithIcon
          className="header-distr__button header-distr__button_green"
          title="Download CSV"
          icon="download"
          iconRight
          onClick={props.handleClickDownCSV}
        />
      </div>
    </div>
  );
}


HeaderDistr.propTypes = {
  changeSearch: PropTypes.func,
  count: PropTypes.number,
  distrOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    text: PropTypes.string,
  })),
  distrFilter: PropTypes.number,
  handleChangeDistr: PropTypes.func,
  handleClickDownCSV: PropTypes.func,
  handleClickSendToArchive: PropTypes.func,
};

HeaderDistr.defaultProps = {
  changeSearch: () => {},
  count: 0,
  distrOptions: [],
  distrFilter: 0,
  handleChangeDistr: () => null,
  handleClickDownCSV: () => null,
  handleClickSendToArchive: () => null,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDistr);
