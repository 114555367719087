import { put, call, select } from 'redux-saga/effects';

import { requestMaker } from '../../../services/settings';
import {
  changeOrderWithShippingInfoSuccess,
  changeOrderWithShippingInfoFailure,
} from '../../actions';

const selectToken = state => state.user.token;

export default function* updateLocalOrderShipping({ ids, loaderId }) {
  const token = yield select(selectToken);
  try {
    const getResponse = yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'get',
      params: { ...ids, marketplaceID: ids.marketplace },
      url: '/api/v1/order-shipping/',
    });
    yield put(changeOrderWithShippingInfoSuccess(ids, getResponse.data.list[0], loaderId));
  } catch (error) {
    yield put(changeOrderWithShippingInfoFailure(error, loaderId));
  }
};
