import {
  GET_ORDERS, GET_ORDERS_SUCCESS, GET_ORDERS_FAILURE, CHANGE_ORDER_SEARCH,
  CHANGE_ORDER_SUCCESS, CHANGE_PAGE_ORDERS, CHANGE_LIMIT_ORDERS,
} from '../actions';
import { getOffset } from '../getOffset';
import makeReducer from '../makeReducer';


const initialOrders = {
  list: [],
  loading: false,
  offset: 0,
  count: 0,
  limit: 50,
};

const getIndexByIDs = (ids, list) => list.findIndex(order =>
  order.unique.orderID === ids.orderID
  && order.unique.orderItemID === ids.orderItemID
  && order.unique.marketplaceID === ids.marketplaceID,
);

const replaceOrRemove = (list, ids, order) => {
  const index = getIndexByIDs(ids, list);
  if (index < 0) return list;
  const changedList = [...list];
  if (!order) changedList.splice(index, 1);
  else changedList[index] = order;
  return changedList;
};

export const activateLoading = state => ({
  ...state, loading: true,
});
export const setFirstPageLoading = state => ({
  ...state, loading: true, offset: 0, page: 1,
});
export const setLimit = (state, { limit }) => ({
  ...state, limit,
});
export const changePage = (state, action) => ({
  ...state, offset: getOffset(state, action), page: action.page,
});
export const saveList = (state, { list, count }) => ({
  ...state, list, count, loading: false,
});
export const makeClearList = ({ list, count }) => state => ({
  ...state, list, count, loading: false,
});
export const handleOrderChange = ({ list, count, ...state }, { ids, order }) =>({
  ...state, list: replaceOrRemove(list, ids, order), count: order ? count : count - 1,
});

const actionHandlers = {
  [GET_ORDERS]: activateLoading,
  [CHANGE_ORDER_SEARCH]: setFirstPageLoading,
  [CHANGE_LIMIT_ORDERS]: setLimit,
  [CHANGE_PAGE_ORDERS]: changePage,
  [GET_ORDERS_SUCCESS]: saveList,
  [GET_ORDERS_FAILURE]: makeClearList(initialOrders),
  [CHANGE_ORDER_SUCCESS]: handleOrderChange,
};

export default makeReducer(initialOrders, actionHandlers);