import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/Card.css';
import './CardWhite.css';

export default function CardWhite({ children, className }) {
  return (
    <div className={`card card-white ${className}`}>
      {children}
    </div>
  );
}


CardWhite.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

CardWhite.defaultProps = {
  children: '',
  className: '',
};
