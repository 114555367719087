import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import './HeaderInventory.css';
import { ButtonWithIcon } from '../../common';
import FormInsertProduct from '../form_insert_product/FormInsertProduct';

const mapStateToProps = state => ({
  count: state.inventory.count,
});

const mapDispatchToProps = dispatch => ({
});

const dropdownStyle = {
  dropdown: 'header-inventory__dropdown',
  label: 'header-inventory__dropdown-label',
};

function HeaderDistr(props) {
  return (
    <div className="header-inventory">
      <div className="header-inventory__container header-inventory__container_button">
        <Popup
          trigger = {
            <ButtonWithIcon
              className="header-inventory__button"
              title="Insert Product"
              icon="plus"
            />
          }
          position="bottom right"
          on={['click']}
        >
          <FormInsertProduct />
        </Popup>
      </div>
      <div className="header-inventory__container header-inventory__container_space"/>
      <div className="header-inventory__container header-inventory__counter">
        {props.count}
      </div>
    </div>
  );
}


HeaderDistr.propTypes = {
  count: PropTypes.number,
  distrOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    text: PropTypes.string,
  })),
  distrFilter: PropTypes.number,
  handleChangeDistr: PropTypes.func,
  handleClickDownCSV: PropTypes.func,
  handleClickSendToArchive: PropTypes.func,
};

HeaderDistr.defaultProps = {
  count: 0,
  distrOptions: [],
  distrFilter: 0,
  handleChangeDistr: () => null,
  handleClickDownCSV: () => null,
  handleClickSendToArchive: () => null,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDistr);
