import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { TableOrdersDistr, withPermissionRedirection } from '../../containers';
import { getMetadata, changeOrderSearch, resetOrderSearch, changeOrder } from '../../redux/actions';

function mapStateToProps(state) {
  return {
    products: state.orders.list,
    loading: state.orders.loading,
    count: state.orders.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initialize: () => {
      dispatch(getMetadata());
      dispatch(changeOrderSearch({ distrPage: true }));
    },
    changeSearch: change =>
      dispatch(changeOrderSearch({ ...change, distrPage: true })),
    setOrderField: (ids, changes, loaderId) =>
      dispatch(changeOrder(ids, changes, loaderId)),
    cleanUp: () => {
      dispatch(resetOrderSearch());
    },
  };
}

class PageOrders extends React.PureComponent {
  static propTypes = {
    initialize: PropTypes.func,
    products: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    cleanUp: PropTypes.func,
    count: PropTypes.number,
  };
  static defaultProps = {
    initialize: () => {},
    products: [],
    loading: false,
    cleanUp: () => null,
    count: 0,
  };
  componentDidMount() {
    this.props.initialize();
  }
  componentWillUnmount() {
    this.props.cleanUp();
  }
  render() {
    return (
      <TableOrdersDistr {...this.props} />
    );
  }
}

export default withPermissionRedirection(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageOrders));