import {
  CHANGE_DROPDOWN_VALUE,
  CHANGE_INPUT_VALUE,
  CHANGE_LIMIT_PRODUCTS,
  CHANGE_PAGE_PRODUCTS,
  CHANGE_PRODUCTS_BULK,
  CHANGE_PRODUCTS_BULK_FAILURE,
  FINISH_REQUEST,
  SAVE_METADATA,
  START_REQUEST,
  UPDATE_FILED_IN_ARRAY,
  UPDATE_PRODUCT_LIST_FAILURE,
  UPDATE_PRODUCT_LIST_SUCCESS,
  UPDATE_LOCAL_PRODUCT_SUCCESS,
} from '../actions';
import { getOffset, getPage } from '../getOffset';


const initialState = {
  loading: true,
  products: [],
  offset: 0,
  limit: 50,
  count: 0,
  page: 1,
  distributors: [],
  marketplaces: [],
};

const withReplacedProduct = product => entry => entry.sku === product.sku ? product : entry;

export default function productListReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_INPUT_VALUE:
    case CHANGE_DROPDOWN_VALUE:
      return { ...state, offset: 0, page: 1 };
    case UPDATE_PRODUCT_LIST_SUCCESS:
      return { ...state, products: action.productList, count: action.count };
    case UPDATE_PRODUCT_LIST_FAILURE:
      return initialState;
    case UPDATE_LOCAL_PRODUCT_SUCCESS:
      return { ...state, products: state.products.map(withReplacedProduct(action.product)) };
    case CHANGE_PRODUCTS_BULK_FAILURE:
    case FINISH_REQUEST:
      return { ...state, loading: false };
    case UPDATE_FILED_IN_ARRAY:
      return { ...state, products: [...action.products] };
    case START_REQUEST:
    case CHANGE_PRODUCTS_BULK:
      return { ...state, loading: true };
    case CHANGE_LIMIT_PRODUCTS:
      return { ...state, limit: action.limit };
    case CHANGE_PAGE_PRODUCTS:
      return {
        ...state,
        loading: true,
        offset: getOffset(state, action),
        page: action.page,
      };
    case SAVE_METADATA:
      return { ...state, distributors: action.distributors, marketplaces: action.marketplaces };
    default:
      return state;
  }
}
