import { call, put, select } from 'redux-saga/effects';
import { requestMaker } from '../../../services/settings';
import { changeProductsBulkFailure, changeProductsBulkSuccess } from '../../actions';
import { getOrDefault } from '../../../services/helpers';

const noSearchParams = ({ id, info, brand }) => !(id || info || brand);

export default function* setProductBulkSaga({ changes, filters }) {
  const token = yield select(({ user }) => user.token);
  const usedFilters = yield select(({ search }) => ({
    id: getOrDefault('id', filters, search),
    info: getOrDefault('info', filters, search),
    brand: getOrDefault('brand', filters, search),
    distributorid: getOrDefault('distributor', filters, search),
    marketplaceid: getOrDefault('marketplace', filters, search),
  }));
  Object.keys(usedFilters).forEach( (field) => {
    if (!usedFilters[field]) delete usedFilters[field];
  });
  // failsafe set to insure, what no changes will be applied if no visible search results
  if (noSearchParams(usedFilters)) {
    delete usedFilters.distributorid;
    delete usedFilters.marketplaceid;
  }
  try {
    yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'patch',
      url: '/api/v1/products/all/',
      data: { changes, filters: usedFilters },
    });
    yield put(changeProductsBulkSuccess());
  }
  catch (error) {
    yield put(changeProductsBulkFailure(error));
  }
};
