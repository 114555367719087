import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Header, Icon, List } from 'semantic-ui-react';
import { connect } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';

import './ModalReport.css';
import { showReportsModal, hideReportsModal } from '../../../redux/actions';
import { departmentNames } from '../../../services/settings';

const getSide = result => result > 0 ? 'Marketplace' : 'ERP System';

function getDescription({ description, result, marketplaceName }) {
  const marketplacePrefix = marketplaceName ? `${marketplaceName}: ` : '';
  return `${marketplacePrefix}${Math.abs(result)} ${description} ${getSide(result)}`;
}

const toListEntry = entry => ({
  key: `${entry.id}${entry.marketplaceID}`,
  icon: { name: 'times' },
  description: getDescription(entry),
});

const mapStateToProps = ({ reports, user }) => ({
  show: reports.showModal,
  lastReportTime: reports.lastReportTimeBeforeRequest,
  reportList: reports.urgent,
  admin: user.department === departmentNames.admin,
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(hideReportsModal()),
  open: () => dispatch(showReportsModal()),
});

class ModalReport extends Component {
  render() {
    const { show, open, close, admin, lastReportTime } = this.props;
    return (
      <Modal
        size="small"
        trigger={<div />}
        className="modal-report"
        onClose={close}
        open={show && admin}
      >
        <Header as="h1" color="red">
          <Icon name="times circle" />
          <Header.Content>
            Warning
          </Header.Content>
        </Header>
        <Modal.Content className="modal-report">
          <List
            items={this.props.reportList.map(toListEntry)}
            size="big"
          />
        </Modal.Content>
        <Modal.Actions>
          <div className="modal-report__container_actions">
            <span className="modal-report__last-report-date">
              Last time of report: {lastReportTime || 'No record'}
            </span>
            <Button size="big" onClick={close} positive >
              <Icon name="checkmark" /> OK
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

ModalReport.propTypes = {
  admin: PropTypes.bool,
  reportList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    description: PropTypes.string,
    result: PropTypes.number,
  })),
  show: PropTypes.bool,
  open: PropTypes.func,
  close: PropTypes.func,
  lastReportTime: PropTypes.string,
};
ModalReport.defaultProps = {
  admin: '',
  reportList: [],
  show: false,
  open: () => {},
  close: () => {},
  lastReportTime: 'No record',
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalReport);
