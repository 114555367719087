import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import GridProduct from '../grid_product/GridProduct';
import GridProductLite from '../grid_product/GridProductLite';

import TableAvailable from './TableAvailable';
import TableMarketplaces from './TableMarketplaces';
import './TableProducts.css';


export default function RowProducts({ product, productId, productNumber}) {
  if (product) {
    const { available_products, marketplaces } = product;
    return (
      <tr>
        <td className="table-products__cell">
          <div className="table-products__content">
            <Switch>
              <Route exact path="/products/full/">
                <GridProduct product={product} />
              </Route>
              <Route>
                <GridProductLite product={product} />
              </Route>
            </Switch>
            <TableAvailable
              availableProducts={available_products}
              code={product.upc}
              forReq="upc"
              idName="distributorid"
              productId={productId}
              productNumber={productNumber}
            />
            <TableMarketplaces
              marketplaces={marketplaces}
              code={product.sku}
              upc={product.upc}
              forReq="sku"
              idName="marketplaceid"
              productId={productId}
              productNumber={productNumber}
            />
          </div>
        </td>
      </tr>
    );
  }
  return null;
}


RowProducts.propTypes = {
  product: PropTypes.shape({
    upc: PropTypes.string,
    sku: PropTypes.string,
    available_products: PropTypes.arrayOf(PropTypes.object),
    marketplaces: PropTypes.arrayOf(PropTypes.object),
  }),
  productId: PropTypes.string,
  productNumber: PropTypes.number,
};

RowProducts.defaultProps = {
  product: null,
  productId: '',
  productNumber: '',
};
