import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { searchToObj } from '../../services/helpers';

class PagePrintLabel extends PureComponent {
  static getDerivedStateFromProps(props) {
    console.log(props.location.search);
    if (!this || !this.props || props.location.search !== this.props.location.search) {
      const search = searchToObj(props.location.search) || {};
      return {
        labelURL: search.labelURL,
      };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    this.state={
      labelURL: '',
    };
  }
  componentDidMount(){
    window.print();
  }
  render() {
    return (
      <img src={this.state.labelURL} alt="label" />
    );
  }
}

PagePrintLabel.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};
PagePrintLabel.defaultProps = {
  location: {},
};

export default withRouter(PagePrintLabel);
