import { put, call, select } from 'redux-saga/effects';
import { requestMaker } from '../../../services/settings';
import { changeProdInventoryFailure, changeProdInventorySuccess } from '../../actions';

export default function* changeProdInvSaga({ upc, changes, loaderId }) {
  const token = yield select(state => state.user.token);
  try {
    yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'patch',
      url: '/api/v1/inventory/',
      data: { upc, distrID: 1, changes },
    });
    const response = yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'get',
      url: '/api/v1/inventory/',
      params: { upc, distrID: 1, limit: 1 },
    });
    yield put(changeProdInventorySuccess(upc, response.data.list[0], loaderId));
  } catch (error) {
    yield put(changeProdInventoryFailure(error, loaderId));
  }
};
