import { put, call } from 'redux-saga/effects';

import { localStorageKey } from '../../../services/settings';
import { loadStateSuccess } from '../../actions';

export default function* loadStateSaga() {
  const rawdata = yield call([localStorage, 'getItem'], localStorageKey);
  try {
    const data = JSON.parse(rawdata);
    yield put(loadStateSuccess(data));
  } catch (error) {
    yield call([console, 'log'], error);
  }
};
