import React from 'react';
import { Loader } from 'semantic-ui-react';
import './TableWrapper.css';
import PropTypes from 'prop-types';

function ContentWrapper(ContentComponent, TableHeader) {
  const Content = ({ ...props }) => (
    <div className="table-wrapper">
      <div className="table-wrapper__header">
        <TableHeader {...props} />
      </div>
      <div className="table-wrapper__content">
        <ContentComponent {...props} />
      </div>
    </div>
  );
  return Content;
}

export default ContentWrapper;

