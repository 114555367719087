import React from 'react';
import PropTypes from 'prop-types';
import { v4 as getID } from 'uuid';
import { Switch, Route, withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import NavLinkBasic from './NavLinkBasic';
import '../header/Header.css';
import './NavLink.css';

const log = value => {
  console.log(value);
  return value;
};

class NavLinkMenu extends React.PureComponent{
  getOptionFullPath = option => `${this.props.href}${option.path}`;
  getMenuValue = () => {
    const { options } = this.props;
    return options.findIndex(this.optionByLocation);
  };
  optionByLocation = option => matchPath(this.props.location.pathname, {
    path: this.getOptionFullPath(option),
    isExact: false,
  });
  handleChange = (event, { value }) => {
    const { history, options, href } = this.props;
    const path = (value === this.getMenuValue())
      ? href
      : this.getOptionFullPath(options[value]);
    history.push(path);
  };
  render() {
    const {
      uniqueKey, options,
      href, title, ...props
    } = this.props;
    return (
      <Switch>
        <Route path={href}>
          <Dropdown
            icon={null}
            options={options}
            text={title}
            onChange={this.handleChange}
            className="navbar__block navbar__block_active nav-link__dropdown"
            value={this.getMenuValue()}
          />
        </Route>
        <Route>
          <NavLinkBasic
            uniqueKey={uniqueKey || getID()}
            href={href}
            title={title}
            {...props}
          />
        </Route>
      </Switch>
    );
  }
}


NavLinkMenu.propTypes = {
  uniqueKey: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    path: PropTypes.string,
  })),
  href: PropTypes.string,
  title: PropTypes.string,
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

NavLinkMenu.defaultProps = {
  uniqueKey: null,
  options: [],
  href: '/',
  title: '',
};

export default withRouter(NavLinkMenu);
