import React from 'react';
import {Loader} from 'semantic-ui-react';
import './TableWrapper.css';

function TableWrapper(TableComponent, PaginationComponent) {
  return (props) => (
    <div className="table-wrapper">
      <div className="table-wrapper__content">
        <TableComponent {...props} />
      </div>
      {props.loading && <Loader active={props.loading} /> }
      {props.count > 0 || props.loading ? null :
        <div style={{ textAlign: 'center', height: '100%' }}>There are no products</div>
      }
      {props.count > 0 && !props.loading ?
        <div className="table-wrapper__footer">
          <PaginationComponent {...props} />
        </div>
      : null}
    </div>
  );
}

export default TableWrapper;
