import React from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import { connect } from 'react-redux';

import '../../../styles/Table.css';
import {
  bulkEditPresets, makeHiddenInputRenderer, makeColumnSetuper,
} from '../../common/tableCellRenders';
import { changeProductsBulk } from '../../../redux/actions';
import { makeSelectorRenderer } from './YesNoSelector';
import { getNewYorkTime } from '../../../services/helpers';

const setupColumn = makeColumnSetuper('table__column', 'table__head table__column');
const { decimalFieldPreset, integerFieldPreset } = bulkEditPresets;

const mapDispatchToProps = dispatch => ({
  makeSubmitter: (rowID, field) => value => dispatch(changeProductsBulk({
    distributor: {
      [field]: value,
    },
  }, {
    distributor: rowID,
  })),
  makeUnpublishSubmitter: (rowID, field) => value => dispatch(changeProductsBulk({
    distributor: {
      unpublish: value,
      unpublishDate: value ? getNewYorkTime() : null,
    },
  }, {
    distributor: rowID,
  })),
});

function TableChangeFilteredDistr({ data, makeUnpublishSubmitter, makeSubmitter}) {
  const switchWithSubmit = makeSelectorRenderer(makeUnpublishSubmitter, 'distributorid');
  const inputWithSubmit = preset =>
    makeHiddenInputRenderer(makeSubmitter, preset, 'distributorid');
  const columnSettings = [
    { accessor: 'weight', minWidth: 22.25, Cell: inputWithSubmit(decimalFieldPreset)},
    { accessor: 'uom', minWidth: 16.75, Cell: inputWithSubmit(integerFieldPreset) },
    { accessor: 'unpublish', minWidth: 29.75, Cell: switchWithSubmit },
  ];
  const columns = columnSettings.map(setupColumn);
  return (
    <ReactTable
      noDataText="There are no products"
      data={data}
      columns={columns}
      className="table table-products__table"
      showPaginationBottom={false}
      loadingText=""
      resizable={false}
      minRows={1}
    />
  );
}


TableChangeFilteredDistr.propTypes = {
  makeSubmitter: PropTypes.func,
  makeUnpublishSubmitter: PropTypes.func,
  
};

TableChangeFilteredDistr.defaultProps = {
  data: [{
    weight: '',
    uom: '',
    unpublish: -1,
  }],
  makeSubmitter: (rowID, field) => value => null,
  makeUnpublishSubmitter: rowID => value => null,
};

export default connect(null, mapDispatchToProps)(TableChangeFilteredDistr)