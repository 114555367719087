import { put, call } from 'redux-saga/effects';
import makeRequest from '../authorizedRequest';
import { insertProdInventorySuccess, insertProdInventoryFailure } from '../../actions';

const defaultValues = {
  weight: 0,
};

const makeDefaultValuesFiller = (product, defaults) => (newProd, field) => ({
  ...newProd,
  [field]: product[field] || defaults[field],
});

const getWithDefaultValues = (product, defaults) => Object.keys(defaults)
  .reduce(makeDefaultValuesFiller(product, defaults), { ...product });

export default function* insertProdInvSaga({ product, loaderId }) {
  const usedProduct = getWithDefaultValues(product, defaultValues);
  yield call(makeRequest, {
    requestSettings: {
      method: 'post',
      url: '/api/v1/inventory/',
      data: { ...usedProduct, distrID: 1 },
    },
    successAction: insertProdInventorySuccess,
    failureAction: insertProdInventoryFailure,
    loaderId,
  });
}
