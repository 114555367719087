import { select, put, call } from 'redux-saga/effects';
import { requestMaker } from '../../../services/settings';
import { orderStatus, deleteEmptyFields } from '../../../services/helpers';
import { changeOrderBulkSuccess, changeOrderBulkFailure } from '../../actions';
const paramSelector = ({ orderSearch }) => {
  if (orderSearch.distrPage) {
    return {
      status: orderStatus.export,
      distr: orderSearch.distributor,
      itemInfo: orderSearch.itemInfo,
      itemID: orderSearch.itemID,
      orderID: orderSearch.orderID,
      customer: orderSearch.customer,
    };
  }
  return {
    status: orderSearch.status,
    distr: orderSearch.distributor,
    itemInfo: orderSearch.itemInfo,
    itemID: orderSearch.itemID,
    customer: orderSearch.customer,
    orderID: orderSearch.orderID,
    marketplace: orderSearch.marketplace,
    shipBy: orderSearch.shipBy,
    search: orderSearch.searchAll,
  };
};

const tokenSelector = ({ user: { token } }) => token;

const isWaitingForShipping = status => [
  orderStatus.archive,
  orderStatus.shipReady,
].includes(status);

function createShipOrdersEntries(token) {
  return requestMaker({
    headers: { Authorization: `JWT ${token}` },
    method: 'post',
    url: '/api/v1/ship-order/all/',
    data: {
      statusWhitelist: [
        orderStatus.shipReady,
        orderStatus.archive,
      ],
    },
  });
}

function cleanUpShipOrderEntries(token) {
  return requestMaker({
    headers: { Authorization: `JWT ${token}` },
    method: 'delete',
    url: '/api/v1/ship-order/all/',
    data: {
      statusWhitelist: [
        orderStatus.shipReady,
        orderStatus.archive,
      ],
    },
  });
}

function setOrderBulk(filters, changes, token) {
  return requestMaker({
    headers: { Authorization: `JWT ${token}` },
    method: 'patch',
    url: '/api/v1/order/all/',
    data: { filters, changes },
  });
}

export default function* changeOrderBulkSaga({ changes, update }) {
  const filters = yield select(paramSelector);
  const { status } = filters;
  deleteEmptyFields(filters);
  deleteEmptyFields(changes, true);
  if (!Object.keys(filters).length) filters.status = status;
  const token = yield select(tokenSelector);
  try {
    const statusChanged = changes.status != null;
    const toShipOrder = isWaitingForShipping(changes.status);
    yield call(setOrderBulk, filters, changes, token);
    if (statusChanged && toShipOrder) yield call(createShipOrdersEntries, token);
    if (statusChanged && !toShipOrder) yield call(cleanUpShipOrderEntries, token);
    yield put(changeOrderBulkSuccess());
    yield call(update);
  } catch (error) {
    yield put(changeOrderBulkFailure());
  }
};
