import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../../page_products/table_products/TableProducts.css';
import { actions } from '../../../redux/reducers/searchReducer';

class InputSearch extends React.PureComponent {
  onBlur(event) {
    // TODO: onBlur logic
  }

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.props.submit(event);
    }
  };

  render() {
    const { styles } = this.props;
    return (
      <div className={styles.inputContainer}>
        <input
          onChange={this.props.submit}
          onKeyDown={this.onKeyDown}
          value={this.props.value}
          type="text"
          placeholder={this.props.placeHolder}
          className={styles.input}
        />
      </div>
    );
  }
}

InputSearch.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  submit: PropTypes.func,
  placeHolder: PropTypes.string,
  value: PropTypes.string,
};

InputSearch.defaultProps = {
  styles: {
    inputContainer: '',
    input: '',
  },
  submit: () => {},
  placeHolder: '',
  value: null,
};

export default InputSearch;
