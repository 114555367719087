import React from 'react';
import PropTypes from 'prop-types';
import { v4 as getId } from 'uuid';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { makeSelectorProductChangeSubmit } from '../../../services/selectors'
import { updateSwitch } from '../../../redux/actions';
import './YesNoSwitch.css';

const makeMapDispatchToProps = () => {
  const makeSubmitter = makeSelectorProductChangeSubmit();
  return (dispatch, props) => ({
    submit: makeSubmitter(dispatch, props),
  });
};

const mapStateToProps = state => ({
  loaders: state.loaders,
});

export class YesNoSwitch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.id = props.loaderID || getId();
    this.handleClick = this.handleClick.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.loading && !nextProps.loaders[this.id]) {
      this.setState({ loading: false });
    }
  }
  handleClick() {
    this.props.submit(+!this.props.value, this.id);
    if (this.props.loaders) this.setState({ loading: true });
  }
  render() {
    return (
      <div className={`${this.props.className} yes-no-switch`} onClick={this.handleClick}>
        {this.props.loading || this.state.loading ? '' : ((this.props.value) ? 'Yes' : 'No')}
        <Loader size="tiny" inline active={this.props.loading || this.state.loading} />
      </div>
    );
  }
}

YesNoSwitch.propTypes = {
  submit: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  loaders: PropTypes.objectOf(PropTypes.bool),
  loading: PropTypes.bool,
  loaderID: PropTypes.string,
};

YesNoSwitch.defaultProps = {
  submit: () => {},
  className: '',
  value: false,
  loaders: null,
  loading: false,
  loaderID: '',
};

export default connect(mapStateToProps, makeMapDispatchToProps)(YesNoSwitch);

