import { call, put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { deleteEmptyFields, getOrDefault } from '../../../services/helpers';
import { getProdsInventoryFailure, getProdsInventorySuccess } from '../../actions';
import { WITHHOLD_SEARCH_TIME, requestMaker } from '../../../services/settings';
import getPaginationData from '../pagination/getPaginationData';

const makeGetter = (obj, def) => field => getOrDefault(field, obj, def);

export default function* getProdsInventorySaga({ params }) {
  yield call(delay, WITHHOLD_SEARCH_TIME);
  const usedParams = yield select(({ inventorySearch, inventory }) => {
    const get = makeGetter(params, inventorySearch);
    return {
      itemID: get('itemID'),
      itemInfo: get('itemInfo'),
      brand: get('brand'),
      ...getPaginationData(params, inventory),
    };
  });
  deleteEmptyFields(usedParams);
  const token = yield select( state => state.user.token);
  usedParams.distrID = 1;
  try {
    const response = yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'get',
      url: '/api/v1/inventory/',
      params: usedParams,
    });
    yield put(getProdsInventorySuccess(response.data.list, response.data.count));
  } catch (error) {
    yield put(getProdsInventoryFailure(error));
  }
};
