import React from 'react';
import PropTypes from 'prop-types';

import RowTableShipping from './RowTableShipping';
import HeaderTableShipping from './HeaderTableShipping';
import '../../page_orders/table_orders/TableOrders.css';
import { makeOrderHash } from '../../../services/helpers';

class TableOrders extends React.PureComponent{
  renderRowTableShipping = (order, i) => (
    <RowTableShipping
      key={makeOrderHash(order.unique)}
      order={order}
      orderNumber={i}
      setOrderField={this.props.setOrderField}
    />
  );
  render() {
    const { products, loading, changeSearch } = this.props;
    return (
      <div className="working-area__table-products">
        <table className="table-products">
          <tbody>
            <HeaderTableShipping changeSearch={changeSearch} />
            {!loading && products.map(this.renderRowTableShipping)}
          </tbody>
        </table>
      </div>
    );
  }
}


TableOrders.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  changeSearch: PropTypes.func,
  setOrderField: PropTypes.func,
};

TableOrders.defaultProps = {
  products: [],
  loading: false,
  changeSearch: null,
  setOrderField: () => {},
};

export default TableOrders;
