import React from 'react';
import PropTypes from 'prop-types';
import { v4 as getID } from 'uuid';

import '../../../styles/Card.css';
import './CardSimple.css';
import { shortenString } from '../../../services/helpers';

const shortenAllWords = (line, length) => line
  .split(' ')
  .map(word => shortenString(word, length))
  .join(' ');

function renderLine(lineText) {
  return (
    <p className="card-simple__line" key={getID()}>
      {lineText}
    </p>
  );
}

const makeShortLineRenderer = length => line => renderLine(shortenString(line, length));

function CardSimple({ data, green, wordLength, lineLength }) {
  const greenStyle = green ? 'card-simple_green' : '';
  return (
    <div className={`card card-simple ${greenStyle}`} >
      {
        (data instanceof Array)
          ? data.map(makeShortLineRenderer(lineLength))
          : shortenString(shortenAllWords(data, wordLength), lineLength)
      }
    </div>
  );
}

CardSimple.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  green: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  lineLength: PropTypes.number,
  wordLength: PropTypes.number,
};
CardSimple.defaultProps = {
  data: '',
  green: false,
  lineLength: 0,
  wordLength: 0,
};

export default CardSimple;

