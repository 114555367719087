import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';

import PanelMarketShipCount from '../panel_market_ship_count/PanelMarketShipCount';
import './GridMarketShipStats.css';

const mapStateToProps = state => ({
  marketplaceStats: state.systemStats.marketplaces,
  loading: state.systemStats.loading.marketplaces,
});

const renderPanel = marketplace => (
  <div
    className="grid-market-ship-stats__container"
    key={`${marketplace.id}${marketplace.name}MarketplaceShipmentCount`}
  >
    <PanelMarketShipCount
      marketplace={marketplace}
    />
  </div>
);

function GridMarketShipStats({ marketplaceStats, loading }) {
  const panels = marketplaceStats.map(renderPanel);
  return (
    <div className="grid-market-ship-stats">
      {panels}
    </div>
  );
}


GridMarketShipStats.propTypes = {
  marketplaceStats: PropTypes.arrayOf(PropTypes.shape({
    shipped: PropTypes.number,
    notShipped: PropTypes.number,
    total: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    imageURL: PropTypes.string,
  })),
  loading: PropTypes.bool,
};

GridMarketShipStats.defaultProps = {
  marketplaceStats: [],
  loading: false,
};

export default connect(mapStateToProps)(GridMarketShipStats);