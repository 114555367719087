import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { chooseProductToShip, clearProductsToShip, changeProductToShip } from '../../../../../../redux/actions';
import RowToShip from './RowToShip';
import RowToShipLayout from './RowToShipLayout';
import '../../form_rate_choice/table_shipping_services/TableShippingServices.css';
import '../../../../../../styles/Panel.css';

const getKey = ({ sku }) => `TableProductsToShip.${sku}`;

const mapStateToProps = ({ shipment }) => ({
  choice: shipment.choice,
});

const mapDispatchToProps = dispatch => ({
  handleChoice: (event, { value }) => dispatch(chooseProductToShip(value)),
  makeSubmitter: (sku, field) => value => dispatch(changeProductToShip(sku, { [field]: value })),
});

class TableProductsToShip extends React.PureComponent{
  renderRow = product => (
    <RowToShip
      key={getKey(product)}
      product={product}
      handleChoice={this.props.handleChoice}
      makeSubmitter={this.props.makeSubmitter}
    />
  );
  render() {
    const { products } = this.props;
    return (
      <div className="panel__table table table-shipping-services__table table-products-to-ship">
        <RowToShipLayout
          skuColumn="SKU"
          nameColumn="Title"
          uomColumn="UOM"
          shipQtyColumn="Quantity"
          imageColumn="Image"
          checkColumn=""
          cellClassName="table-shipping-services__header"
        />
        {products.map(this.renderRow)}
      </div>
    );
  }
}


TableProductsToShip.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    sku: PropTypes.string,
    name: PropTypes.string,
    qty: PropTypes.number,
    chosen: PropTypes.bool,
  })),
  handleChoice: PropTypes.func,
  makeSubmitter: PropTypes.func,
  cleanUp: PropTypes.func,
};

TableProductsToShip.defaultProps = {
  products: [],
  handleChoice: (event, data) => {},
  cleanUp: () => {},
  makeSubmitter: (sku, field) => value => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(TableProductsToShip);