import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/Card.css';
import './CardImage.css';
import { sequreImageURL } from '../../../services/helpers';

function CardImage({ imageUrl, placeholderText, url, className }) {
  const imgStyle = {
    backgroundImage: `url(${sequreImageURL(imageUrl)})`,
  };
  return (
    <a href={url} className={`card ${className}`} target="_blank">
      <div className="card card-image" style={imgStyle} />
    </a>
  );
}

/*
<div className="card card-image" />
  <img src={imageUrl} alt={placeholderText} className="card-image__image" />
</div>
*/

CardImage.propTypes = {
  url: PropTypes.string,
  imageUrl: PropTypes.string,
  placeholderText: PropTypes.string,
  className: PropTypes.string,
};
CardImage.defaultProps = {
  url: '',
  imageUrl: '',
  placeholderText: '',
  className: '',
};

export default CardImage;
