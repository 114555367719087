import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Dropdown, Pagination } from 'semantic-ui-react';

import './Pagination.css';
import { PAGINATION_OPTIONS } from '../../../services/settings';

class PaginationComponent extends React.PureComponent{
  static getDerivedStateFromProps({ count, page, limit }, prevState) {
    if (count < prevState.count) {
      const pageCount = Math.ceil(count / limit);
      if (pageCount < page) return { page: pageCount, count };
    }
    if ((page !== prevState.page) || (count !== prevState.count)) return { page, count };
    return null;
  }
  constructor(props, context) {
    super(props, context);
    this.state = {
      page: 1,
      count: 0,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) this.props.handlePageChange(this.state.page);
  }
  getEnd = () => {
    const { offset, limit } = this.props;
    const { count } = this.state;
    const end = offset + limit;
    return count > end ? end : count;
  };
  countPages = () => {
    const { limit } = this.props;
    const { count } = this.state;
    return Math.ceil(count / limit);
  };
  handlePageChange = (event, { activePage }) => this.setState({ page: activePage });
  render() {
    const count = this.state.count;
    return (
      <div className="pagination-wrapper">
        <div className="pagination-block">
          <Pagination
            ariaLabel="pagination"
            firstItem={{ content: <Icon name="angle double left" />, icon: true }}
            lastItem={{ content: <Icon name="angle double right" />, icon: true }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            activePage={this.state.page}
            onPageChange={this.handlePageChange}
            totalPages={this.countPages()}
          />
        </div>
        <div className="pagination-info">
          <div className="pagination-info__dropdown-wrapper">
            <Dropdown
              className="pagination-info__dropdown"
              placeholder={this.props.offset.toString()}
              pointing="bottom right"
              options={PAGINATION_OPTIONS}
              selectOnBlur={false}
              onChange={this.props.handleLimitChange}
              value={this.props.limit}
            />
          </div>
          <div className="pagination-info__text">
            Displaying {this.props.offset} - {this.getEnd()} of {count} Records
          </div>
        </div>
      </div>
    );
  }
}


PaginationComponent.propTypes = {
  limit: PropTypes.number,
  count: PropTypes.number,
  offset: PropTypes.number,
  page: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleLimitChange: PropTypes.func,
};

PaginationComponent.defaultProps = {
  limit: 50,
  count: 0,
  offset: 0,
  page: 1,
  handlePageChange: (activePage) => null,
  handleLimitChange: (event, { value }) => null,
};

export default PaginationComponent;