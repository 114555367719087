import React from 'react';
import PropTypes from 'prop-types';
import { ButtonPicture } from '../../common';

export default function ButtonBulkEdit(props) {
  return (
    <ButtonPicture pictureURL="bulk_edits.png" {...props} />
  );
}


ButtonBulkEdit.propTypes = {};

ButtonBulkEdit.defaultProps = {};
