import React from 'react';
import { connect } from 'react-redux';

import './PageError.css';

export default function PageError({ message }) {
  return (
    <div className="page-error">
      {message}
    </div>
  );
}
