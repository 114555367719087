import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { downloadMarketCSV } from '../../../redux/actions';

import RowMarketShipCount from './RowMarketShipCount';
import './TableMarketShipCount.css';
import { addDays, objToSearch, orderStatus } from '../../../services/helpers';


const mapStateToProps = state => ({
  shipBy: state.systemStats.shipDate,
});

const mapDispatchToProps = dispatch => ({
  downloadCSV: (marketplace, shipDate, status) =>
    dispatch(downloadMarketCSV(marketplace, shipDate, status)),
});

class TableMarketShipCount extends React.PureComponent{
  makeClickOnLinkHandler = status => () => {
    const { shipBy, id } = this.props;
    const params = objToSearch({ marketplace: id, shipBy, status });
    const url = `/orders${params}`;
    window.open(url, '_blank');
  };
  makeClickOnDownloadHandler = status => () => {
    const { id, shipBy } = this.props;
    this.props.downloadCSV(id, shipBy, status);
  };
  render() {
    const { shipped, notShipped, total } = this.props;
    return (
      <div className="table-market-ship-count">
        <RowMarketShipCount
          label="shipped"
          count={shipped}
          total={total}
          handleClickLink={this.makeClickOnLinkHandler(orderStatus.shipped)}
          handleClickDownload={this.makeClickOnDownloadHandler(orderStatus.shipped)}
          cellClassName="table-market-ship-count__shipped"
        />
        <RowMarketShipCount
          label="not shipped"
          count={notShipped}
          total={total}
          handleClickLink={this.makeClickOnLinkHandler(orderStatus.notShipped)}
          handleClickDownload={this.makeClickOnDownloadHandler(orderStatus.notShipped)}
          cellClassName="table-market-ship-count__not-shipped"
        />
        <RowMarketShipCount
          label="total"
          count={total}
          handleClickLink={this.makeClickOnLinkHandler(orderStatus.open)}
          handleClickDownload={this.makeClickOnDownloadHandler(orderStatus.open)}
          cellClassName="table-market-ship-count__total"
        />
      </div>
    );
  };
}


TableMarketShipCount.propTypes = {
  shipped: PropTypes.number,
  notShipped: PropTypes.number,
  total: PropTypes.number,
  id: PropTypes.number,
  shipBy: PropTypes.string.isRequired,
  downloadCSV: PropTypes.func.isRequired,
};

TableMarketShipCount.defaultProps = {
  shipped: 0,
  notShipped: 0,
  total: 0,
  id: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableMarketShipCount);
