import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/Card.css';
import './CardLabeled.css';

const applyStyleIf = (condition, className) => condition ? className : '';
const combineStyles = (...classNames) => classNames.join(' ');

const getStyles = props => ({
  main: combineStyles(
    'card card-labeled',
    props.className,
    applyStyleIf(props.caution, combineStyles('card-labeled_caution', props.cautionClassName)),
    applyStyleIf(props.strange, combineStyles('card-labeled_strange', props.strangeClassName)),
  ),
  label: combineStyles(
    'card-labeled__label',
    props.labelClassName,
    applyStyleIf(props.caution, combineStyles('card-labeled__label_caution', props.labelCautionClassName)),
    applyStyleIf(props.strange, combineStyles('card-labeled__label_strange', props.labelStrangeClassName)),
  ),
  content: combineStyles(
    'card-labeled__content',
    props.contentClassName,
    applyStyleIf(props.many, combineStyles('card-labeled__content_list', props.manyClassName)),
  ),
});

function CardLabeled({ label, children, ...props }) {
  const styles = getStyles(props);
  return (
    <div className={styles.main}>
      <div className={styles.label}>
        {label}
      </div>
      <div className={styles.content} >
        {children}
      </div>
    </div>
  );
}

CardLabeled.propTypes = {
  label: PropTypes.string,
  caution: PropTypes.bool,
  strange: PropTypes.bool,
  lineLength: PropTypes.number,
  many: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  cautionClassName: PropTypes.string,
  labelCautionClassName: PropTypes.string,
  strangeClassName: PropTypes.string,
  labelStrangeClassName: PropTypes.string,
  manyClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node,
};
CardLabeled.defaultProps = {
  label: '',
  caution: false,
  strange: false,
  lineLength: 0,
  many: false,
  className: '',
  labelClassName: '',
  cautionClassName: '',
  labelCautionClassName: '',
  strangeClassName: '',
  labelStrangeClassName: '',
  manyClassName: '',
  contentClassName: '',
};

export default CardLabeled;
