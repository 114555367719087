import React from 'react';
import PropTypes from 'prop-types';
import './PopupQty.css';

export default function PopupQtyLayout({qtyComponent, shipQtyComponent}) {
  return (
    <div className="popup-qty">
      <div className="popup-qty__container popup-qty__container_ship">
        {shipQtyComponent}
      </div>
      <div className="popup-qty__container">
        {qtyComponent}
      </div>
    </div>
  );
}


PopupQtyLayout.propTypes = {
  qtyComponent: PropTypes.node,
  shipQtyComponent: PropTypes.node,
};

PopupQtyLayout.defaultProps = {
  qtyComponent: null,
  shipQtyComponent: null,
};
