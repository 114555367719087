import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { connect } from 'react-redux';

import PanelShippingControlsLayout from './PanelShippingControlsLayout';
import '../../../styles/Panel.css';
import ButtonReprintLabel from '../panel_size/popup_shipping/button_reprint_label/ButtonReprintLabel';
import PopupShipping from '../panel_size/popup_shipping/PopupShipping';

const mapStateToProps = (state, props) => ({
  localParcelSizes: state.tempParcelSizes[props.ids.orderID],
});

const mergeSizeValues = (localSizes, remoteSizes) => ({
  width: localSizes.width || remoteSizes.width,
  height: localSizes.height || remoteSizes.height,
  length: localSizes.length || remoteSizes.length,
  weight: localSizes.weight || remoteSizes.weight,
});

function PanelShippingControls({ ids, shipInfo, localParcelSizes, orderCount }) {
  return (
    <PanelShippingControlsLayout
      returnComponent={
        <PopupShipping
          ids={ids}
          size={mergeSizeValues(localParcelSizes, shipInfo)}
          shipmentID={shipInfo.shipmentID}
          orderCount={orderCount}
          returning={true}
        >
          <Button
            className="panel__button"
          >
            Return Shipment
          </Button>
        </PopupShipping>
      }
      reprintComponent={
        <ButtonReprintLabel
          className="panel__button"
          shipmentID={shipInfo.shipmentID}
        />
      }
    />
  );
}

const PropTypeNumOrStr = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

PanelShippingControls.propTypes = {
  localParcelSizes: PropTypes.shape({
    weight: PropTypeNumOrStr,
    width: PropTypeNumOrStr,
    height: PropTypeNumOrStr,
    length: PropTypeNumOrStr,
  }),
  shipInfo: {
    weight: PropTypeNumOrStr,
    width: PropTypeNumOrStr,
    height: PropTypeNumOrStr,
    length: PropTypeNumOrStr,
    shipmentID: PropTypes.string,
  },
};

PanelShippingControls.defaultProps = {
  localParcelSizes: {
    weight: '',
    width: '',
    height: '',
    length: '',
  },
  shipInfo: {
    weight: '',
    width: '',
    height: '',
    length: '',
    shipmentID: '',
  },
};

export default connect(mapStateToProps)(PanelShippingControls)