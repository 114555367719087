import { put, call, select } from 'redux-saga/effects';

import { requestMaker } from '../../../services/settings';

import { orderStatus } from '../../../services/helpers';

const selectParams = ({ orderSearch }) => {
  if (orderSearch.distrPage) {
    return {
      status: orderStatus.export,
      distr: orderSearch.distributor,
      itemInfo: orderSearch.itemInfo,
      itemID: orderSearch.itemID,
      orderID: orderSearch.orderID,
      customer: orderSearch.customer,
    };
  }
  return {
    status: orderSearch.status,
    distr: orderSearch.distributor,
    shipBy: orderSearch.shipBy,
    search: orderSearch.searchAll,
    marketplace: orderSearch.marketplace,
    itemInfo: orderSearch.itemInfo,
    itemID: orderSearch.itemID,
    orderID: orderSearch.orderID,
    customer: orderSearch.customer,
  };
};

const shipOrderStatuses = [
  orderStatus.archive,
  orderStatus.shipReady,
];

const isAddToShipOrder = (oldStatus, newStatus) =>
  !shipOrderStatuses.includes(oldStatus) && shipOrderStatuses.includes(newStatus);

const isRemoveFromShipOrder = (oldStatus, newStatus) =>
  (newStatus != null)
  && shipOrderStatuses.includes(oldStatus)
  && !shipOrderStatuses.includes(newStatus);

const getShipOrderRequestMethod = (oldStat, newStat) => {
  if (isAddToShipOrder(oldStat, newStat)) return 'post';
  if (isRemoveFromShipOrder(oldStat, newStat)) return 'delete';
  return null;
};

export function* testShipOrder(order) {
  const token = yield select(state => state.user.token);
  const params = { upc: order.buyInfo.itemIDs.upc, orderID: order.unique.orderID };
  try {
    const response = yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'get',
      url: '/api/v1/ship-order/',
      params,
    });
    console.log(response.data);
  } catch (error) {
    console.log(`${params.upc}/${params.orderID} \n ${error.response.data}`);
  }
}

const noParams = params => Object.keys(params).every(key => !params[key]);

const getParams = (searchQuery, ids) => {
  const { marketplaceID: marketplace, orderID, orderItemID } = ids;
  const { status, ...restParams } = searchQuery;
  if (noParams(restParams)) {
    return { marketplace, orderID, orderItemID, status };
  }
  return { marketplace, orderID, orderItemID };
};

const makeChangeOrderSaga = ({ orderSelector, url, successAction, failureAction }) =>
  function* changeOrderSaga({ ids, changes, loaderId }) {
    const token = yield select(state => state.user.token);
    const orderToChange = yield select(orderSelector(ids));
    const searchQuery = yield select(selectParams);
    try {
      yield call(requestMaker, {
        headers: { Authorization: `JWT ${token}` },
        method: 'patch',
        data: { ...ids, changes },
        url,
      });
      const shipOrderRequestMethod = getShipOrderRequestMethod(
        orderToChange.status, changes.status,
      );
      if (shipOrderRequestMethod) {
        yield call(requestMaker, {
          headers: { Authorization: `JWT ${token}` },
          method: shipOrderRequestMethod,
          url: '/api/v1/ship-order/',
          data: { upc: orderToChange.buyInfo.itemIDs.upc, orderID: ids.orderID },
        });
      }
      const getResponse = yield call(requestMaker, {
        headers: { Authorization: `JWT ${token}` },
        method: 'get',
        params: getParams(searchQuery, ids),
        url,
      });
      yield put(successAction(ids, getResponse.data.list[0], loaderId));
    } catch (error) {
      yield put(failureAction(error, loaderId));
    }
  };

export default makeChangeOrderSaga;
