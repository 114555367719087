import React from 'react';
import PropTypes from 'prop-types';
import './HeaderOrderSearch.css';

export default function HeaderOrderSearchLayout(props) {
  return (
    <div className="header-order-search">
      <div className="header-order-search__container header-order-search__counter">
        {props.count}
      </div>
      <div className="header-order-search__container">
        {props.statusSpot}
      </div>
      <div className="header-order-search__container">
        {props.marketSpot}
      </div>
      <div className="header-order-search__container header-order-search__container_date">
        {props.shipBySpot}
      </div>
      <div className="header-order-search__container">
        {props.distrSpot}
      </div>
      <div className="header-order-search__container  header-order-search__container_bulk">
        {props.bulkEditSpot}
      </div>
      <div className="header-order-search__container  header-order-search__container_search">
        {props.generalSearchSpot}
      </div>
    </div>
  );
}


HeaderOrderSearchLayout.propTypes = {
  count: PropTypes.number,
  statusSpot: PropTypes.node,
  marketSpot: PropTypes.node,
  shipBySpot: PropTypes.node,
  distrSpot: PropTypes.node,
  generalSearchSpot: PropTypes.node,
  bulkEditSpot: PropTypes.node,
};

HeaderOrderSearchLayout.defaultProps = {
  count: 0,
  statusSpot: '',
  shipBySpot: '',
  marketSpot: '',
  distrSpot: '',
  generalSearchSpot: '',
  bulkEditSpot: '',
};
