import { put, call, select } from 'redux-saga/effects';

import {
  changeProductsBulkWithoutStoreSuccess,
  changeProductsBulkWithoutStoreFailure,
} from '../../actions';
import { requestMaker } from '../../../services/settings';

const selectToken = ({ user }) => user.token;

export default function* setProductBulkWithoutStoreSaga({ filters, changes, update }) {
  const token = yield select(selectToken);
  try {
    yield call(requestMaker, {
      headers: { Authorization: `JWT ${token}` },
      method: 'patch',
      url: '/api/v1/products/all/',
      data: { changes, filters },
    });
    yield put(changeProductsBulkWithoutStoreSuccess());
    yield call(update);
  }
  catch (error) {
    yield put(changeProductsBulkWithoutStoreFailure(error));
  }
};
