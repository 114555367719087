import makeReducer from '../makeReducer';
import {
  GET_PRODUCTS_TO_SHIP, GET_PRODUCTS_TO_SHIP_SUCCESS, GET_PRODUCTS_TO_SHIP_FAILURE,
  CHOOSE_PRODUCT_TO_SHIP, CLEAR_PRODUCTS_TO_SHIP, CHANGE_PRODUCT_TO_SHIP,
} from '../actions'

const initialState = {
  list: [],
  loading: false,
};

const reset = () => ({ ...initialState });
const changeEntryWithSKU = getChanged => (sku, changes) => product => (sku === product.sku)
  ? getChanged(product, changes)
  : product;
const choose = changeEntryWithSKU(product => ({ ...product, chosen: !product.chosen }));
const applyChanges = changeEntryWithSKU((product, changes) => ({ ...product, ...changes }));

export default makeReducer(initialState, {
  [GET_PRODUCTS_TO_SHIP_SUCCESS]: (state, { list }) => ({ ...state, list, loading: false }),
  [GET_PRODUCTS_TO_SHIP_FAILURE]: reset,
  [GET_PRODUCTS_TO_SHIP]: state => ({ ...state, loading: true }),
  [CLEAR_PRODUCTS_TO_SHIP]: reset,
  [CHANGE_PRODUCT_TO_SHIP]: ({ list, ...state }, { sku, changes }) =>
    ({ ...state, list: list.map(applyChanges(sku, changes)) }),
  [CHOOSE_PRODUCT_TO_SHIP]: ({ list, ...state }, { sku }) =>
    ({ ...state, list: list.map(choose(sku)) }),
});
