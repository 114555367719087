import { SAVE_METADATA } from '../actions';

const initialMeta = {
  orderStatuses: [], // id = status_codes.OrderStatus, name = status_codes.Name
  marketplaces: [],
  distributors: [],
  shippingCarriers: [],
};

export default function metaReducer(meta = initialMeta, { type, meta: payload }) {
  switch (type) {
    case SAVE_METADATA:
      return { ...meta, ...payload };
    default: return meta;
  }
}