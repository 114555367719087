import React from 'react';
import { Route, Redirect} from 'react-router';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import {
  PageProducts, PageOrders, PageDistr,
  PageShipping, PageSystem, PageInventory,
  PageLogin, PageForbidden, PageNotFound,
  PagePrintLabel,
} from './routes';

import { Layout, Header, ModalReport } from './components/layout';
import './App.css';
import rootReducer from './redux/reducers/rootReducer';
import rootSaga from './redux/sagas/rootSaga';
import { loadState, initReportTimer } from './redux/actions';


const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const sagaMiddleware = createSagaMiddleware();
const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware),
  // other store enhancers if any
);
const store = createStore(rootReducer, enhancer);
sagaMiddleware.run(rootSaga);
store.dispatch(loadState());
store.dispatch(initReportTimer());

const renderLayout = () => (
  <Layout
    headerComponent={<Header />}
    footerComponent={<div>ERP system 2018. <em>ValoMarket&reg;</em></div>}
    modalComponent={<ModalReport />}
  >
    <Switch>
      <Route path="/products" component={PageProducts} />
      <Route exact path="/orders" component={PageOrders} />
      <Route exact path="/login" component={PageLogin} />
      <Route exact path="/" component={PageLogin} />
      <Route exact path="/distributors" component={PageDistr} />
      <Route exact path="/inventory" component={PageInventory} />
      <Route exact path="/system" component={PageSystem} />
      <Route exact path="/shipping" component={PageShipping} />
      <Route exact path="/forbidden" component={PageForbidden} />
      <Route exact path="/notFound" component={PageNotFound} />
      <Redirect to="/notFound" />
    </Switch>
  </Layout>
);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/print-label" component={PagePrintLabel} />
          <Route path="/" render={renderLayout} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
