import ReactTable from 'react-table';
import 'react-table/react-table.css';

import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/Table.css';
import './PopupAvailProds.css';
import {
  decimalFieldPreset, integerFieldPreset, makeHiddenInputRenderer, makeSwitchRenderer,
  makeColumnSetuper,
} from '../../common/tableCellRenders';

function defaultValue({ value }) {
  return value || '-';
}

const setupColumn = makeColumnSetuper('table__column', 'table__head table__column');

function TableAvailable({ availableProducts, makeSubmitter, makeUnpublishSubmitter }) {
  const switchUnpublish = makeSwitchRenderer(makeUnpublishSubmitter, 'distributorid');
  const inputWithSubmit = preset =>
    makeHiddenInputRenderer(makeSubmitter, preset, 'distributorid');
  const columnSettings = [
    { accessor: 'name', minWidth: 20.5},
    { Header: 'id num', accessor: 'distributorproductid', minWidth: 31.5 },
    { accessor: 'price', minWidth: 22.25, Cell: defaultValue },
    { accessor: 'qty', minWidth: 16.5, Cell: defaultValue },
    { accessor: 'brand', minWidth: 44.75 },
    {
      accessor: 'title',
      minWidth: 165.5,
      className: 'table__column popup-avail-prods__cell_title',
    },
    { accessor: 'description', minWidth: 29 },
    { accessor: 'weight', minWidth: 22.25, Cell: inputWithSubmit(decimalFieldPreset)},
    { accessor: 'size', minWidth: 31.25},
    { accessor: 'uom', minWidth: 16.75, Cell: inputWithSubmit(integerFieldPreset) },
    { accessor: 'unpublish', minWidth: 29.75, Cell: switchUnpublish },
  ];
  const columns = columnSettings.map(setupColumn);
  return (
    <ReactTable
      noDataText="There are no products"
      data={availableProducts}
      columns={columns}
      className="table table-products__table"
      showPaginationBottom={false}
      loadingText=""
      resizable={false}
      minRows={1}
    />
  );
}

TableAvailable.propTypes = {
  availableProducts: PropTypes.arrayOf(PropTypes.object),
  makeSubmitter: PropTypes.func,
  makeUnpublishSubmitter: PropTypes.func,
};

TableAvailable.defaultProps = {
  availableProducts: [],
  makeSubmitter: () => () => {},
  makeUnpublishSubmitter: () => () => {},
};

export default TableAvailable;
