import { put, call, select } from 'redux-saga/effects';
import { requestMaker } from '../../services/settings';

export default function* authorizedRequest({ requestSettings, successAction, failureAction, loaderId, mapData }) {
  const token = yield select(state => state.user.token);
  const usedRequestSettings = {
    ...requestSettings,
    headers: {
      ...requestSettings.headers,
      Authorization: `JWT ${token}`,
    },
  };
  try {
    const response = yield call(requestMaker, usedRequestSettings);
    if (mapData && response.data){
      yield put(successAction(mapData(response.data), loaderId));
    } else {
      yield put(successAction(loaderId));
    }
  } catch (error) {
    yield put(failureAction(error, loaderId));
  }
}

