import {
  HIDE_REPORTS_MODAL,
  SHOW_REPORTS_MODAL,
  GET_REPORTS_SUCCESS,
  INIT_REPORT_TIMER,
  LOAD_STATE_SUCCESS,
  LOG_OUT,
  STOP_REPORT_TIMER,
  GET_REPORTS,
} from '../actions';
import makeReducer from '../makeReducer';

const initialReports = {
  urgent: [],
  list: [],
  lastReportTime: null,
  lastReportTimeBeforeRequest: null,
  showModal: false,
  timerID: null,
};

const listIsNotEmpty = list => !!(list && list.length);

const stopTimer = state => ({ ...state, timerID: null });

const startTimer = ({ timerID, ...state }, action) => ({
  ...state,
  timerID: timerID || action.timerID,
  lastReportTimeBeforeRequest: state.lastReportTime,
});

const actionHandlers = {
  [LOAD_STATE_SUCCESS]: (state, { data }) =>
    ({ ...state, lastReportTime: data.lastReportTime }),
  [HIDE_REPORTS_MODAL]: state => ({ ...state, showModal: false }),
  [SHOW_REPORTS_MODAL]: state => ({ ...state, showModal: true }),
  [GET_REPORTS_SUCCESS]: (state, { urgent, lastReportTime }) => (state.showModal)
    ? state
    : { ...state, urgent, lastReportTime, showModal: listIsNotEmpty(urgent) },
  [STOP_REPORT_TIMER]: stopTimer,
  [LOG_OUT]: state => ({ ...state, timerStarted: false, lastReportTime: null }),
  [GET_REPORTS]: startTimer,
};

export default makeReducer(initialReports, actionHandlers);
