import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import '../../../styles/Card.css';
import './CardLabeled.css';

class CardLabeledWithPopup extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isOpen: false,
    };
  }
  close = () => this.setState({ isOpen: false });
  handleOpen = () => this.setState({ isOpen: true });
  render() {
    const { label, children, PopupContent, many, ...props } = this.props;
    const manyStyle = many ? 'card-labeled__content_list' : '';
    return (
      <div className="card card-labeled">
        <Popup
          open={this.state.isOpen}
          onClose={this.close}
          onOpen={this.handleOpen}
          trigger={
            <div className="card-labeled__label card__labeled_clickable">
              {label}
            </div>
          }
          position="right center"
          on="click"
        >
          <PopupContent close={this.close} {...props} />
        </Popup>
        <div className={`card-labeled__content ${manyStyle}`}>
          {children}
        </div>
      </div>
    );
  }
}

const PropTypesRenderable = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.string,
  PropTypes.number,
]);
CardLabeledWithPopup.propTypes = {
  label: PropTypes.string,
  children: PropTypesRenderable,
  PopupContent: PropTypes.func.isRequired,
  many: PropTypes.bool,
};
CardLabeledWithPopup.defaultProps = {
  label: '',
  children: '',
  many: false,
};

export default CardLabeledWithPopup;
