import { call } from 'redux-saga/effects';
import { popupSetMarketsTableCellFailure, popupSetMarketsTableCellSuccess } from '../../actions';
import setAvailableProduct from '../products/setAvailableProduct';

export default function* setPopupMarketsTableCell({ sku, marketplaceid, values }){
  yield call(
    setAvailableProduct,
    { sku, marketplaceid, ...values },
    popupSetMarketsTableCellSuccess,
    popupSetMarketsTableCellFailure,
  );
};
