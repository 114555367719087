import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import './PageOrders.css';
import { TableOrders, withPermissionRedirection } from '../../containers';
import { getMetadata, changeOrderSearch, resetOrderSearch, changeOrder } from '../../redux/actions';
import { searchToObj } from '../../services/helpers';

function mapStateToProps(state) {
  return {
    products: state.orders.list,
    loading: state.orders.loading,
    offset: state.orders.offset,
    count: state.orders.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initialize: (search) => {
      dispatch(getMetadata());
      dispatch(changeOrderSearch({ distrPage: false, ...search }));
    },
    changeSearch: change => dispatch(changeOrderSearch(change)),
    setOrderField: (ids, changes, loaderId) => dispatch(changeOrder(ids, changes, loaderId)),
    cleanUp: () => {
      dispatch(resetOrderSearch());
    },
  };
}

class PageOrders extends React.PureComponent {
  static propTypes = {
    initialize: PropTypes.func,
    cleanUp: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    changeSearch: PropTypes.func,
    setOrderField: PropTypes.func,
  };
  static defaultProps = {
    initialize: () => {},
    cleanUp: () => {},
    changeSearch: null,
    setOrderField: null,
  };
  componentDidMount() {
    let search = searchToObj(this.props.location.search);
    search = search ? {
      status: +search.status,
      marketplace: +search.marketplace,
      shipBy: search.shipBy,
    } : {};
    this.props.initialize(search);
  }
  componentWillUnmount() {
    this.props.cleanUp();
  }
  render() {
    return (
      <TableOrders {...this.props} />
    );
  }
}

export default compose(
  withPermissionRedirection,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PageOrders);