import React from 'react';
import PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import GridMarketShipStats from '../grid_market_ship_stats/GridMarketShipStats';
import PanelOrderCountByDistr from '../panel_order_count_by_distr/PanelOrderCountByDistr';


import './ContentSystem.css';

const mapStateToProps = state => ({
  loading: state.systemStats.loading,
});

function ContentSystem({ loading }) {
  return (
    <div className="content-system">
      <div className="content-system__container">
        {(!loading.marketplaces)
          ? <GridMarketShipStats />
          : <Loader active={loading.marketplaces} />
        }
      </div>
      <div className="content-system__container content-system__container_distr">
        {(!loading.distributors)
          ? <PanelOrderCountByDistr />
          : <Loader active={loading.distributors} />
        }
      </div>
    </div>
  );
}


ContentSystem.propTypes = {
  loading: PropTypes.shape({
    marketplaces: PropTypes.bool,
    distributors: PropTypes.bool,
  }),
};

ContentSystem.defaultProps = {
  loading: { },
};

export default connect(mapStateToProps)(ContentSystem)