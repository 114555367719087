import React, {Component} from 'react';
import PropTypes from 'prop-types';

class WithSubmit extends Component {
  constructor(props, context){
    super(props, context);
    const { value, validate } = props;
    this.state = {
      value,
      editing: false,
      valid: validate(value),
    };
    this.focus = false;
  }
  handleClick = () => {
    this.focus = true;
  };
  handleKeyPress = ({ key }) => {
    if (key === 'Enter') {
      if (this.state.valid) {
        this.props.submit(this.state.value);
        this.setState({ editing: false });
      }
    } else if (key === 'Escape') {
      this.handleBlur();
    }
  };
  handleBlur = () => {
    if (this.state.valid && this.state.value !== this.props.value) {
      this.props.submit(this.state.value);
    }
    this.setState({ editing: false, value: this.props.value });
  };
  handleChange = (event, { value }) => {
    this.setState({ editing: true, value, valid: this.props.validate(value) });
  };
  getClassNameOnError = () => this.state.editing && !this.state.valid ? this.props.classNameOnError : '';
  getClassNameOnEdit = () => this.state.editing ? this.props.classNameOnEdit : '';
  getClassName = () =>
    `${this.props.className} ${this.getClassNameOnEdit()} ${this.getClassNameOnError()}`;
  render() {
    const {
      className, classNameOnEdit, classNameOnError, validate,
      submit, value, Input, ...props
    } = this.props;
    const { focus } = this;
    this.focus = false;
    return (
      <React.Fragment>
        <Input
          onClick={this.handleClick}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyPress}
          onBlur={this.handleBlur}
          className={this.getClassName()}
          value={this.state.editing ? this.state.value : this.props.value}
          autoFocus={focus}
          {...props}
        />
      </React.Fragment>
    );
  }
}

WithSubmit.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  submit: PropTypes.func.isRequired,
  classNameOnEdit: PropTypes.string,
  className: PropTypes.string,
  classNameOnError: PropTypes.string,
  Input: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  validate: PropTypes.func,
};
WithSubmit.defaultProps = {
  value: '',
  classNameOnEdit: '',
  classNameOnError: '',
  className: '',
  validate: value => true,
};

export default WithSubmit;

