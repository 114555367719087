import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 as getID } from 'uuid';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  loaderList: state.loaders,
});

class WithLoader extends Component {
  constructor(props, context) {
    super(props, context);
    this.id = getID();
  }
  render() {
    const { Element, loaderList, loaderID, dispatch, passLoaderID, ...props } = this.props;
    const usedLoaderID = loaderID || this.id;
    if (passLoaderID) props.loaderID = usedLoaderID;
    return (
      <Element
        {...props}
        loading={!!loaderList[usedLoaderID]}
      />
    );
  }
}

WithLoader.propTypes = {
  loaderList: PropTypes.objectOf(PropTypes.bool),
  loaderID: PropTypes.string,
  Element: PropTypes.func.isRequired,
  passLoaderID: PropTypes.bool,
};
WithLoader.defaultProps = {
  loaderList: [],
  loaderID: '',
  passLoaderID: false,
};

const WithLoaderContainer = connect(mapStateToProps)(WithLoader);

const withLoader = Element => props => (
  <WithLoaderContainer
    Element={Element}
    {...props}
  />
);

export default withLoader;
