import { put, call } from 'redux-saga/effects';
import makeRequest from '../authorizedRequest';
import { deleteProdInventoryFailure, deleteProdInventorySuccess } from '../../actions';

const makeDeleteProdInventorySuccess = upc => loaderId =>
  deleteProdInventorySuccess(upc, loaderId);

export default function* deleteProdInvSaga({ upc, loaderId }) {
  yield call(makeRequest, {
    requestSettings: {
      method: 'delete',
      url: '/api/v1/inventory/',
      data: { upc, distrID: 1 },
    },
    successAction: makeDeleteProdInventorySuccess(upc),
    failureAction: deleteProdInventoryFailure,
    loaderId,
  });
};

