import React from 'react';
import PropTypes from 'prop-types';

import '../../../../../../styles/Panel.css';
import '../../PopupShipping.css';
import './TableProductsToShip.css';

export default function RowToShipLayout({ cellClassName, ...props }) {
  return (
    <React.Fragment>
      <div className={`panel__table__cell popup-shipping__table__cell ${cellClassName}`}>
        {props.skuColumn}
      </div>
      <div className={`panel__table__cell popup-shipping__table__cell popup-shipping__table__cell_service ${cellClassName}`}>
        {props.nameColumn}
      </div>
      <div className={`panel__table__cell popup-shipping__table__cell ${cellClassName}`}>
        {props.imageColumn}
      </div>
      <div className={`panel__table__cell popup-shipping__table__cell ${cellClassName}`}>
        {props.uomColumn}
      </div>
      <div className={`panel__table__cell popup-shipping__table__cell popup-shipping__table__cell_image ${cellClassName}`}>
        {props.shipQtyColumn}
      </div>
      <div className={`panel__table__cell popup-shipping__table__cell_radio panel__table__cell_right-end ${cellClassName}`}>
        {props.checkColumn}
      </div>
    </React.Fragment>
  );
}


RowToShipLayout.propTypes = {
  skuColumn: PropTypes.node,
  nameColumn: PropTypes.node,
  uomColumn: PropTypes.node,
  checkColumn: PropTypes.node,
  shipQtyColumn: PropTypes.node,
  imageColumn: PropTypes.node,
  cellClassName: PropTypes.string,
};

RowToShipLayout.defaultProps = {
  skuColumn: '',
  nameColumn: '',
  uomColumn: '',
  checkColumn: '',
  shipQtyColumn: '',
  imageColumn: '',
  cellClassName: '',
};
