import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TableProducts, withPermissionRedirection } from '../../containers';
import './PageProducts.css';
import { getMetadata } from '../../redux/actions';

const mapStateToProps = state => ({
  products: state.productList.products,
  loading: state.productList.loading,
  distributors: state.meta.distributors,
  marketplaces: state.meta.marketplaces,
  count: state.productList.count,
});

const mapDispatchToProps = dispatch => ({
  initialize: () => {
    dispatch(getMetadata());
  },
});

class PageProducts extends React.PureComponent {
  static propTypes = {
    products: PropTypes.arrayOf(PropTypes.object),
    offset: PropTypes.number,
    distributors: PropTypes.arrayOf(PropTypes.object),
    marketplaces: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    initialize: PropTypes.func,
    count: PropTypes.number,
  };
  static defaultProps = {
    auth: true,
    products: [],
    offset: 0,
    distributors: [],
    marketplaces: [],
    loading: false,
    initialize: () => null,
    count: 0,
  };
  componentDidMount() {
    this.props.initialize();
  }
  render() {
    return (
      <TableProducts {...this.props} />
    );
  }
}

export default withPermissionRedirection(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageProducts));
