import React from 'react';
import PropTypes from 'prop-types';
import './Labeled.css';

export default function Labeled({ children, label, className }) {
  return (
    <div className={`bottom-labeled ${className}`}>
      <div className="bottom-labeled__content">
        {children}
      </div>
      <div className="bottom-labeled__label">
        {label}
      </div>
    </div>
  );
}


Labeled.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

Labeled.defaultProps = {
  label: '',
  children: '',
  className: '',
};
