import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { connect } from 'react-redux';

import InputHiddenTabControlled from './input_hidden_tab_controlled/InputHiddenTabControlled';
import CardLabeledWithDisable from './CardLabeled';
import { CardLabeled, YesNoSwitch as YesNoSwitchWithoutLoader } from '../../common';
import PanelSizeLayout from './PanelSizeLayout';
import { makeValidatorDecimalWithRegEx as makeValidatorDecimal } from '../../../services/validators';
import { withLoader } from '../../../containers';
import PopupShipping from './popup_shipping/PopupShipping';
import './PanelSize.css';
import './popup_shipping/PopupShipping.css';
import { setParcelSize, removeParcelSize } from '../../../redux/actions';
import poke from '../../../services/helpers/poke'

const InputHidden = withLoader(InputHiddenTabControlled);

const sizeValidator = makeValidatorDecimal(6, 2);

const fieldTabNums = {
  weight: 1,
  length: 2,
  width: 3,
  height: 4,
};

const YesNoSwitch = withLoader(YesNoSwitchWithoutLoader);

const ORDER_HEIGHT = 122.641;
const POPUP_FRAME_HEIGHT = 241.233;

const isPopupFits = (buttonY, orderCount) => {
  return buttonY > ((ORDER_HEIGHT * orderCount) + POPUP_FRAME_HEIGHT);
};

function getNextTab(tab) {
  const tabs = Object.values(fieldTabNums);
  const index = tabs.indexOf(tab);
  return tabs[(index + 1) % tabs.length];
}

const mapStateToProps = ({ tempParcelSizes }, props) => ({
  localParcelSize: tempParcelSizes[props.ids.orderID],
});

const mapDispatchToProps = dispatch => ({
  setSizeField(orderID, field, value) {
    dispatch(setParcelSize(orderID, { [field]: value }));
  },
  removeParcelSize(orderID) { dispatch(removeParcelSize(orderID)); },
});

class PanelSize extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.initialState = {
      tab: 0,
    };
    this.state = { ...this.initialState, popupFits: true };
    this.popupButtonRef = React.createRef();
  }
  componentDidMount() {
    const buttonY = this.popupButtonRef.current.ref.getBoundingClientRect().top;
    this.setState({
      popupFits: isPopupFits(buttonY, this.props.orderCount),
    });
  }
  getChanged() {
    const { localParcelSize } = this.props;
    const changed = {};
    let thereAreChanges = false;
    Object.keys(fieldTabNums).forEach((field) => {
      if (localParcelSize[field] !== ''){
        thereAreChanges = true;
        changed[field] = localParcelSize[field];
      }
    });
    return thereAreChanges ? changed : null;
  };
  getValue(field) {
    const { localParcelSize, shipInfo, disabled } = this.props;
    if (localParcelSize[field] !== '') return +localParcelSize[field];
    if (!disabled) return +shipInfo[field];
    return null;
  }
  getAllValues() {
    const values = {};
    Object.keys(fieldTabNums).forEach((field) => { values[field] = this.getValue(field); });
    return values;
  }
  makeSubmitter = field => value => {
    const { setSizeField, ids } = this.props;
    setSizeField(ids.orderID, field, value);
  };
  showInput = (tab) => {
    this.setState({ tab });
  };
  hideInput = (tab) => {
    if (this.state.tab === tab) this.setState({ tab: 0 });
  };
  submit = () => {
    const { setOrderField, ids } = this.props;
    const changes = this.getChanged();
    if (changes && !this.props.disabled) {
      setOrderField(ids, {
        shipLength: changes.length,
        shipWidth: changes.width,
        shipHeight: changes.height,
        shipWeight: changes.weight,
      });
      this.props.removeParcelSize(ids.orderID);
    }
  };
  handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      this.setState({ tab: getNextTab(this.state.tab) });
      event.preventDefault();
    }
  };
  renderCard(field, label, contentClassName = '') {
    return (
      <CardLabeledWithDisable
        label={label || field}
        contentClassName={contentClassName}
        old={!this.props.localParcelSize[field]}
      >
        <InputHidden
          tab={fieldTabNums[field]}
          currentTab={this.state.tab}
          value={this.getValue(field)}
          validate={sizeValidator}
          submit={this.makeSubmitter(field)}
          placeholder="-"
          onShow={this.showInput}
          onHide={this.hideInput}
          type="number"
        />
      </CardLabeledWithDisable>
    );
  }
  render() {
    const {
      ids, shipInfo, orderCount, setIsFood, isFoodLoaderID,
    } = this.props;
    return (
      <PanelSizeLayout
        onKeyDown={this.handleKeyDown}
        lenghtComponent={this.renderCard('length')}
        widthComponent={this.renderCard('width')}
        heightComponent={this.renderCard('height')}
        weightComponent={this.renderCard('weight', 'weight (lbs)', 'panel-size__card-labeled_weight')}
        buttonComponent={
          <PopupShipping
            ids={ids}
            size={this.getAllValues()}
            shipmentID={shipInfo.shipmentID}
            orderCount={orderCount}
            positionBottom={!this.state.popupFits}
          >
            <Button
              className="panel__button"
              onClick={this.submit}
              ref={this.popupButtonRef}
            >
              Ship Order
            </Button>
          </PopupShipping>
        }
        foodComponent={
          <CardLabeled
            label="food"
            caution={!!shipInfo.isFood}
            className="panel-size__card-label_food"
            labelClassName="panel-size__card-labeled_food__label"
            contentClassName="panel-size__card-labeled_food__content"
          >
            <YesNoSwitch
              submit={setIsFood}
              value={!!shipInfo.isFood}
              loaderID={isFoodLoaderID}
            />
          </CardLabeled>
        }
      />
    );
  }
}


PanelSize.propTypes = {
  localParcelSize: {
    length: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    weigth: PropTypes.number,
  },
  shipInfo: PropTypes.shape({
    length: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    weigth: PropTypes.number,
    shipmentID: PropTypes.string,
    isFood: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  ids: PropTypes.shape({
    orderID: PropTypes.string,
    orderItemID: PropTypes.string,
    marketplaceID: PropTypes.number,
  }),
  setOrderField: PropTypes.func,
  orderCount: PropTypes.number,
  setIsFood: PropTypes.func,
  isFoodLoaderID: PropTypes.string,
  setSizeField: PropTypes.func.isRequired,
  removeParcelSize: PropTypes.func.isRequired,
};

PanelSize.defaultProps = {
  localParcelSize: {
    length: '',
    width: '',
    height: '',
    weight: '',
  },
  shipInfo: {
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
  },
  disabled: false,
  ids: {},
  setOrderField: () => {},
  setIsFood: () => {},
  orderCount: 0,
  isFoodLoaderID: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelSize);