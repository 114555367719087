import { put, call, select } from 'redux-saga/effects';
import fileDownload from 'js-file-download';

import { downloadMarketCSVFailure, downloadMarketCSVSuccess } from '../../actions';
import { requestMaker } from '../../../services/settings';
import { addDays, CSVNullToSpace, formatCSVString, orderStatus, poke } from '../../../services/helpers';

const formatDate = (date) => {
  const [year, month, day] = date.split('T')[0].split('-');
  return `${year}-${month}-${day}`;
};

const formatFileDate = (date) => {
  const [year, month, day] = date.split('T')[0].split('-');
  return `${month}-${day}-${year}`;
};

const withoutSpaces = name => name.split(' ').join('');

const getFileType = (status) => {
  switch(status){
    case orderStatus.shipped:
      return 'Shipped';
    case orderStatus.notShipped:
      return 'NotShipped';
    case orderStatus.open:
      return 'Total';
    default:
      return 'Other';
  }
};

const getOrders = (params, token) => call(requestMaker, {
  headers: { Authorization: `JWT ${token}` },
  method: 'get',
  url: '/api/v1/order/',
  params,
});

const columnHeadersLine = [
  '"Order ID"', '"Tracking Number"', '"Shipping Carrier"', '"Order Status"',
  '"ShipBy Date"', '"Order Notes"', '"SKU"', '"UPC"', '"Quantity Purchased"',
  '"Address1"', '"Address2"', '"Address3"', '"City"', '"State"', '"Zip Code"',
  '"Country"',
].join(',');

const getAddressString = list =>
  list.reduce((cell, line) => {
    const formattedLine = formatCSVString(line);
    if (formattedLine) {
      if (cell) return `${cell}\n${formattedLine}`;
      return formattedLine;
    }
    return cell;
  }, '');

const makeOrderAggregator = getStatusName => order => ({
  orderID: CSVNullToSpace(order.unique.orderID),
  trackingNumber: CSVNullToSpace(order.trackingNumber),
  shippingCarrier: CSVNullToSpace(order.shippingCarrier),
  status: getStatusName(order.status),
  shipBy: formatCSVString(formatDate(order.orderInfo.dateShipBy)),
  notes: formatCSVString(order.notes),
  sku: CSVNullToSpace(order.buyInfo.itemIDs.sku),
  upc: CSVNullToSpace(order.buyInfo.itemIDs.upc),
  qty: CSVNullToSpace(order.buyInfo.qty),
  address1: formatCSVString(order.customerInfo.address1),
  address2: formatCSVString(order.customerInfo.address2),
  address3: formatCSVString(order.customerInfo.address3),
  city: formatCSVString(order.customerInfo.city),
  state: formatCSVString(order.customerInfo.state),
  zipCode: formatCSVString(order.customerInfo.zipcode),
  country: formatCSVString(order.customerInfo.country),
});

const strCell = cellValue => `"${cellValue}"`;
const makeCSVOrderString = order => [
  strCell(order.orderID),
  strCell(order.trackingNumber),
  strCell(order.shippingCarrier),
  order.status,
  strCell(order.shipBy),
  strCell(order.notes),
  strCell(order.sku),
  strCell(order.upc),
  order.qty,
  strCell(order.address1),
  strCell(order.address2),
  strCell(order.address3),
  strCell(order.city),
  strCell(order.state),
  strCell(order.zipCode),
  strCell(order.country),
].join(',');

export const getOrderListCSV = (list, getStatusName) => list
  .map(makeOrderAggregator(getStatusName))
  .map(makeCSVOrderString)
  .join('\n');

export const getOrderListCSVwithHeader = (list, getStatusName) =>
  `sep=,\n${columnHeadersLine}\n${getOrderListCSV(list, getStatusName)}`;

const makeStatusNameGetter = options => (status) => {
  const statusOption = options.find(option => option.value === status);
  return statusOption ? statusOption.text : '';
};

const addStatusParam = (params, status) => {
  if (status >= 0) return { ...params, status };
  return { ...params, notStatus: -status };
};

export default function* downloadMarketCSVSaga({ marketplace, shipBy, status }) {
  const { marketplaceName, getStatusName, token } = yield select(({ meta, user }) => ({
    marketplaceName: meta.marketplaces.find(mrkt => mrkt.id === marketplace).text,
    getStatusName: makeStatusNameGetter(meta.orderStatuses),
    token: user.token,
  }));
  try {
    const orders = yield getOrders(addStatusParam({
      marketplace, shipBy, limit: 0,
    }, status), token);
    yield call(fileDownload,
      getOrderListCSVwithHeader(orders.data.list, getStatusName),
      `${withoutSpaces(marketplaceName)}_${formatFileDate(shipBy)}_${getFileType(status)}.csv`,
    );
    yield put(downloadMarketCSVSuccess());
  } catch (error) {
    yield put(downloadMarketCSVFailure(error));
  }
};
