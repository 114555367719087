import { put, call, select } from 'redux-saga/effects';
import { connectAndPrintZPL } from './print';

import {
  buyShipmentSuccess, buyShipmentFailure, updateShippedOrders,
} from '../../actions';
import { requestMaker } from '../../../services/settings';
import { orderStatus, getNewYorkTime, getExpectedWeightFromVolume } from '../../../services/helpers';
import saveBase64EncodedFile from './saveBase64EncodedFile';

const PARCEL_DENSITY = 1 / 139;
const WITH_SIZE_BASED_RATE_CALC = {
  carriers: [
    'FedEx',
  ],
  services: [
    'Priority',
  ],
};

const isServiceUsesVolumeWeightCalculation = rate =>
  WITH_SIZE_BASED_RATE_CALC.carriers.includes(rate.carrier)
  || WITH_SIZE_BASED_RATE_CALC.services.includes(rate.service);

const getRateByID = id => rate => rate.id === id;
const chosenOnly = order => order.chosen;

function selector({ shipment, productsToShip, user }) {
  const rate = shipment.rates.find(getRateByID(shipment.choice)) || {};
  return {
    rateID: shipment.choice,
    orders: productsToShip.list.filter(chosenOnly),
    orderID: shipment.orderID,
    shipmentNote: shipment.shipmentNote,
    returning: shipment.returning,
    token: user.token,
    rate,
  };
}

const formatAsCarrierServicePair = ({ carrier, service }) => `${carrier} ${service}`;


function getChangesIfOrderRequiresService(changes, order, shipmentNote) {
  let usedShipmentNote = null;
  if (changes.remainingQty > 0) {
    usedShipmentNote = `...Shipped ${order.qty - changes.remainingQty}, Owes ${changes.remainingQty}...`;
  }
  if (shipmentNote) usedShipmentNote = shipmentNote;
  if (usedShipmentNote) {
    return {
      shipQty: order.qty - changes.remainingQty,
      status: orderStatus.service,
      notes: `${order.notes || ''}, ${usedShipmentNote}`,
      shipNotes: usedShipmentNote,
    };
  }
  return {};
}

function getWeightChanges(order, parcelSize, rate){
  if (order.orderCount < 2 && order.qty < 2 && !order.denyWeightUpdate) {
    const expectedWeight = (isServiceUsesVolumeWeightCalculation(rate))
      ? getExpectedWeightFromVolume(parcelSize, PARCEL_DENSITY) : 0;
    return {
      weight: expectedWeight > order.shipWeight ? expectedWeight : order.shipWeight,
    };
  }
  return {};
}

// will be called for each selected order
function prepareChangesToOrders ({ orderID, rate, trackingNumber, shipmentID, shipmentNote, size, returning }) {
  const shipTime = getNewYorkTime();
  return (order, i, orderList) => {
    const {
      orderItemID, marketplaceID, qty,
      shipQty, remainingQty,
    } = order;
    const changes = {
      status: orderStatus.shipped,
      shipCharge: rate.rate,
      shippingCarrier: formatAsCarrierServicePair(rate),
      remainingQty: (remainingQty || qty) - shipQty,
      shippedItems: orderList.length,
      verified: 1,
      shipTime,
      trackingNumber,
      shipmentID,
      scanFormID: null,
    };
    let usedOrder = order;
    if (returning) {
      delete changes.trackingNumber;
      delete changes.shipmentID;
      changes.notes = `${order.notes || ''}, Return shipmentID: ${shipmentID}, tracking number: ${trackingNumber}`;
      usedOrder = {
        ...order,
        notes: changes.notes,
      };
    }
    return {
      orderID,
      orderItemID,
      marketplaceID,
      changes: {
        ...changes,
        ...getChangesIfOrderRequiresService(changes, usedOrder, shipmentNote),
        ...getWeightChanges(usedOrder, size, rate),
      },
    };
  };
}

const getIDsForShipOrder = orderID => ({ upc }) => ({ orderID, upc });

function* buyShipment(shipmentID, rateID, token) {
  const response = yield call(requestMaker, {
    headers: { Authorization: `JWT ${token}` },
    url: '/api/v1/shipping/buy/',
    method: 'post',
    data: {
      shipmentID,
      rateID,
    },
  });
  return response.data;
}

function* updateOrdersWithShipmentData(shipmentData){
  const orderChangesBulk = shipmentData.orders.map(prepareChangesToOrders(shipmentData));
  yield call(requestMaker, {
    headers: { Authorization: `JWT ${shipmentData.token}` },
    method: 'PATCH',
    url: '/api/v1/order-shipping/',
    data: { bulk: orderChangesBulk },
  });
}

function* removeShippedOrdersFromShipOrderList({ orderID, orders, token }){
  const shipOrderBulk = orders.map(getIDsForShipOrder(orderID));
  yield call(requestMaker, {
    headers: { Authorization: `JWT ${token}` },
    method: 'DELETE',
    url: '/api/v1/ship-order/',
    data: { bulk: shipOrderBulk },
  });
}

function* getFile(labelURL){
  const response = yield call(requestMaker, {
    url: '/api/v1/shipping/zpl/',
    method: 'GET',
    params: { labelURL },
  });
  return response.data;
}

function* getUpdatedOrders(orderID, token){
  const response = yield call(requestMaker, {
    headers: { Authorization: `JWT ${token}` },
    url: '/api/v1/order-shipping/',
    method: 'GET',
    params: { orderID },
  });
  return response.data.list;
}

const savePDF = () => {};

export default function* buyShipmentSaga({ size }) {
  const {
    rateID, orders, orderID, returning,
    rate, token, shipmentNote,
  } = yield select(selector);
  try {
    const { shipmentID } = rate;
    const {
      trackingNumber, labelURL, purpose, format
    } = yield call(buyShipment, shipmentID, rateID, token);
    yield call(updateOrdersWithShipmentData, {
      orders,
      orderID,
      rate,
      trackingNumber,
      shipmentID,
      shipmentNote,
      size,
      token,
      returning,
    });
    const updatedOrders = yield call(getUpdatedOrders, orderID, token);
    yield put(updateShippedOrders(updatedOrders));
    yield call(removeShippedOrdersFromShipOrderList, {
      orderID, orders, token,
    });
    // requesting zpl with given URL
    const rawData = yield call(getFile, labelURL);
    if (purpose === 'print' && format === 'ZPL') {
      yield call(connectAndPrintZPL, rawData);
    }
    if (purpose === 'save' && format === 'PDF') {
      yield call(
        saveBase64EncodedFile,
        rawData,
        `${orderID}-RETURN.pdf`,
      );
    }
    yield put(buyShipmentSuccess());
  } catch (error) {
    yield put(buyShipmentFailure(error));
  }
};
