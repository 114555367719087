import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/Card.css';
import './CardHiding.css';

function CardOpinion({ data, hide }) {
  return (
    <div className={`card card-hiding ${hide ? 'card-hiding_hiding' : ''}`}>
      {hide ? '' : data}
    </div>
  );
}

CardOpinion.propTypes = {
  data: PropTypes.string,
  hide: PropTypes.bool,
};
CardOpinion.defaultProps = {
  data: '',
  hide: false,
};

export default CardOpinion;