import { connect } from 'react-redux';
import { changeOrderBulk, getOrders } from '../../../redux/actions';
import PopupOrderBulkEdit from './PopupOrderBulkEdit';

const mapStateToProps = ({ meta }) => ({
  statusOptions: meta.orderStatuses,
});

const mapDispatchToProps = (dispatch, props) => ({
  submit: changes =>
    dispatch(changeOrderBulk(changes, () => props.changeSearch())),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupOrderBulkEdit);