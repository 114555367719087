export const shippingServiceNames = {
  usps: {
    priority: 'Priority',
    //first: 'First',
    GroundAdvantage: 'GroundAdvantage',
  },
  fedExSmartPost: {
    smartPost: 'SMART_POST',
  },
  fedEx: {
    ground: 'FEDEX_GROUND',
    groundHomeDelivery: 'GROUND_HOME_DELIVERY',
  },
  globegistics: {
    pmi: 'PMI',
    eComPacket: 'eComPacket',
    eComPacketDDP: 'eComPacketDDP',
    eComTrackedDDP: 'eComTrackedDDP',
    eComPMIDutyPaid: 'eComPMIDutyPaid',
  },
  asendia: {
    ePAQSelect:'ePAQSelect',
    ePAQSelectDDP:'ePAQSelectDDP',
    ePAQPlusePacketCanadaDDP:'ePAQPlusePacketCanadaDDP',
    ePAQPlus:'ePAQPlus',
    ePAQSelectPMICanadaDDP:'ePAQSelectPMICanadaDDP',
  },
};

export const availableShippingServices = {
  local: {
    common: [
      shippingServiceNames.usps.priority,
      shippingServiceNames.fedExSmartPost.smartPost,
      shippingServiceNames.fedEx.ground,
    ],
    lightWeight: [
      //shippingServiceNames.usps.first,
      shippingServiceNames.usps.GroundAdvantage,
    ],
    higherWeight: [
      shippingServiceNames.fedEx.groundHomeDelivery,
    ],
  },
  international: {
    common: [
      /*shippingServiceNames.globegistics.eComPacket,
      shippingServiceNames.globegistics.pmi,*/
      shippingServiceNames.asendia.ePAQPlus,
      shippingServiceNames.asendia.ePAQSelect,
    ],
    DDP: [
      /*shippingServiceNames.globegistics.eComTrackedDDP,
      shippingServiceNames.globegistics.eComPacketDDP,
      shippingServiceNames.globegistics.eComPMIDutyPaid,*/
      shippingServiceNames.asendia.ePAQPlusePacketCanadaDDP,
      shippingServiceNames.asendia.ePAQSelectDDP,
      shippingServiceNames.asendia.ePAQSelectPMICanadaDDP,
    ],
  },
};

export const suggestedShippingServices = [
  shippingServiceNames.fedEx.groundHomeDelivery,
  shippingServiceNames.fedEx.ground,
];

export const nonResidential = [
  shippingServiceNames.fedEx.ground,
];

export const GROUND_DELIVERY_PREFERENCE_VALUE_THRESHOLD = 60;

export const makeAvailableRatesListGetter = services => (international, heavy, isFood) => {
  if (international) {
    const { common, DDP } = services.international;
      if (!isFood)
        return [...common, ...DDP];
    return common;
  }
  const { lightWeight, higherWeight, common } = services.local;
  if (heavy) return [...common, ...lightWeight, ...higherWeight ];
  return [...common, ...lightWeight];
};

export const getAvailableRatesList = makeAvailableRatesListGetter(availableShippingServices);

export const makeRateFilter = (international, heavy, isFood, isResidential) => rate =>
  getAvailableRatesList(international, heavy, isFood).includes(rate.service) && (!isResidential || !nonResidential.includes(rate.service));
