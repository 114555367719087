import React from 'react';
import PropTypes from 'prop-types';

import RowTableOrders from './RowTableOrders';
import HeaderTableOrders from './HeaderTableOrders';
import './TableOrders.css';
import { makeOrderHash } from '../../../services/helpers';

class TableOrders extends React.PureComponent {
  renderRowTableOrders= (order, i) => (
    <RowTableOrders
      key={makeOrderHash(order.unique)}
      order={order}
      orderNumber={i}
      setOrderField={this.props.setOrderField}
    />
  );
  render(){
    const { products, loading, changeSearch } = this.props;
    return (
      <div className="working-area__table-products">
        <table className="table-products">
          <tbody>
            <HeaderTableOrders changeSearch={changeSearch} />
            {!loading && products.map(this.renderRowTableOrders)}
          </tbody>
        </table>
      </div>
    );
  }
}


TableOrders.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  changeSearch: PropTypes.func,
  setOrderField: PropTypes.func,
};

TableOrders.defaultProps = {
  products: [],
  loading: false,
  changeSearch: null,
  setOrderField: () => null,
};

export default TableOrders;