import { loaderTriggers, loaderResolvers } from '../actions';

const initialLoaders = {};



export default function loadersReducer(loaders = initialLoaders, { type, loaderId }) {
  if (loaderTriggers.includes(type)) return { ...loaders, [loaderId]: true };
  if (loaderResolvers.includes(type)) {
    const { [loaderId]: trash, ...newLoaders } = loaders;
    return newLoaders;
  }
  return loaders;
}