import React from 'react';
import { Button, Form, Header, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { v4 as getID } from 'uuid';
import { getDepartmentData } from '../../../services/settings';

import './LoginPage.css';

const formatHeader = header => header === 'non_field_errors'
  ? ''
  : header.split('_').join(' ');

const formatMessage = (message) => {
  if (message instanceof Array) return message.join('\n');
  return message;
};

const fieldsEmpty = ({ username, password }) => !username || !password;

const getErrors = errorObj => Object.keys(errorObj)
  .map(error => ({
    header: formatHeader(error),
    description: formatMessage(errorObj[error]),
    key: getID(),
  }));

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }
  componentWillMount() {
    this.redirectIfLogged();
  }
  componentDidUpdate() {
    this.redirectIfLogged();
  }
  componentWillUnmount() {
    this.props.hideError();
  }
  redirectIfLogged() {
    let destination = '/notFound';
    const department = getDepartmentData(this.props.department);
    if (department) {
      destination = (department.allowedPages === 'all')
        ? '/system'
        : department.allowedPages[0];
    }
    if (this.props.logged) this.props.history.push(destination);
  }
  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
    if (this.props.error) this.props.hideError();
  };
  handleSubmit = () => {
    const { username, password } = this.state;
    this.props.logIn(username, password);
  };

  render() {
    return (
      <div className="login-wrapper">
        <div className="login-form">
          <Form onSubmit={this.handleSubmit}>
            <Header size="medium">Login</Header>
            <Form.Field
              label="Username"
              name="username"
              control="input"
              type="text"
              onChange={this.handleChange}
            />
            <Form.Field
              label="Password"
              name="password"
              control="input"
              type="password"
              onChange={this.handleChange}
            />
            <Button
              disabled={fieldsEmpty(this.state)}
            >
              Submit
            </Button>
            {
              this.props.error &&
              <Label pointing="left" color="red" onClick={this.props.hideError}>
                {this.props.error}
              </Label>
            }
          </Form>
        </div>
      </div>
    );
  }
}


LoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  logIn: PropTypes.func,
  hideError: PropTypes.func,
  department: PropTypes.string,
  logged: PropTypes.bool,
  error: PropTypes.string,
};

LoginPage.defaultProps = {
  department: '',
  history: { push: () => {} },
  logIn: () => {},
  hideError: () => {},
  logged: false,
  error: null,
};

export default LoginPage;
