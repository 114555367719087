import React from 'react';
import PropTypes from 'prop-types';
import { makeValidatorDecimalWithRegEx } from '../../../../services/validators';

import PanelLabeled from './PanelLabeled';
import renderInputs from './renderInputs';

const validateWeight = makeValidatorDecimalWithRegEx(6, 2);

export default function PanelOptional(props) {
  const { makeChangeHandler, ...values } = props;
  const inputs = [
    { fieldName: 'brand', label: 'Brand', double: true },
    { fieldName: 'name', label: 'Item Title', double: true },
    { fieldName: 'description', label: 'Description', double: true },
    { fieldName: 'weight', label: 'Weight', validate: validateWeight },
    { fieldName: 'size', label: 'Size', double: true },
  ];
  return (
    <PanelLabeled label="optional">
      <div className="panel-labeled__grid">
        {renderInputs(inputs, makeChangeHandler, values)}
      </div>
    </PanelLabeled>
  );
}

const PTNumOrStr = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

PanelOptional.propTypes = {
  makeChangeHandler: PropTypes.func,
  name: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  weight: PTNumOrStr.isRequired,
  size: PropTypes.string.isRequired,
};

PanelOptional.defaultProps = {
  makeChangeHandler: field => (event, data) => null,
};
