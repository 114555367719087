import {
  CHANGE_DROPDOWN_VALUE,
  CHANGE_INPUT_VALUE,
  changeDropDownValue,
  changeInputValue,
} from '../actions';


export const actions = {
  changeInputValue,
  changeDropDownValue,
};

const ACTION_HANDLERS = {
  [CHANGE_INPUT_VALUE]: (state, action) => ({ ...state, [action.key]: action.value }),
  [CHANGE_DROPDOWN_VALUE]: (state, action) => ({ ...state, [action.key]: action.value }),
};

const initialState = {
  id: '',
  info: '',
  brand: '',
  distributor: '',
  marketplace: '',
};

export default function counterReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
