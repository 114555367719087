export const UPDATE_PRODUCT_LIST = 'UPDATE_PRODUCT_LIST';
export const GET_PRODUCTS_LIST = 'GET_PRODUCTS_LIST';

export function getProductsList(offset) {
  return {
    type: GET_PRODUCTS_LIST,
    offset,
  };
}

export function updateProductList(searchQuery) {
  return {
    type: UPDATE_PRODUCT_LIST,
    searchQuery,
  };
}

export const UPDATE_PRODUCT_LIST_SUCCESS = 'UPDATE_PRODUCT_LIST_SUCCESS';

export function updateProductListSuccess(productList, count) {
  return {
    type: UPDATE_PRODUCT_LIST_SUCCESS,
    productList,
    count: count == null ? productList.length : count,
  };
}

export const UPDATE_PRODUCT_LIST_FAILURE = 'UPDATE_PRODUCT_LIST_FAILURE';

export function updateProductListFailure(error) {
  return {
    type: UPDATE_PRODUCT_LIST_FAILURE,
    error,
  };
}

export const LOG_IN = 'LOG_IN';

export function logIn(username, password) {
  return {
    type: LOG_IN,
    username,
    password,
  };
}

export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';

export function logInSuccess({ username, token, department, image_url }) {
  return {
    type: LOG_IN_SUCCESS,
    username,
    token,
    department,
    image_url,
  };
}

export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';

export function logInFailure(error) {
  return {
    type: LOG_IN_FAILURE,
    error,
  };
}


export const LOG_OUT = 'LOG_OUT';

export function logOut() {
  return {
    type: LOG_OUT,
  };
}

export const LOAD_STATE = 'LOAD_STATE';

export function loadState() {
  return {
    type: LOAD_STATE,
  };
}

export const LOAD_STATE_SUCCESS = 'LOAD_STATE_SUCCESS';


export function loadStateSuccess(data) {
  return {
    type: LOAD_STATE_SUCCESS,
    data,
  };
}

export const CHANGE_INPUT_VALUE = 'CHANGE_INPUT_VALUE';
export const CHANGE_DROPDOWN_VALUE = 'CHANGE_DROPDOWN_VALUE';

export const changeInputValue = (key, e) => {
  return {
    type: CHANGE_INPUT_VALUE,
    value: e.target.value,
    key,
  };
};
export const changeDropDownValue = (key, value) => {
  return {
    type: CHANGE_DROPDOWN_VALUE,
    value,
    key,
  };
};

export const FINISH_REQUEST = 'FINISH_REQUEST';
export const START_REQUEST = 'START_REQUEST';
export const CHANGE_OFFSET = 'CHANGE_OFFSET';
export const GET_METADATA = 'GET_METADATA';
export const SAVE_METADATA = 'SAVE_METADATA';
export const UPDATE_FIELED = 'UPDATE_FIELED';
export const UPDATE_FILED_IN_ARRAY = 'UPDATE_FILED_IN_ARRAY';

export function changeOffset(offset) {
  return {
    type: CHANGE_OFFSET,
    offset,
  };
}

export function getMetadata() {
  return {
    type: GET_METADATA,
  };
}
export function saveMetadata(meta) {
  return {
    type: SAVE_METADATA,
    meta,
  };
}


export function updateField(body, productNumber, productId, loaderId) {
  return {
    type: UPDATE_FIELED,
    body,
    productNumber,
    productId,
    loaderId,
  };
}

export function updateSwitch(body, productNumber, productId, loaderId) {
  return {
    type: UPDATE_FIELED,
    body,
    productNumber,
    productId,
    loaderId,
  };
}

export const UPDATE_FIELD_FAILURE = 'UPDATE_FIELD_FAILURE';

export function updateFieldFailure(error, loaderId) {
  return {
    type: UPDATE_FIELD_FAILURE,
    loaderId,
    error,
  };
}

export const CHANGE_ORDER_SEARCH = 'CHANGE_ORDER_SEARCH';

export function changeOrderSearch(params) {
  return {
    type: CHANGE_ORDER_SEARCH,
    params,
  };
}

export const CHANGE_ORDER_SEARCH_FOR_DISTR_PAGE = 'CHANGE_ORDER_SEARCH_FOR_DISTR_PAGE';

export function changeOrderSearchForDistrPage() {
  return {
    type: CHANGE_ORDER_SEARCH_FOR_DISTR_PAGE,
  };
}

export const GET_ORDERS = 'GET_ORDERS';

export function getOrders() {
  return {
    type: GET_ORDERS,
  };
}

export const GET_ORDERS_FOR_DISTR_PAGE = 'GET_ORDERS_FOR_DISTR_PAGE';

export function getOrdersForDistrPage() {
  return {
    type: GET_ORDERS_FOR_DISTR_PAGE,
  };
}

export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';

export function getOrdersSuccess(list, count) {
  return {
    type: GET_ORDERS_SUCCESS,
    list,
    count,
  };
}

export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';

export function getOrdersFailure(error) {
  return {
    type: GET_ORDERS_FAILURE,
    error,
  };
}

export const CHANGE_ORDER = 'CHANGE_ORDER';

export function changeOrder(ids, changes, loaderId) {
  return {
    type: CHANGE_ORDER,
    ids,
    changes,
    loaderId,
  };
}

export const CHANGE_ORDER_SUCCESS = 'CHANGE_ORDER_SUCCESS';

export function changeOrderSuccess(ids, order, loaderId) {
  return {
    type: CHANGE_ORDER_SUCCESS,
    loaderId,
    ids,
    order,
  };
}

export const CHANGE_ORDER_FAILURE = 'CHANGE_ORDER_FAILURE';

export function changeOrderFailure(error, loaderId) {
  return {
    type: CHANGE_ORDER_FAILURE,
    error,
    loaderId,
  };
}

export const CHOOSE_DISTRIBUTOR = 'CHOOSE_DISTRIBUTOR';

export function chooseDistributor(hash, choice) {
  return {
    type: CHOOSE_DISTRIBUTOR,
    hash,
    choice,
  };
}

export const SAVE_DISTRIBUTOR_CHOICES = 'SAVE_DISTRIBUTOR_CHOICES';

export function saveDistributorChoices() {
  return {
    type: SAVE_DISTRIBUTOR_CHOICES,
  };
}

export const actions = {
  changeOffset,
  getMetadata,
  updateField,
  updateSwitch,
};

export const SAVE_DISTRIBUTOR_CHOICES_SUCCESS = 'SAVE_DISTRIBUTOR_CHOICES_SUCCESS';

export function saveDistributorChoicesSuccess() {
  return {
    type: SAVE_DISTRIBUTOR_CHOICES_SUCCESS,
  };
}

export const SAVE_DISTRIBUTOR_CHOICES_FAILURE = 'SAVE_DISTRIBUTOR_CHOICES_FAILURE';

export function saveDistributorChoicesFailure(error) {
  return {
    type: SAVE_DISTRIBUTOR_CHOICES_FAILURE,
    error,
  };
}

export const DOWNLOAD_CSV = 'DOWNLOAD_CSV';

export function downloadCSV() {
  return {
    type: DOWNLOAD_CSV,
  };
}

export const DOWNLOAD_CSV_SUCCESS = 'DOWNLOAD_CSV_SUCCESS';

export function downloadCSVSuccess() {
  return {
    type: DOWNLOAD_CSV_SUCCESS,
  };
}

export const DOWNLOAD_CSV_FAILURE = 'DOWNLOAD_CSV_FAILURE';

export function downloadCSVFailure(error) {
  return {
    type: DOWNLOAD_CSV_FAILURE,
    error,
  };
}

export const SEND_TO_ARCHIVE = 'SEND_TO_ARCHIVE';
export const SEND_TO_ARCHIVE_SUCCESS = 'SEND_TO_ARCHIVE_SUCCESS';
export const SEND_TO_ARCHIVE_FAILURE = 'SEND_TO_ARCHIVE_FAILURE';

export function sendToArchive() {
  return {
    type: SEND_TO_ARCHIVE,
  };
}

export function sendToArchiveSuccess() {
  return {
    type: SEND_TO_ARCHIVE_SUCCESS,
  };
}

export function sendToArchiveFailure() {
  return {
    type: SEND_TO_ARCHIVE_FAILURE,
  };
}


export const REMOVE_DISTR_CHOICE = 'REMOVE_DISTR_CHOICE';

export function removeDistrChoice(hash) {
  return {
    type: REMOVE_DISTR_CHOICE,
    hash,
  };
}

export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export function getProductsFailure(error) {
  return {
    type: GET_PRODUCTS_FAILURE,
    error,
  };
}

export const RESET_ORDER_SEARCH = 'RESET_ORDER_SEARCH';

export function resetOrderSearch() {
  return {
    type: RESET_ORDER_SEARCH,
  };
}

export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE';

export function addProduct(product) {
  return {
    type: ADD_PRODUCT,
    product,
  };
}

export function addProductSuccess(product) {
  return {
    type: ADD_PRODUCT_SUCCESS,
    product,
  };
}

export function addProductFailure(error) {
  return {
    type: ADD_PRODUCT_FAILURE,
    error,
  };
}

export const HIDE_LOGIN_ERROR = 'HIDE_LOGIN_ERROR';

export function hideLoginError() {
  return {
    type: HIDE_LOGIN_ERROR,
  };
}

export const POPUP_GET_AVAILABLE_PRODUCTS = 'POPUP_GET_AVAILABLE_PRODUCTS';
export const POPUP_GET_AVAILABLE_PRODUCTS_SUCCESS = 'POPUP_GET_AVAILABLE_PRODUCTS_SUCCESS';
export const POPUP_GET_AVAILABLE_PRODUCTS_FAILURE = 'POPUP_GET_AVAILABLE_PRODUCTS_FAILURE';

export function popupGetAvailableProducts(sku, marketplace) {
  return {
    type: POPUP_GET_AVAILABLE_PRODUCTS,
    sku,
    marketplace,
  };
}

export function popupGetAvailableProductsSuccess(available, marketplaces) {
  return {
    type: POPUP_GET_AVAILABLE_PRODUCTS_SUCCESS,
    available,
    marketplaces,
  };
}

export function popupGetAvailableProductsFailure(error) {
  return {
    type: POPUP_GET_AVAILABLE_PRODUCTS_FAILURE,
    error,
  };
}

export const POPUP_SET_MARKETS_TABLE_CELL = 'POPUP_SET_MARKETS_TABLE_CELL';
export const POPUP_SET_MARKETS_TABLE_CELL_SUCCESS = 'POPUP_SET_MARKETS_TABLE_CELL_SUCCESS';
export const POPUP_SET_MARKETS_TABLE_CELL_FAILURE = 'POPUP_SET_MARKETS_TABLE_CELL_FAILURE';

export function popupSetMarketsTableCell(sku, marketplaceid, values) {
  return {
    type: POPUP_SET_MARKETS_TABLE_CELL,
    sku,
    marketplaceid,
    values,
  };
}

export function popupSetMarketsTableCellSuccess() {
  return {
    type: POPUP_SET_MARKETS_TABLE_CELL_SUCCESS,
  };
}

export function popupSetMarketsTableCellFailure(error) {
  return {
    type: POPUP_SET_MARKETS_TABLE_CELL_FAILURE,
    error,
  };
}

export const POPUP_SET_AVAIL_PRODS_TABLE_CELL = 'POPUP_SET_AVAIL_PRODS_TABLE_CELL';
export const POPUP_SET_AVAIL_PRODS_TABLE_CELL_SUCCESS = 'POPUP_SET_AVAIL_PRODS_TABLE_CELL_SUCCESS';
export const POPUP_SET_AVAIL_PRODS_TABLE_CELL_FAILURE = 'POPUP_SET_AVAIL_PRODS_TABLE_CELL_FAILURE';

export function popupSetAvailProdsTableCell(upc, distributorid, values) {
  return {
    type: POPUP_SET_AVAIL_PRODS_TABLE_CELL,
    upc,
    distributorid,
    values,
  };
}

export function popupSetAvailProdsTableCellSuccess() {
  return {
    type: POPUP_SET_AVAIL_PRODS_TABLE_CELL_SUCCESS,
  };
}

export function popupSetAvailProdsTableCellFailure(error) {
  return {
    type: POPUP_SET_AVAIL_PRODS_TABLE_CELL_FAILURE,
    error,
  };
}

export const POPUP_CLEAR = 'POPUP_CLEAR';

export function popupClear() {
  return {
    type: POPUP_CLEAR,
  };
}


export const CHANGE_PRODUCTS_BULK = 'CHANGE_PRODUCTS_BULK';
export const CHANGE_PRODUCTS_BULK_SUCCESS = 'CHANGE_PRODUCTS_BULK_SUCCESS';
export const CHANGE_PRODUCTS_BULK_FAILURE = 'CHANGE_PRODUCTS_BULK_FAILURE';

export function changeProductsBulk(changes, filters) {
  return {
    type: CHANGE_PRODUCTS_BULK,
    changes,
    filters,
  };
}

export function changeProductsBulkSuccess() {
  return {
    type: CHANGE_PRODUCTS_BULK_SUCCESS,
  };
}

export function changeProductsBulkFailure(error) {
  return {
    type: CHANGE_PRODUCTS_BULK_FAILURE,
    error,
  };
}


export const GET_PRODS_INVENTORY = 'GET_PRODS_INVENTORY';
export const GET_PRODS_INVENTORY_SUCCESS = 'GET_PRODS_INVENTORY_SUCCESS';
export const GET_PRODS_INVENTORY_FAILURE = 'GET_PRODS_INVENTORY_FAILURE';

export function getProdsInventory() {
  return {
    type: GET_PRODS_INVENTORY,
  };
}

export function getProdsInventorySuccess(list, count) {
  return {
    type: GET_PRODS_INVENTORY_SUCCESS,
    list,
    count,
  };
}

export function getProdsInventoryFailure(error) {
  return {
    type: GET_PRODS_INVENTORY_FAILURE,
    error,
  };
}

export const CHANGE_INVENTORY_SEARCH = 'CHANGE_INVENTORY_SEARCH';

export function changeInventorySearch(params) {
  return {
    type: CHANGE_INVENTORY_SEARCH,
    params,
  };
}

export const CHANGE_PROD_INVENTORY = 'CHANGE_PROD_INVENTORY';
export const CHANGE_PROD_INVENTORY_SUCCESS = 'CHANGE_PROD_INVENTORY_SUCCESS';
export const CHANGE_PROD_INVENTORY_FAILURE = 'CHANGE_PROD_INVENTORY_FAILURE';

export function changeProdInventory(upc, changes, loaderId) {
  return {
    type: CHANGE_PROD_INVENTORY,
    upc,
    changes,
    loaderId,
  };
}

export function changeProdInventorySuccess(upc, product, loaderId) {
  return {
    type: CHANGE_PROD_INVENTORY_SUCCESS,
    upc,
    product,
    loaderId,
  };
}

export function changeProdInventoryFailure(error, loaderId) {
  return {
    type: CHANGE_PROD_INVENTORY_FAILURE,
    error,
    loaderId,
  };
}

export const INSERT_PROD_INVENTORY = 'INSERT_PROD_INVENTORY';
export const INSERT_PROD_INVENTORY_SUCCESS = 'INSERT_PROD_INVENTORY_SUCCESS';
export const INSERT_PROD_INVENTORY_FAILURE = 'INSERT_PROD_INVENTORY_FAILURE';

export function insertProdInventory(product, loaderId) {
  return {
    type: INSERT_PROD_INVENTORY,
    product,
    loaderId,
  };
}

export function insertProdInventorySuccess(loaderId) {
  return {
    type: INSERT_PROD_INVENTORY_SUCCESS,
    loaderId,
  };
}

export function insertProdInventoryFailure(error, loaderId) {
  return {
    type: INSERT_PROD_INVENTORY_FAILURE,
    error,
    loaderId,
  };
}

export const DELETE_PROD_INVENTORY = 'DELETE_PROD_INVENTORY';
export const DELETE_PROD_INVENTORY_SUCCESS = 'DELETE_PROD_INVENTORY_SUCCESS';
export const DELETE_PROD_INVENTORY_FAILURE = 'DELETE_PROD_INVENTORY_FAILURE';

export function deleteProdInventory(upc, loaderId) {
  return {
    type: DELETE_PROD_INVENTORY,
    upc,
    loaderId,
  };
}

export function deleteProdInventorySuccess(upc, loaderId) {
  return {
    type: DELETE_PROD_INVENTORY_SUCCESS,
    upc,
    loaderId,
  };
}

export function deleteProdInventoryFailure(error, loaderId) {
  return {
    type: DELETE_PROD_INVENTORY_FAILURE,
    error,
    loaderId,
  };
}

export const GET_MARKETPLACE_STATS = 'GET_MARKETPLACE_STATS';
export const GET_MARKETPLACE_STATS_SUCCESS = 'GET_MARKETPLACE_STATS_SUCCESS';
export const GET_MARKETPLACE_STATS_FAILURE = 'GET_MARKETPLACE_STATS_FAILURE';

export function getMarketplaceStats(shipDate) {
  return {
    type: GET_MARKETPLACE_STATS,
    shipDate,
  };
}

export function getMarketplaceStatsSuccess(marketStats) {
  return {
    type: GET_MARKETPLACE_STATS_SUCCESS,
    marketStats,
  };
}

export function getMarketplaceStatsFailure(error) {
  return {
    type: GET_MARKETPLACE_STATS_FAILURE,
    error,
  };
}

export const CHANGE_SHIP_DATE_SYSTEM_PAGE = 'CHANGE_SHIP_DATE_SYSTEM_PAGE';

export function changeShipDateSystemPage(shipDate) {
  return {
    type: CHANGE_SHIP_DATE_SYSTEM_PAGE,
    shipDate,
  };
}

export const GET_DISTR_STATS = 'GET_DISTR_STATS';
export const GET_DISTR_STATS_SUCCESS = 'GET_DISTR_STATS_SUCCESS';
export const GET_DISTR_STATS_FAILURE = 'GET_DISTR_STATS_FAILURE';

export function getDistrStats() {
  return {
    type: GET_DISTR_STATS,
  };
}

export function getDistrStatsSuccess(distrStats, totalProducts) {
  return {
    type: GET_DISTR_STATS_SUCCESS,
    distrStats,
    totalProducts,
  };
}

export function getDistrStatsFailure(error) {
  return {
    type: GET_DISTR_STATS_FAILURE,
    error,
  };
}

export const DOWNLOAD_MARKET_CSV = 'DOWNLOAD_MARKET_CSV';
export const DOWNLOAD_MARKET_CSV_SUCCESS = 'DOWNLOAD_MARKET_CSV_SUCCESS';
export const DOWNLOAD_MARKET_CSV_FAILURE = 'DOWNLOAD_MARKET_CSV_FAILURE';

export function downloadMarketCSV(marketplace, shipBy, status) {
  return {
    type: DOWNLOAD_MARKET_CSV,
    marketplace,
    shipBy,
    status,
  };
}

export function downloadMarketCSVSuccess() {
  return {
    type: DOWNLOAD_MARKET_CSV_SUCCESS,
  };
}

export function downloadMarketCSVFailure(error) {
  return {
    type: DOWNLOAD_MARKET_CSV_FAILURE,
    error,
  };
}

export const CHANGE_PAGE_PRODUCTS = 'CHANGE_PAGE_PRODUCTS';

export function changePageProducts(page) {
  return {
    type: CHANGE_PAGE_PRODUCTS,
    page,
  };
}

export const CHANGE_LIMIT_PRODUCTS = 'CHANGE_LIMIT_PRODUCTS';

export function changeLimitProducts(limit) {
  return {
    type: CHANGE_LIMIT_PRODUCTS,
    limit,
  };
}

export const CHANGE_PAGE_ORDERS = 'CHANGE_PAGE_ORDERS';

export function changePageOrders(page) {
  return {
    type: CHANGE_PAGE_ORDERS,
    page,
  };
}

export const CHANGE_PAGE_INVENTORY = 'CHANGE_PAGE_INVENTORY';

export function changePageInventory(page) {
  return {
    type: CHANGE_PAGE_INVENTORY,
    page,
  };
}

export const CHANGE_LIMIT_ORDERS = 'CHANGE_LIMIT_ORDERS';

export function changeLimitOrders(limit) {
  return {
    type: CHANGE_LIMIT_ORDERS,
    limit,
  };
}

export const CHANGE_LIMIT_INVENTORY = 'CHANGE_LIMIT_INVENTORY';

export function changeLimitInventory(limit) {
  return {
    type: CHANGE_LIMIT_INVENTORY,
    limit,
  };
}

export const FLAG_AS_ALLOWED = 'FLAG_AS_ALLOWED';

export function flagAsAllowed() {
  return {
    type: FLAG_AS_ALLOWED,
  };
}

export const FLAG_AS_FORBIDDEN = 'FLAG_AS_FORBIDDEN';

export function flagAsForbidden() {
  return {
    type: FLAG_AS_FORBIDDEN,
  };
}

export const GET_SHIPPING_RATES = 'GET_SHIPPING_RATES';
export const GET_SHIPPING_RATES_SUCCESS = 'GET_SHIPPING_RATES_SUCCESS';
export const GET_SHIPPING_RATES_FAILURE = 'GET_SHIPPING_RATES_FAILURE';

export const CHOOSE_SHIPPING_SERVICE = 'CHOOSE_SHIPPING_SERVICE';

export function chooseShippingService(choice) {
  return {
    type: CHOOSE_SHIPPING_SERVICE,
    choice,
  };
}

export function getShippingRates(ids, sizes, returning, selectedPackages) {
  return {
    type: GET_SHIPPING_RATES,
    ids,
    sizes,
    returning,
    selectedPackages,
  };
}

export function getShippingRatesSuccess(shipment) {
  return {
    type: GET_SHIPPING_RATES_SUCCESS,
    shipment,
  };
}

export function getShippingRatesFailure(error) {
  return {
    type: GET_SHIPPING_RATES_FAILURE,
    error,
  };
}

export const CLEAR_SHIPPING_RATES = 'CLEAR_SHIPPING_RATES';

export function clearShippingRates() {
  return {
    type: CLEAR_SHIPPING_RATES,
  };
}

export const GET_ORDERS_WITH_SHIPPING_INFO = 'GET_ORDERS_WITH_SHIPPING_INFO';
export const GET_ORDERS_WITH_SHIPPING_INFO_SUCCESS = 'GET_ORDERS_WITH_SHIPPING_INFO_SUCCESS';
export const GET_ORDERS_WITH_SHIPPING_INFO_FAILURE = 'GET_ORDERS_WITH_SHIPPING_INFO_FAILURE';

export function getOrdersWithShippingInfo() {
  return {
    type: GET_ORDERS_WITH_SHIPPING_INFO,
  };
}

export function getOrdersWithShippingInfoSuccess(list, count) {
  return {
    type: GET_ORDERS_WITH_SHIPPING_INFO_SUCCESS,
    list,
    count,
  };
}

export function getOrdersWithShippingInfoFailure(error) {
  return {
    type: GET_ORDERS_WITH_SHIPPING_INFO_FAILURE,
    error,
  };
}

export const CHANGE_ORDER_WITH_SHIPPING_SEARCH = 'CHANGE_ORDER_WITH_SHIPPING_SEARCH';

export function changeOrderWithShippingSearch(params) {
  return {
    type: CHANGE_ORDER_WITH_SHIPPING_SEARCH,
    params,
  };
}

export const CHANGE_PAGE_SHIPPING = 'CHANGE_PAGE_SHIPPING';

export function changePageShipping(page) {
  return {
    type: CHANGE_PAGE_SHIPPING,
    page,
  };
}

export const CHANGE_LIMIT_SHIPPING = 'CHANGE_LIMIT_SHIPPING';

export function changeLimitShipping(limit) {
  return {
    type: CHANGE_LIMIT_SHIPPING,
    limit,
  };
}

export const CHANGE_ORDER_WITH_SHIPPING_INFO = 'CHANGE_ORDER_WITH_SHIPPING_INFO';
export const CHANGE_ORDER_WITH_SHIPPING_INFO_SUCCESS = 'CHANGE_ORDER_WITH_SHIPPING_INFO_SUCCESS';
export const CHANGE_ORDER_WITH_SHIPPING_INFO_FAILURE = 'CHANGE_ORDER_WITH_SHIPPING_INFO_FAILURE';

export function changeOrderWithShippingInfo(ids, changes, loaderId) {
  return {
    type: CHANGE_ORDER_WITH_SHIPPING_INFO,
    ids,
    changes,
    loaderId,
  };
}

export function changeOrderWithShippingInfoSuccess(ids, order, loaderId) {
  return {
    type: CHANGE_ORDER_WITH_SHIPPING_INFO_SUCCESS,
    loaderId,
    ids,
    order,
  };
}

export function changeOrderWithShippingInfoFailure(error, loaderId) {
  return {
    type: CHANGE_ORDER_WITH_SHIPPING_INFO_FAILURE,
    error,
    loaderId,
  };
}

export const BUY_SHIPMENT = 'BUY_SHIPMENT';
export const BUY_SHIPMENT_SUCCESS = 'BUY_SHIPMENT_SUCCESS';
export const BUY_SHIPMENT_FAILURE = 'BUY_SHIPMENT_FAILURE';

export function buyShipment(size) {
  return {
    type: BUY_SHIPMENT,
    size,
  };
}

export function buyShipmentSuccess(labelURL) {
  return {
    type: BUY_SHIPMENT_SUCCESS,
    labelURL,
  };
}

export function buyShipmentFailure(error) {
  return {
    type: BUY_SHIPMENT_FAILURE,
    error,
  };
}

export const GET_PRODUCTS_TO_SHIP = 'GET_PRODUCTS_TO_SHIP';
export const GET_PRODUCTS_TO_SHIP_SUCCESS = 'GET_PRODUCTS_TO_SHIP_SUCCESS';
export const GET_PRODUCTS_TO_SHIP_FAILURE = 'GET_PRODUCTS_TO_SHIP_FAILURE';

export function getProductsToShip(orderID) {
  return {
    type: GET_PRODUCTS_TO_SHIP,
    orderID,
  };
}

export function getProductsToShipSuccess(list) {
  return {
    type: GET_PRODUCTS_TO_SHIP_SUCCESS,
    list,
  };
}

export function getProductsToShipFailure(error) {
  return {
    type: GET_PRODUCTS_TO_SHIP_FAILURE,
    error,
  };
}

export const CHOOSE_PRODUCT_TO_SHIP = 'CHOOSE_PRODUCT_TO_SHIP';

export function chooseProductToShip(sku) {
  return {
    type: CHOOSE_PRODUCT_TO_SHIP,
    sku,
  };
}

export const CLEAR_PRODUCTS_TO_SHIP = 'CLEAR_PRODUCTS_TO_SHIP';

export function clearProductsToShip() {
  return {
    type: CLEAR_PRODUCTS_TO_SHIP,
  };
}

export const CHANGE_PRODUCT_TO_SHIP = 'CHANGE_PRODUCT_TO_SHIP';

export function changeProductToShip(sku, changes) {
  return {
    type: CHANGE_PRODUCT_TO_SHIP,
    sku,
    changes,
  };
}

export const REPRINT_LABEL = 'REPRINT_LABEL';
export const REPRINT_LABEL_SUCCESS = 'REPRINT_LABEL_SUCCESS';
export const REPRINT_LABEL_FAILURE = 'REPRINT_LABEL_FAILURE';

export function reprintLabel(shipmentID) {
  return {
    type: REPRINT_LABEL,
    shipmentID,
  };
}

export function reprintLabelSuccess() {
  return {
    type: REPRINT_LABEL_SUCCESS,
  };
}

export function reprintLabelFailure(error) {
  return {
    type: REPRINT_LABEL_FAILURE,
    error,
  };
}

export const CHANGE_ORDER_BULK = 'CHANGE_ORDER_BULK';
export const CHANGE_ORDER_BULK_SUCCESS = 'CHANGE_ORDER_BULK_SUCCESS';
export const CHANGE_ORDER_BULK_FAILURE = 'CHANGE_ORDER_BULK_FAILURE';

export function changeOrderBulk(changes, update) {
  return {
    type: CHANGE_ORDER_BULK,
    changes,
    update,
  };
}

export function changeOrderBulkSuccess() {
  return {
    type: CHANGE_ORDER_BULK_SUCCESS,
  };
}

export function changeOrderBulkFailure(error) {
  return {
    type: CHANGE_ORDER_BULK_FAILURE,
    error,
  };
}

export const HIDE_REPORTS_MODAL = 'HIDE_REPORTS_MODAL';

export function hideReportsModal() {
  return {
    type: HIDE_REPORTS_MODAL,
  };
}

export const SHOW_REPORTS_MODAL = 'SHOW_REPORTS_MODAL';

export function showReportsModal() {
  return {
    type: SHOW_REPORTS_MODAL,
  };
}

export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';

export function getReports(timerID) {
  return {
    type: GET_REPORTS,
    timerID,
  };
}

export function getReportsSuccess(urgent, lastReportTime) {
  return {
    type: GET_REPORTS_SUCCESS,
    urgent,
    lastReportTime,
  };
}

export function getReportsFailure(error) {
  return {
    type: GET_REPORTS_FAILURE,
    error,
  };
}

export const INIT_REPORT_TIMER = 'INIT_REPORT_TIMER';

export function initReportTimer() {
  return {
    type: INIT_REPORT_TIMER,
  };
}


export const STOP_REPORT_TIMER = 'STOP_REPORT_TIMER';

export function stopReportTimer() {
  return {
    type: STOP_REPORT_TIMER,
  };
}

export const CHANGE_PRODUCTS_BULK_WITHOUT_STORE = 'CHANGE_PRODUCTS_BULK_WITHOUT_STORE';
export const CHANGE_PRODUCTS_BULK_WITHOUT_STORE_SUCCESS = 'CHANGE_PRODUCTS_BULK_WITHOUT_STORE_SUCCESS';
export const CHANGE_PRODUCTS_BULK_WITHOUT_STORE_FAILURE = 'CHANGE_PRODUCTS_BULK_WITHOUT_STORE_FAILURE';

export function changeProductsBulkWithoutStore(filters, changes, update = ()=>{}, loaderId) {
  return {
    type: CHANGE_PRODUCTS_BULK_WITHOUT_STORE,
    filters,
    changes,
    update,
    loaderId,
  };
}

export function changeProductsBulkWithoutStoreSuccess() {
  return {
    type: CHANGE_PRODUCTS_BULK_WITHOUT_STORE_SUCCESS,
  };
}

export function changeProductsBulkWithoutStoreFailure(error) {
  return {
    type: CHANGE_PRODUCTS_BULK_WITHOUT_STORE_FAILURE,
    error,
  };
}

export const POPUP_CHANGE_PRODUCTS_BULK = 'POPUP_CHANGE_PRODUCTS_BULK';

export function popupChangeProductsBulk(filters, changes, update = ()=>{}, loaderId) {
  return {
    type: POPUP_CHANGE_PRODUCTS_BULK,
    filters,
    changes,
    update,
    loaderId,
  };
}


export const UPDATE_LOCAL_ORDER_SHIPPING = 'UPDATE_LOCAL_ORDER_SHIPPING';

export function updateLocalOrderShipping(ids, loaderId) {
  return {
    type: UPDATE_LOCAL_ORDER_SHIPPING,
    ids,
    loaderId,
  };
}

export const UPDATE_LOCAL_PRODUCT = 'UPDATE_LOCAL_PRODUCT';
export const UPDATE_LOCAL_PRODUCT_SUCCESS = 'UPDATE_LOCAL_PRODUCT_SUCCESS';
export const UPDATE_LOCAL_PRODUCT_FAILURE = 'UPDATE_LOCAL_PRODUCT_FAILURE';

export function updateLocalProduct(sku, loaderId) {
  return {
    type: UPDATE_LOCAL_PRODUCT,
    sku,
    loaderId,
  };
}

export function updateLocalProductSuccess(product, loaderId) {
  return {
    type: UPDATE_LOCAL_PRODUCT_SUCCESS,
    product,
    loaderId,
  };
}

export function updateLocalProductFailure(error, loaderId) {
  return {
    type: UPDATE_LOCAL_PRODUCT_FAILURE,
    error,
    loaderId,
  };
}

export const SET_SHIPMENT_NOTE = 'SET_SHIPMENT_NOTE';

export function setShipmentNote(shipmentNote) {
  return {
    type: SET_SHIPMENT_NOTE,
    shipmentNote,
  };
}

export const REFUND_SHIPMENT = 'REFUND_SHIPMENT';
export const REFUND_SHIPMENT_SUCCESS = 'REFUND_SHIPMENT_SUCCESS';
export const REFUND_SHIPMENT_FAILURE = 'REFUND_SHIPMENT_FAILURE';

export function refundShipment(shipmentID, loaderId) {
  return {
    type: REFUND_SHIPMENT,
    shipmentID,
    loaderId,
  };
}

export function refundShipmentSuccess(loaderId) {
  return {
    type: REFUND_SHIPMENT_SUCCESS,
    loaderId,
  };
}

export function refundShipmentFailure(error, loaderId) {
  return {
    type: REFUND_SHIPMENT_FAILURE,
    error,
    loaderId,
  };
}

export const PRINT_SCANFORM = 'PRINT_SCANFORM';
export const PRINT_SCANFORM_SUCCESS = 'PRINT_SCANFORM_SUCCESS';
export const PRINT_SCANFORM_FAILURE = 'PRINT_SCANFORM_FAILURE';

export function printScanform() {
  return {
    type: PRINT_SCANFORM,
  };
}

export function printScanformSuccess() {
  return {
    type: PRINT_SCANFORM_SUCCESS,
  };
}

export function printScanformFailure(error) {
  return {
    type: PRINT_SCANFORM_FAILURE,
    error,
  };
}

export const SET_SHIPMENT_RETURNING_FLAG = 'SET_SHIPMENT_RETURNING_FLAG';

export function setShipmentReturningFlag(returning) {
  return {
    type: SET_SHIPMENT_RETURNING_FLAG,
    returning,
  };
}

export const UPDATE_SHIPPED_ORDERS = 'UPDATE_SHIPPED_ORDERS';

export function updateShippedOrders(list) {
  return {
    type: UPDATE_SHIPPED_ORDERS,
    list,
  };
}

export const SET_SHIPMENT_SIGNATURE_REQUIRED_FLAG = 'SET_SHIPMENT_SIGNATURE_REQUIRED_FLAG';

export function setShipmentSignatureRequiredFlag(signatureRequired) {
  return {
    type: SET_SHIPMENT_SIGNATURE_REQUIRED_FLAG,
    signatureRequired,
  };
}

export const SET_PARCEL_SIZE = 'SET_PARCEL_SIZE';

export function setParcelSize(orderID, sizes) {
  return {
    type: SET_PARCEL_SIZE,
    orderID,
    sizes,
  };
}

export const REMOVE_PARCEL_SIZE = 'REMOVE_PARCEL_SIZE';

export function removeParcelSize(orderID) {
  return {
    type: REMOVE_PARCEL_SIZE,
    orderID,
  };
}

export const REMOVE_ALL_PARCEL_SIZES = 'REMOVE_ALL_PARCEL_SIZES';

export function removeAllParcelSizes() {
  return {
    type: REMOVE_ALL_PARCEL_SIZES,
  };
}


export const failureActions = [
  GET_REPORTS_FAILURE,
  GET_ORDERS_FAILURE,
  UPDATE_FIELD_FAILURE,
  UPDATE_PRODUCT_LIST_FAILURE,
  CHANGE_ORDER_FAILURE,
  DOWNLOAD_CSV_FAILURE,
  DOWNLOAD_MARKET_CSV_FAILURE,
  SAVE_DISTRIBUTOR_CHOICES_FAILURE,
  SEND_TO_ARCHIVE_FAILURE,
  GET_PRODUCTS_FAILURE,
  ADD_PRODUCT_FAILURE,
  LOG_IN_FAILURE,
  POPUP_GET_AVAILABLE_PRODUCTS_FAILURE,
  POPUP_SET_MARKETS_TABLE_CELL_FAILURE,
  POPUP_SET_AVAIL_PRODS_TABLE_CELL_FAILURE,
  CHANGE_PRODUCTS_BULK_FAILURE,
  GET_PRODS_INVENTORY_FAILURE,
  CHANGE_PROD_INVENTORY_FAILURE,
  INSERT_PROD_INVENTORY_FAILURE,
  DELETE_PROD_INVENTORY_FAILURE,
  GET_MARKETPLACE_STATS_FAILURE,
  GET_DISTR_STATS_FAILURE,
  GET_SHIPPING_RATES_FAILURE,
  GET_ORDERS_WITH_SHIPPING_INFO_FAILURE,
  CHANGE_ORDER_WITH_SHIPPING_INFO_FAILURE,
  GET_PRODUCTS_TO_SHIP_FAILURE,
  BUY_SHIPMENT_FAILURE,
  REPRINT_LABEL_FAILURE,
  CHANGE_ORDER_BULK_FAILURE,
  CHANGE_PRODUCTS_BULK_WITHOUT_STORE_FAILURE,
  UPDATE_LOCAL_PRODUCT_FAILURE,
  REFUND_SHIPMENT_FAILURE,
  PRINT_SCANFORM_FAILURE,
];


export const loaderTriggers = [
  UPDATE_FIELED,
  CHANGE_ORDER,
  CHANGE_PROD_INVENTORY,
  INSERT_PROD_INVENTORY,
  DELETE_PROD_INVENTORY,
  CHANGE_ORDER_WITH_SHIPPING_INFO,
  CHANGE_PRODUCTS_BULK_WITHOUT_STORE,
  REFUND_SHIPMENT,
];

export const loaderResolvers = [
  UPDATE_FIELD_FAILURE,
  UPDATE_FILED_IN_ARRAY,
  CHANGE_ORDER_SUCCESS,
  CHANGE_ORDER_FAILURE,
  CHANGE_PROD_INVENTORY_SUCCESS,
  CHANGE_PROD_INVENTORY_FAILURE,
  INSERT_PROD_INVENTORY_SUCCESS,
  INSERT_PROD_INVENTORY_FAILURE,
  DELETE_PROD_INVENTORY_SUCCESS,
  DELETE_PROD_INVENTORY_FAILURE,
  UPDATE_LOCAL_ORDER_SHIPPING,
  UPDATE_LOCAL_PRODUCT_SUCCESS,
  UPDATE_LOCAL_PRODUCT_FAILURE,
  CHANGE_ORDER_WITH_SHIPPING_INFO_SUCCESS,
  CHANGE_ORDER_WITH_SHIPPING_INFO_FAILURE,
  REFUND_SHIPMENT_SUCCESS,
  REFUND_SHIPMENT_FAILURE,
];
