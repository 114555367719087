import {
  CHOOSE_SHIPPING_SERVICE,
  GET_SHIPPING_RATES,
  GET_SHIPPING_RATES_SUCCESS,
  GET_SHIPPING_RATES_FAILURE, CLEAR_SHIPPING_RATES,
  BUY_SHIPMENT, BUY_SHIPMENT_SUCCESS, BUY_SHIPMENT_FAILURE,
  SET_SHIPMENT_NOTE,
  SET_SHIPMENT_RETURNING_FLAG, SET_SHIPMENT_SIGNATURE_REQUIRED_FLAG,
} from '../actions';

const initialShipment = {
  choice: '',
  loading: false,
  rates: [],
  labelURL: '',
  orderID: '',
  error: null,
  carrierMessages: [],
  shipmentNote: '',
  signatureRequired: false,
  returning: false,
};

const getErrorData = error => (
  error && error.response && error.response.data && error.response.data.error
) ? error.response.data.error : null;

export default function shipmentReducer(shipment = initialShipment, action){
  switch (action.type) {
    case SET_SHIPMENT_RETURNING_FLAG:
      return { ...shipment, returning: action.returning };
    case SET_SHIPMENT_SIGNATURE_REQUIRED_FLAG:
      return { ...shipment, signatureRequired: action.signatureRequired };
    case CHOOSE_SHIPPING_SERVICE:
      return { ...shipment, choice: action.choice };
    case GET_SHIPPING_RATES:
      return {
        ...shipment,
        loading: true,
        orderID: action.ids.orderID,
        returning: !!action.returning,
        error: null,
      };
    case SET_SHIPMENT_NOTE:
      return { ...shipment, shipmentNote: action.shipmentNote };
    case BUY_SHIPMENT:
      return { ...shipment, loading: true, error: null };
    case GET_SHIPPING_RATES_FAILURE:
      return { ...initialShipment, error: getErrorData(action.error) };
    case BUY_SHIPMENT_SUCCESS:
      return {
        ...shipment,
        labelURL: action.labelURL,
        loading: false,
        shipmentNote: '',
      };
    case BUY_SHIPMENT_FAILURE:
      return { ...shipment, error: getErrorData(action.error), loading: false };
    case GET_SHIPPING_RATES_SUCCESS:
      return {
        ...shipment, loading: false, ...action.shipment,
      };
    case CLEAR_SHIPPING_RATES:
      return initialShipment;
    default: return shipment;
  }
}