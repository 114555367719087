import { call } from 'redux-saga/effects';
import { getProductsToShipSuccess, getProductsToShipFailure } from '../../actions';
import authorizedRequest from '../authorizedRequest';
import { getOrderAsProductToShip } from '../../../services/serializers';

const mapData = ({ list }) => list.map(getOrderAsProductToShip);

export default function* getProductsToShipSaga({ orderID }) {
  yield call(authorizedRequest, {
    requestSettings: {
      url: '/api/v1/order-shipping/',
      params: { orderID },
    },
    successAction: getProductsToShipSuccess,
    failureAction: getProductsToShipFailure,
    mapData,
  });
};
