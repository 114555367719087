export { default as timeIntervals } from './timeIntervals';

export {
  config,
  requestMaker,
  getFullServerURI,
  APP_CONFIG,
  WITHHOLD_SEARCH_TIME,
  PAGINATION_OPTIONS,
  INTERVAL_GET_REPORTS,
  ADMIN_SITE_URL,
  AXIOS_TIMEOUT,
} from './constants';

export {
  availableShippingServices,
  shippingServiceNames,
  suggestedShippingServices,
  makeRateFilter,
  getAvailableRatesList,
  makeAvailableRatesListGetter,
  nonResidential,
  GROUND_DELIVERY_PREFERENCE_VALUE_THRESHOLD,
} from './shippingServices';

export const localStorageKey = 'Valo Market Persistent state';

export {
  default as departments,
  departmentNames,
  getDepartmentData,
  isPageAllowedForDepartment,
} from './departments';

export {
  ASK_SIGNATURE_ORDER_PRICE_THRESHOLD,
  SIGNATURE_BY_DEFAULT_ORDER_PRICE_THRESHOLD,
} from './limits';