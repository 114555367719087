const orderStatus = {
  open: 0,
  export: 1,
  reorders: 2,
  archive: 3,
  shipReady: 4,
  shipped: 5,
  service: 6,
  onHold: 7,
  asap: 8,
  cancelled: 9,
};

orderStatus.notShipped = -orderStatus.shipped;

export default orderStatus;
