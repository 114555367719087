import React from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonWithoutLoader } from 'semantic-ui-react';
import { connect } from 'react-redux';

import '../../../styles/Panel.css';
import './PanelInvProps.css';
import CardLabeled from '../../common/card_labeled/CardLabeled';
import { InputDisguised as InputHiddenWithoutLoader } from '../../common/input_disguised/InputDisguised';
import { YesNoSwitch as SwitchWithoutLoader } from '../../common/yes_no_switch/YesNoSwitch';
import {
  makeValidatorDecimalWithRegEx as makeValidatorDecimal,
  validateIntegerWithRegEx as validateInteger,
} from '../../../services/validators';
import WithLoader from '../../../containers/WithLoader';

const validateWeight = makeValidatorDecimal(6, 2);
const validatePrice = makeValidatorDecimal(7, 2);
const formatPrice = price => `$${price}`;

const InputHidden = WithLoader(InputHiddenWithoutLoader);
const Switch = WithLoader(SwitchWithoutLoader);
const Button = WithLoader(ButtonWithoutLoader);

export default function PanelInvProps({ properties, makeSubmitter, getLoaderID, handleClickDelete }) {
  const { weight, uom, size, unpublish, price, qty } = properties;
  return (
    <div className="panel panel_short panel-inv-props">
      <div className="panel__container panel-inv-props__container_weight">
        <CardLabeled label="weight">
          <InputHidden
            validate={validateWeight}
            value={weight}
            submit={makeSubmitter('weight')}
            loaderID={getLoaderID('weight')}
          />
        </CardLabeled>
      </div>
      <div className="panel__container panel-inv-props__container_size">
        <CardLabeled label="size" >
          <InputHidden
            value={size}
            submit={makeSubmitter('size')}
            loaderID={getLoaderID('size')}
          />
        </CardLabeled>
      </div>
      <div className="panel__container panel-inv-props__container_price">
        <CardLabeled label="price">
          <InputHidden
            validate={validatePrice}
            value={price}
            submit={makeSubmitter('price')}
            formatWhenHidden={formatPrice}
            loaderID={getLoaderID('price')}
          />
        </CardLabeled>
      </div>
      <div className="panel__container panel-inv-props__container_qty">
        <CardLabeled label="quantity">
          <InputHidden
            validate={validateInteger}
            value={qty}
            submit={makeSubmitter('qty')}
            loaderID={getLoaderID('qty')}
          />
        </CardLabeled>
      </div>
      <div className="panel__container panel-inv-props__container_uom">
        <CardLabeled label="uom">
          <InputHidden
            validate={validateInteger}
            value={uom}
            submit={makeSubmitter('uom')}
            loaderID={getLoaderID('uom')}
          />
        </CardLabeled>
      </div>
      <div className="panel__container panel-inv-props__container_unpublish">
        <CardLabeled label="unpublish">
          <Switch
            value={unpublish}
            submit={makeSubmitter('unpublish')}
            loaderID={getLoaderID('unpublish')}
          />
        </CardLabeled>
      </div>
      <div className="panel__container panel-inv-props__container_delete">
        <Button
          className="panel-inv-props__button"
          loaderID={getLoaderID('delete')}
          onClick={handleClickDelete}
        >
          DELETE
        </Button>
      </div>
    </div>
  );
}


PanelInvProps.propTypes = {
  getLoaderID: PropTypes.func,
  makeSubmitter: PropTypes.func,
  properties: PropTypes.shape({
    weight: PropTypes.number,
    uom: PropTypes.number,
    size: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    unpublish: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]),
    price: PropTypes.number,
    qty: PropTypes.number,
  }),
  handleClickDelete: PropTypes.func,
};

PanelInvProps.defaultProps = {
  getLoaderID: field => field,
  makeSubmitter: field => (event, data) => null,
  properties: {
    weight: 0,
    uom: 0,
    size: '0',
    unpublish: true,
    price: 0,
    qty: 0,
  },
  handleClickDelete: (event, data) => null,
};
