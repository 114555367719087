import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SearchInput from './InputProductSearch';
import DropDown from '../../common/dropdown/DropDown';
import './TableProducts.css';

const styles = {
  header: 'table-products__cell_head table-products__cell',
  headerFirst: 'table-products__cell_head table-products__cell table-products__column_first',
  input: 'table-products__input',
  inputContainer: 'table-products__input-container',
  inputContainerBrand: 'table-products__input-container_brand',
  search: 'table-products__search-bar  ',
  label: 'table-products__label',
  dropdown: 'table-products__dropdown',
  dropdownDistributor: 'table-products__dropdown table-products__dropdown_distributor',
  checkbox: 'table-products__checkbox',
};

const stylesIdInput = {
  inputContainer: 'table-products__input-container table-products__input-container_id',
  input: styles.input,
};


const mapStateToProps = state => ({
  marketplaces: state.meta.marketplaces,
  distributors: state.meta.distributors,
});

class HeaderTableProducts extends React.PureComponent {
  render() {
    const { distributors, marketplaces } = this.props;
    return (
      <tr>
        <th className={styles.header}>
          <div className={styles.search}>
            <SearchInput inputKey="id" placeHolder="Item ID..." styles={stylesIdInput} />
            <SearchInput inputKey="info" placeHolder="Item Info..." styles={styles} />
            <SearchInput inputKey="brand" placeHolder="Brand..." styles={styles} />
            <DropDown
              label="Market"
              placeholder="All markets"
              options={marketplaces}
              inputKey="marketplace"
              styles={styles}
            />
            <DropDown
              label="Distriburor"
              placeholder="All distributors"
              options={distributors}
              inputKey="distributor"
              styles={styles}
            />
          </div>
        </th>
      </tr>
    );
  }
}

HeaderTableProducts.propTypes = {
  marketplaces: PropTypes.arrayOf(PropTypes.object),
  distributors: PropTypes.arrayOf(PropTypes.object),
};

HeaderTableProducts.defaultProps = {
  marketplaces: [],
  distributors: [],
};

export default connect(mapStateToProps)(HeaderTableProducts);
