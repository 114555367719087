import { connect } from 'react-redux';
import Pagination from '../../layout/pagination/Pagination';
import { changeLimitProducts, changePageProducts } from '../../../redux/actions'

const mapStateToProps = ({ productList }) => ({
  count: productList.count,
  offset: productList.offset,
  limit: productList.limit,
  page: productList.page,
});

const mapDispatchToProps = dispatch => ({
  handlePageChange: activePage => dispatch(changePageProducts(activePage)),
  handleLimitChange: (event, { value }) => dispatch(changeLimitProducts(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);