import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonWithoutLoader } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { connect } from 'react-redux';

import PanelRequired from './panels/PanelRequired';
import PanelOptional from './panels/PanelOptional';
import './FormInsertProduct.css';
import { insertProdInventory } from '../../../redux/actions';
import WithLoader from '../../../containers/WithLoader';

const Button = WithLoader(ButtonWithoutLoader);

const insertButtonLoaderID = 'InventoryInsertButton';

const mapDispatchToProps = dispatch => ({
  submit: product => dispatch(insertProdInventory(product, insertButtonLoaderID)),
});

class FormInsertProduct extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      upc: '',
      distrProdID: '',
      name: '',
      brand: '',
      description: '',
      weight: '',
      uom: '',
      size: '',
      unpublish: 0,
      price: '',
      qty: '',
      invalidFields: [],
    };
  }
  requiredFieldIsEmpty = () => {
    const { upc, distrProdID, qty, uom, price } = this.state;
    return [upc, distrProdID, qty, uom, price].some(field => !field && field !== 0);
  };
  makeChangeHandler = (field, validate) => (event, { value }) => {
    const error = validate ? validate(value) : false;
    const fieldIndex = this.state.invalidFields.indexOf(field);
    const wasInvalid = (fieldIndex >= 0);
    let { invalidFields } = this.state;
    if (error && !wasInvalid) invalidFields = [...this.state.invalidFields, field];
    if (!error && wasInvalid) {
      invalidFields = [...this.state.invalidFields];
      invalidFields.splice(fieldIndex, 1);
    }
    this.setState({ [field]: value, invalidFields });
  };
  submit = () => {
    const { invalidFields, ...product } = this.state;
    this.props.submit(product);
  };
  render() {
    const { name, brand, size, weight, description, valid, ...required } = this.state;
    const optional = { name, brand, size, weight, description };
    return (
      <div className="form-insert-product">
        <PanelRequired {...required} makeChangeHandler={this.makeChangeHandler} />
        <PanelOptional {...optional} makeChangeHandler={this.makeChangeHandler} />
        <Button
          color="purple"
          size="massive"
          floated="right"
          disabled={this.requiredFieldIsEmpty() || this.state.invalidFields.length > 0}
          onClick={this.submit}
          loaderID={insertButtonLoaderID}
        >
          INSERT
        </Button>
      </div>
    );
  }
}

FormInsertProduct.propTypes = {
  submit: PropTypes.func,
};
FormInsertProduct.defaultProps = {
  submit: product => null,
};

export default connect(null, mapDispatchToProps)(FormInsertProduct);
