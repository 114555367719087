import { call } from 'redux-saga/effects';
import setAvailableProduct from '../products/setAvailableProduct';
import { popupSetAvailProdsTableCellFailure, popupSetAvailProdsTableCellSuccess } from '../../actions';

export default function* setPopupMarketsTableCell({ upc, distributorid, values }){
  yield call(
    setAvailableProduct,
    { upc, distributorid, ...values },
    popupSetAvailProdsTableCellSuccess,
    popupSetAvailProdsTableCellFailure,
  );
};
