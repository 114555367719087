import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { withRouter } from 'react-router-dom';
import { getNumberSeparated } from '../../../services/helpers';

const getPercentage = (count, total) => `%${((count / total) * 100).toFixed(2)}`;

export default function RowMarketShipCount({ label, count, total, handleClickDownload, handleClickLink, cellClassName }) {
  const noTotal = (total === null);
  const classNameDouble = (noTotal)
    ? 'table-market-ship-count__container_double'
    : '';
  return (
    <React.Fragment>
      <div className={`table-market-ship-count__container table-market-ship-count__container_first table-market-ship-count__container_label ${cellClassName}`}>
        {label}
      </div>
      <div className={`table-market-ship-count__container ${cellClassName} ${classNameDouble}`}>
        {getNumberSeparated(count)}
      </div>
      {(!noTotal) && <div className={`table-market-ship-count__container  ${cellClassName}`}>
        {getPercentage(count, total)}
      </div>}
      <div className={`table-market-ship-count__container
       table-market-ship-count__container_last
       table-market-ship-count__container_icon
       ${cellClassName}`}
      >
        <Icon name="external" onClick={handleClickLink} />
        <Icon name="download" onClick={handleClickDownload} />
      </div>
    </React.Fragment>
  );
}


RowMarketShipCount.propTypes = {
  label: PropTypes.string,
  count: PropTypes.number,
  total: PropTypes.number,
  handleClickDownload: PropTypes.func,
  handleClickLink: PropTypes.func,
  cellClassName: PropTypes.string,
};

RowMarketShipCount.defaultProps = {
  label: '',
  count: 0,
  total: null,
  handleClickDownload: () => null,
  handleClickLink: () => null,
  cellClassName: '',
};
