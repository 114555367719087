import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popup, Dropdown, TextArea, Button } from 'semantic-ui-react';

import 'semantic-ui-css/semantic.min.css';

import PopupOrderBulkEditLayout from './PopupOrderBulkEditLayout';
import { orderStatus } from '../../../services/helpers';
import '../../../styles/Panel.css';
import '../grid_order/GridOrder.css';

const UNCHANGED = 'As is';

class PopupOrderBulkEdit extends Component {
  constructor(props, context) {
    super(props, context);
    this.initialState = {
      notes: null,
      status: null,
    };
    this.state = { ...this.initialState };
  }
  makeChangeHandler = field => (event, { value }) => this.setState({ [field]: value });
  noChanges() {
    return Object.keys(this.state)
      .every(field => this.state[field] === this.initialState[field]);
  }
  submit = () => {
    const { notes, status } = this.state;
    this.props.submit({ notes, status });
  };
  handleOpen = () => {
    this.setState(this.initialState);
  };
  render() {
    return (
      <Popup
        trigger={this.props.children}
        on="click"
        position="bottom right"
        onOpen={this.handleOpen}
        flowing
      >
        <PopupOrderBulkEditLayout
          notesSpot={<TextArea
            className="panel__input"
            value={this.state.notes || ''}
            onChange={this.makeChangeHandler('notes')}
          />}
          statusSpot={<Dropdown
            className="panel__dropdown grid-orders__status-dropdown"
            options={[
              { text: UNCHANGED, value: null },
              ...this.props.statusOptions,
            ]}
            placeholder={UNCHANGED}
            value={this.state.status}
            icon="chevron down"
            onChange={this.makeChangeHandler('status')}
          />}
          submitSpot={
            <Button
              size="big"
              color="purple"
              className="popup-order-bulk-edit__container_button"
              onClick={this.submit}
              disabled={this.noChanges()}
            >
              CHANGE ORDERS
            </Button>
          }
        />
      </Popup>
    );
  }
}

PopupOrderBulkEdit.propTypes = {
  children: PropTypes.node.isRequired,
  statusOptions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.any,
  })),
  submit: PropTypes.func,
};
PopupOrderBulkEdit.defaultProps = {
  statusOptions: [],
  submit: () => {},
};

export default PopupOrderBulkEdit;
