import React from 'react';

import WithLoader from './WithLoader';
import WithSubmitWithoutLoader from './WithSubmit';

const WithSubmit = WithLoader(WithSubmitWithoutLoader);

const WithLoaderAndSubmit = Input => props => (
  <WithSubmit
    {...props}
    Input={Input}
  />
);

export default WithLoaderAndSubmit;