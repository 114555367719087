import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import './TableDistrChoice.css';
import '../../../styles/Panel.css';

function RowTableDistrChoice({ distr, chosen, current, handleChoice }) {
  const { id, name, price, qty, prodID, unpublish } = distr;
  const currentDistrCellStyle = current ? 'panel__table__cell_current' : '';
  const disabledCellStyle = unpublish ? 'panel__table__cell_disabled' : '';
  return (
    <React.Fragment>
      <div className={`panel__table__cell ${currentDistrCellStyle} ${disabledCellStyle}`}>
        <Checkbox
          className="panel__table__radio"
          checked={chosen}
          onMouseDown={handleChoice}
          value={id}
          radio
        />
      </div>
      <div className={`panel__table__cell ${currentDistrCellStyle} ${disabledCellStyle}`}>
        {name}
      </div>
      <div className={`panel__table__cell ${currentDistrCellStyle} ${disabledCellStyle}`}>
        {`$${price}`}
      </div>
      <div className={`panel__table__cell ${currentDistrCellStyle} ${disabledCellStyle}`}>
        {`(${qty})`}
      </div>
      <div className={`panel__table__cell ${currentDistrCellStyle} ${disabledCellStyle} panel__table__cell_right-end`}>
        {prodID}
      </div>
    </React.Fragment>
  );
}

RowTableDistrChoice.propTypes = {
  distr: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    name: PropTypes.string,
    price: PropTypes.number,
    qty: PropTypes.number,
    prodID: PropTypes.string,
    uom: PropTypes.number,
  }),
  chosen: PropTypes.bool,
  current: PropTypes.bool,
  handleChoice: PropTypes.func,
};
RowTableDistrChoice.defaultProps = {
  distr: {
    id: 0,
    name: '',
    price: '',
    qty: '',
    prodID: '',
    uom: 0,
  },
  chosen: false,
  current: false,
  handleChoice: () => {},
};

export default RowTableDistrChoice;
