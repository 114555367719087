import { put, call } from 'redux-saga/effects';
import { v4 as getID } from 'uuid';

import { logInFailure, logInSuccess, getReports, stopReportTimer } from '../actions';
import { getFullServerURI, requestMaker, departmentNames } from '../../services/settings';

const getFullImageURL = partialURL => partialURL ? getFullServerURI(`media/${partialURL}`) : '';

export default function* loginSaga({ username, password }) {
  try {
    const response = yield call(requestMaker, {
      method: 'post',
      url: '/login/',
      data: { username, password },
    });
    const user = {
      ...response.data.user,
      token: response.data.token,
      image_url: getFullImageURL(response.data.user.image_url),
    };
    yield put(logInSuccess(user));
    if (user.department === departmentNames.admin) {
      // we stop report timer if one is still running to override with our own
      // this allows us to get reports instantly instead of schedule
      yield put(stopReportTimer());
      yield put(getReports(getID()));
    }
  }
  catch (error) {
    yield call([console, 'log'], error);
    yield put(logInFailure(error));
  }
};
