import React from 'react';
import PropTypes from 'prop-types';

import './PanelLabeled.css';
import '../../../../styles/Panel.css';

export default function PanelForm({ label, children, red }) {
  return (
    <div className="panel-labeled">
      <div className={`panel-labeled__label ${red ? 'panel-labeled__label_red' : ''}`}>
        {label}
      </div>
      <div className={`panel-labeled__content ${red ? 'panel-labeled__content_red' : ''}`}>
        {children}
      </div>
    </div>
  );
}


PanelForm.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  red: PropTypes.bool,
};

PanelForm.defaultProps = {
  label: 'Label',
  children: '',
  red: false,
};
