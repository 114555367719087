import makeChangeOrderSaga from './makeChangeOrderSaga';
import {
  changeOrderWithShippingInfoSuccess,
  changeOrderWithShippingInfoFailure,
} from '../../actions';
import { orderIDsMatch } from './changeOrderSaga';

const selectOrderByIDs = ids => state => state.ordersShipping.list.find(orderIDsMatch(ids));

export default makeChangeOrderSaga({
  orderSelector: selectOrderByIDs,
  url: '/api/v1/order-shipping/',
  successAction: changeOrderWithShippingInfoSuccess,
  failureAction: changeOrderWithShippingInfoFailure,
});
