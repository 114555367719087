import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import LoginPage from './components/LoginPage';
import { hideLoginError, logIn } from '../../redux/actions';


function mapStateToProps(state) {
  return {
    logged: state.user.logged,
    department: state.user.department,
    error: state.user.error
      ? state.user.error.join('\n')
      : state.user.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logIn: (username, password) => dispatch(logIn(username, password)),
    hideError: () => dispatch(hideLoginError()),
  };
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LoginPage);