import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputHiddenControlled } from '../../../common';

class InputHiddenTabControlled extends Component {
  handleShow = () => {
    const { onShow, tab } = this.props;
    onShow(tab);
  };
  handleHide = () => {
    const { onHide, tab } = this.props;
    onHide(tab);
  };
  render() {
    const {
      tab, currentTab, onShow, onHide, ...props
    } = this.props;
    return (
      <InputHiddenControlled
        hidden={tab !== currentTab}
        onShow={this.handleShow}
        onHide={this.handleHide}
        {...props}
      />
    );
  }
}

InputHiddenTabControlled.propTypes = {
  tab: PropTypes.number.isRequired,
  currentTab: PropTypes.number,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
};
InputHiddenTabControlled.defaultProps = {
  currentTab: -1,
  onShow: () => {},
  onHide: () => {},
};

export default InputHiddenTabControlled;
