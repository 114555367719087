import axios from 'axios';

import configParams from '../../config.json';
import { timeIntervals } from './';

function all(array) {
  return array.every(e => !!e);
}

const config = {};

// export const APP_CONFIG = configParams[process.env.REACT_APP_ENV];

// export const APP_CONFIG = {
//   serverBaseUrl: "http://10.0.0.76:8011/",
//   apiUrl: "http://10.0.0.76:8011/api/v1",
//   axios_timeout: 2e4
// };

export const APP_CONFIG = {
  serverBaseUrl: "https://erp-server.valomarket.com/",
  apiUrl: "https://erp-server.valomarket.com/api/v1",
  axios_timeout: 2e4
};
// console.log(APP_CONFIG, "APP_CONFIG");

if (!all([APP_CONFIG, APP_CONFIG.serverBaseUrl, APP_CONFIG.apiUrl])) {
  alert('Invalid URL\'s config.\nPlease check configuration!');
}

config.serverBaseUrl = APP_CONFIG.serverBaseUrl;

config.apiUrl = APP_CONFIG.apiUrl;

export const AXIOS_TIMEOUT = APP_CONFIG.axios_timeout || 10 * timeIntervals.second;

export const requestMaker = axios.create({
  baseURL: config.serverBaseUrl,
  headers: {
    'content-type': 'application/json',
  },
  timeout: AXIOS_TIMEOUT,
});

export function getFullServerURI(partialURI) {
  if (config.serverBaseUrl.endsWith('/')) return `${config.serverBaseUrl}${partialURI}`;
  return `${config.serverBaseUrl}/${partialURI}`;
}

export { config };

export const WITHHOLD_SEARCH_TIME = timeIntervals.second;

export const PAGINATION_OPTIONS = [
  { text: 50, value: 50 },
  { text: 200, value: 200 },
];

export const INTERVAL_GET_REPORTS = 2 * timeIntervals.hour;

export const ADMIN_SITE_URL = getFullServerURI('admin');
