export default function getArrayWithReplaced (arr, value, searchFunction) {
  const clone = [...arr];
  const target = arr.findIndex(searchFunction);
  if (target < 0)
    return arr;
  if (!value) {
    clone.splice(target, 1);
  } else {
    clone[target] = value;
  }
  return clone;
};
