import { CHANGE_INVENTORY_SEARCH } from '../actions';

const initialInventorySearch = {
  itemID: '',
  itemInfo: '',
  brand: '',
  limit: 50,
};

export default function InventorySearchReducer(inventorySearch = initialInventorySearch, action){
  switch (action.type) {
    case CHANGE_INVENTORY_SEARCH:
      return { ...inventorySearch, ...action.params };
    default: return inventorySearch;
  }
}