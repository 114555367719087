import { SET_PARCEL_SIZE, REMOVE_PARCEL_SIZE, REMOVE_ALL_PARCEL_SIZES } from '../actions';
import makeReducer from '../makeReducer';
import { getObjWithout } from '../../services/helpers';

const initialTempParcelSizes = {};
const emptySize = {
  length: '',
  width: '',
  height: '',
  weight: '',
};

const getOrCreateSize = (state, orderID) => state[orderID] || emptySize;

const actionHandlers = {
  [SET_PARCEL_SIZE]: (state, { orderID, sizes }) =>
    ({ ...state, [orderID]: { ...getOrCreateSize(state, orderID), ...sizes } }),
  [REMOVE_PARCEL_SIZE]: (state, { orderID }) => getObjWithout(state, orderID),
  [REMOVE_ALL_PARCEL_SIZES]: () => initialTempParcelSizes,
};

export default makeReducer(initialTempParcelSizes, actionHandlers);
