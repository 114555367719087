import React from 'react';
import PropTypes from 'prop-types';

import NavLinkBasic from '../nav_link/NavLinkBasic';
import './Header.css';

const renderElement = el => (el.renderer)
  ? el.renderer(el)
  : <NavLinkBasic {...el} key={el.uniqueKey} />;

function RoutesSection(props) {
  const elements = (props.elements)
    ? props.elements.map(renderElement)
    : null;
  return (
    <div className="navbar__elements">
      {elements}
    </div>
  );
}

RoutesSection.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.object),
};

RoutesSection.defaultProps = {
  elements: [],
};

export default RoutesSection;
