export const getOrderAsProductToShip = ({ unique, buyInfo, itemInfo, shipInfo, customerInfo, notes }) => ({
  orderItemID: unique.orderItemID,
  marketplaceID: unique.marketplaceID,
  sku: buyInfo.itemIDs.sku,
  upc: buyInfo.itemIDs.upc,
  orderCount: buyInfo.orderCount,
  shipWeight: shipInfo.weight,
  isFood: shipInfo.isFood,
  denyWeightUpdate: shipInfo.denyWeightUpdate,
  country: customerInfo.country,
  imageURL: itemInfo.imageURL,
  name: itemInfo.name,
  qty: buyInfo.qty,
  shipQty: buyInfo.remainingQty || buyInfo.qty,
  remainingQty: buyInfo.remainingQty,
  price: buyInfo.price,
  profit: buyInfo.profit,
  chosen: false,
  uom: itemInfo.uom,
  notes,
});