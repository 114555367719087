import React from 'react';
import PropTypes from 'prop-types';
import './PopupOrderBulkEdit.css';

export default function PopupOrderBulkEditLayout(props) {
  return (
    <div className="popup-order-bulk-edit">
      <div className="popup-order-bulk-edit__input_panel">
        <div className="popup-order-bulk-edit__container">
          <div className="popup-order-bulk-edit_title">Notes</div>
          <div className="popup-order-bulk-edit__container_notes">
            {props.notesSpot}
          </div>
        </div>
        <div className="popup-order-bulk-edit__container">
          <div className="popup-order-bulk-edit_title">Status</div>
          <div className="popup-order-bulk-edit__container_status">
            {props.statusSpot}
          </div>
        </div>
      </div>
      <div className="popup-order-bulk-edit__container popup-order-bulk-edit__container_submit">
        {props.submitSpot}
      </div>
    </div>
  );
}


PopupOrderBulkEditLayout.propTypes = {
  notesSpot: PropTypes.node,
  statusSpot: PropTypes.node,
  submitSpot: PropTypes.node,
};

PopupOrderBulkEditLayout.defaultProps = {
  notesSpot: '',
  statusSpot: '',
  submitSpot: '',
};
