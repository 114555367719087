import React from 'react';
import PropTypes from 'prop-types';
import { v4 as getID } from 'uuid';

import '../../../styles/Card.css';
import '../../common/card_labeled/CardLabeled.css';

import { makeOrderHash } from '../../../services/helpers';

const notCapitalized = ['buyerID'];

const makeLineRenderer = (customerInfo, makeSubmitter) => field => (
  <div
    key={getID()}
    className={`card-labeled__line ${notCapitalized.includes(field)
      ? '' : 'card-labeled__line_capitalize'}`}
  >
    {customerInfo[field]}
  </div>
);

export const orderedCustomerFieldNames = [
  'name', 'address1', 'address2', 'address3',
  'city', 'state', 'zipcode', 'country',
  'buyerPhone', 'buyerID',
];

const replaceFieldName = (fieldNames, name, replaced) => {
  const withReplaced = [...fieldNames];
  withReplaced[withReplaced.indexOf(name)] = replaced;
  return withReplaced;
};

class ListCustomer extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.hash = makeOrderHash(props.ids);
    this.state = {
      isOpen: false,
    };
  }
  render() {
    const { customerInfo } = this.props;
    const displayedFieldNames = (customerInfo.shipPhone)
      ? replaceFieldName(orderedCustomerFieldNames, 'buyerPhone', 'shipPhone')
      : orderedCustomerFieldNames;
    return (
      <div>
        {displayedFieldNames.map(makeLineRenderer(customerInfo))}
      </div>
    );
  }
}

ListCustomer.propTypes = {
  customerInfo: PropTypes.shape({
    name: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.string,
    shipPhone: PropTypes.string,
    buyerPhone: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
    buyerID: PropTypes.string,
  }),
  ids: PropTypes.shape({
    orderID: PropTypes.string,
    orderItemID: PropTypes.string,
    marketplaceID: PropTypes.number,
  }),
};

ListCustomer.defaultProps = {
  customerInfo: {
    name: '',
    country: '',
    state: '',
    city: '',
    email: '',
    address1: '',
    address2: '',
    address3: '',
    zipcode: '',
    shipPhone: '',
    buyerPhone: '',
  },
  ids: {
    orderID: '',
    orderItemID: '',
    marketplaceID: 0,
  },
};

export default ListCustomer;
